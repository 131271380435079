import React from "react";
import './QualityOfLifeForm.scss';
import QualityOfLifeFormInput from "../shared/Forms/QualityOfLifeFormInput";

import { Button, Col, Container, Modal, Row } from "react-bootstrap";

const QualityOfLifeForm = () => {
    return (
        <div class="blueContainer" id="form">
            <div class="formCopyContainer">
                <p class="formCopy">We can’t possibly pack all that we offer into one page!<br/>
                Provide your email address to receive our NEW Quality of Life Report.<br/>
                You’ll discover all the ways to live your best life in Henrico.</p>
            </div>
            <div class="formInputContainer">
                <QualityOfLifeFormInput/>
            </div>
            
        </div>
    );
    };

export default QualityOfLifeForm;