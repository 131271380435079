import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./SecondaryNav.scss";

const WhiteOakNav = (props) => {
    return (
        <div>
            <Navbar expand="xs" className="secondary-navabar">
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/white-oak/quick-facts"
                >
                    Quick Facts
                </NavLink>
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/white-oak/the-site"
                >
                    The Site
                </NavLink>
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/white-oak/infrastructure"
                >
                    Infrastructure
                </NavLink>
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/white-oak/location"
                >
                    Location
                </NavLink>
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/white-oak/business-climate"
                >
                    Business Climate
                </NavLink>
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/richmond-nap"
                >
                    Richmond NAP
                </NavLink>
            </Navbar>
        </div>
    );
}

export default WhiteOakNav;