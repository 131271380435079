import 'react-app-polyfill/ie9';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-dom';
import './index.scss';
import App from './App';
//import { Helmet } from "react-helmet";
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'chart.js/dist/Chart.bundle.min.js';
import 'react-image-lightbox/style.css';
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Montserrat:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i', 'sans-serif']
  }
});

render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
