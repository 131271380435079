import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import resourcesHenricoCounty from './images/Henrico County.png';
import resourcesBusinessOneStop from './images/Business One Stop.png';
import resourcesDoingBusinessInHenrico from './images/Doing Business in Henrico.png';
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "./ExistingBusinessHero"
import ExistingBusinessNav from '../shared/Navs/ExistingBusinessNav';
import corePdf from "./pdfs/SCORE+Business+Plan+Sketch.pdf";
import checklist from "./pdfs/VA+Bus+Startup+Checklist.pdf";
import smallBusinessGuide from "./pdfs/SCORE-Deluxe-Marketing-Fundamentals.pdf";
import introToVaLlcs from "./pdfs/An+Intro+to+VA+LLCs+2019.pdf";
import startingBusinessPlanBusinessPlan from "./pdfs/Starting+a+business+-+Business+plan.pdf";
import startingBusinessPlanFeasibilityChecklist from "./pdfs/Starting+a+business+-+Feasibility+Checklist.pdf";
import VirginiaGuideToEstablishingABusiness from "./pdfs/Virginia+Guide+to+Establishing+a+Business+2015-2016.pdf";
import "./StartABusiness.scss";
import PageTitle from '../shared/PageTitle/PageTitle';
import { RemoteContent } from '../shared/Cms';

const StartABusiness = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'existing-business',
    page: 'Start a Business'
  };
    return (
        <div>
            <ExistingBusinessHero/>
            <Container>
                <ExistingBusinessNav />
                  <PageTitle title={
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="title"
                    />
                  } />
                <Row>
                   <Col>
                     <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="s1"
                    />
                   </Col>
                </Row>
                  <RemoteContent
                    domain={domain}
                    section={section}
                    page={page}
                    field="businesslinks"
                  />
                <Row className="additional-resources-row">
                    <Col>
                        <Row>
                            <Col xs={12}>
                                <p className="paragraph-header--blue header-additional-resources">Additional Resources</p>
                            </Col>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={4} className="resource-column">
                                      <RemoteContent
                                        domain={domain}
                                        section={section}
                                        page={page}
                                        field="image1"
                                      />
                                      <RemoteContent
                                        domain={domain}
                                        section={section}
                                        page={page}
                                        field="resourcehenrico"
                                      />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={4} className="resource-column">
                                      <RemoteContent
                                        domain={domain}
                                        section={section}
                                        page={page}
                                        field="image2"
                                      />
                                      <RemoteContent
                                        domain={domain}
                                        section={section}
                                        page={page}
                                        field="resourceonestop"
                                      />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={4} className="resource-column">
                                      <RemoteContent
                                        domain={domain}
                                        section={section}
                                        page={page}
                                        field="image3"
                                      />
                                      <RemoteContent
                                        domain={domain}
                                        section={section}
                                        page={page}
                                        field="resourcedoingbusiness"
                                      />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={4} className="resource-column">
                                      <a href="https://adulteducation.henricoschools.us/" target="_blank"><img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/HCPS+adult+logo_template_300-web.png" width="260"/></a>
                                      <br></br><br></br>
                                      <p><strong><a href="https://adulteducation.henricoschools.us/" target="_blank">Henrico Adult Education</a></strong></p>
                                      <p>Fun enrichment courses, World Languages, Career & Technical Education classes including the Health & Medical field.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <NewsInsights />
                </Row>
            </Container>
        </div>
    );
};

export default StartABusiness;
