import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel'
import headquarters from './images/headquarters.png';
import advancedManufacturing from './images/adv mfg.png';
import financeAndInsurance from './images/finance insurance.png';
import healthAndLifeSciences from './images/health life sciences.png';
import international from './images/international.png';
import supplyChainManagement from './images/supply chain mgmt.png';
import dataCenters from './images/data centers.png';
import professionalAndCreativeServices from './images/prof creative svcs.png';
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "./ExistingBusinessHero"
import ExistingBusinessNav from '../shared/Navs/ExistingBusinessNav';
import './MajorEmployersAndLegacyCompanies.scss';
import PageTitle from '../shared/PageTitle/PageTitle';
import { RemoteContent } from '../shared/Cms';

const employersColumnOne = [
    "Allianz",
    "Altria",
    "Anthem Blue Cross Blue Shield",
    "Apex Systems",
    "Arko Corp.",
    "Bank of America",
    "Bon Secours",
    "ColonialWebb",
    "Comcast",
]

const employersColumnTwo = [
    "Dominion Energy",
    "EAB",
    "Elephant Insurance",
    "Facebook",
    "Fareva",
    "Genworth Financial",
    "HCA Virginia Health System",
    "Magellan Health",
    "Markel",
]

const employersColumnThree = [
    "McKesson Medical - Surgical",    
    "Mondelez International",
    "Patient First",
    "PPD",
    "Southern States Cooperative",
    "SunTrust Bank",
    "T-Mobile US",
    "Travelers Insurance",
    "Verizon"
]

class  MajorEmployersLegacyCompanies extends React.Component {
    constructor() {
        super();
        this.state = {
            showMore: false,
            showMore1: false,
            showMore2: false,
            showMore3: false,
            showMore4: false,
            showMore5: false,
            name: "React",
            test: 'example'
        }
        this.toggleShowMore = this.toggleShowMore.bind(this);
        this.toggleShowMore1 = this.toggleShowMore1.bind(this);
        this.toggleShowMore2 = this.toggleShowMore2.bind(this);
        this.toggleShowMore3 = this.toggleShowMore3.bind(this);
        this.toggleShowMore4 = this.toggleShowMore4.bind(this);
        this.toggleShowMore5 = this.toggleShowMore5.bind(this);
    }

    toggleShowMore(event){
        event.preventDefault();
        this.state.showMore === true ?  this.setState({showMore: false}) : this.setState({showMore: true});
    }


    toggleShowMore1(event) {
        event.preventDefault();
        this.state.showMore1 === true ?  this.setState({showMore1: false}) : this.setState({showMore1: true});
    }

    toggleShowMore2(event) {
        event.preventDefault();
        this.state.showMore2 === true ?  this.setState({showMore2: false}) : this.setState({showMore2: true});
    }

    toggleShowMore3(event) {
        event.preventDefault();
        this.state.showMore3 === true ?  this.setState({showMore3: false}) : this.setState({showMore3: true});
    }

    toggleShowMore4(event) {
        event.preventDefault();
        this.state.showMore4 === true ?  this.setState({showMore4: false}) : this.setState({showMore4: true});
    }

    toggleShowMore5(event) {
        event.preventDefault();
        this.state.showMore5 === true ?  this.setState({showMore5: false}) : this.setState({showMore5: true});
    }

    render() {
        function importAll(r) {
            return r.keys().map(r);
        }

        const galleryImages = importAll(require.context('./images/legacy-gallery', false, /\.(png|jpe?g|svg)$/));
      const { domain, section, page } = {
        domain: 'henrico',
        section: 'existing-business',
        page: 'Major Employers & Legacy Companies'
      };

        return (
            <div>
                <ExistingBusinessHero/>
                <Container>
                    <ExistingBusinessNav/>
                      <PageTitle title={
                        <RemoteContent
                          domain={domain}
                          section={section}
                          page={page}
                          field="title"
                        />
                      }
                      />
                    <Row>
                        <Col>
                            <p className="paragraph-header--blue">Where the best companies land</p>
                            <RemoteContent
                            domain={domain}
                            section={section}
                            page={page}
                            field="s1"
                          />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/145128-HEN22006_24x24-map-for-website-4.20.23.png" class="legacyMapImg"></img>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Row className="list-of-employers-wrapper">
                    <Col>
                        <Row className="list-of-employers-container">
                            <Col>
                                {employersColumnOne.map(employer => {
                                    return <p key={employer} className="employer-title">{employer}</p>
                                })}
                            </Col>
                            <Col>
                                {employersColumnTwo.map(employer => {
                                    return <p key={employer} className="employer-title">{employer}</p>
                                })}
                            </Col>
                            <Col>
                                {employersColumnThree.map(employer => {
                                    return <p key={employer} className="employer-title">{employer}</p>
                                })}
                            </Col>
                        </Row>
                    </Col>
                </Row> */}
                <Container>
                    <Row className="major-employers-card-row">
                        <Col xs={6} md={4} lg={3}>
                            <a href="/target-sectors/corporate-and-regional-headquarters"><img alt="" className="major-employers-card img-fluid" src={headquarters}></img></a>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <a href="/target-sectors/advanced-manufacturing"><img alt="" className="major-employers-card img-fluid" src={advancedManufacturing}></img></a>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <a href="/target-sectors/finance-and-insurance"><img alt="" className="major-employers-card img-fluid" src={financeAndInsurance}></img></a>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <a href="/target-sectors/health-and-life-sciences"><img alt="" className="major-employers-card img-fluid" src={healthAndLifeSciences}></img></a>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <a href="/target-sectors/international-companies"><img alt="" className="major-employers-card img-fluid" src={international}></img></a>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <a href="/target-sectors/supply-chain-management"><img alt="" className="major-employers-card img-fluid" src={supplyChainManagement}></img></a>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <a href="/target-sectors/data-centers"><img alt="" className="major-employers-card img-fluid" src={dataCenters}></img></a>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <a href="/target-sectors/professional-and-creative-services"><img alt="" className="major-employers-card img-fluid" src={professionalAndCreativeServices}></img></a>
                        </Col>
                    </Row>

                    <Row className="henrico-legacy-companies-wrapper">
                        <Col>
                          <RemoteContent
                            domain={domain}
                            section={section}
                            page={page}
                            field="legacycompanies"
                          />
                        </Col>
                    </Row>
                    <Row className="legacy-company-years">
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <p className="paragraph-header--blue">50+ Years</p>
                           {/* <p>+ See more </p>*/}
                            <b><a href="#!" onClick={this.toggleShowMore}>{this.state.showMore === true ? '-' : '+'}Show {this.state.showMore === true ? 'Less' : 'More'}</a></b><br /><br/>
                            { this.state.showMore === true ?
                                <React.Fragment>
                                  <RemoteContent
                                    domain={domain}
                                    section={section}
                                    page={page}
                                    field="legacy50plus"
                                    className="list-padding-left legacy-company-list"
                                  />
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <p className="paragraph-header--blue">45+ Years</p>
                           {/* <p>+ See more </p>*/}
                            <b><a href="#!" onClick={this.toggleShowMore1}>{this.state.showMore1 === true ? '-' : '+'}Show {this.state.showMore1 === true ? 'Less' : 'More'}</a></b><br /><br/>
                            { this.state.showMore1 === true ?
                                <React.Fragment>
                                  <RemoteContent
                                    domain={domain}
                                    section={section}
                                    page={page}
                                    field="legacy45plus"
                                    className="list-padding-left legacy-company-list"
                                  />
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <p className="paragraph-header--blue">40+ Years</p>
                           {/* <p>+ See more </p>*/}
                            <b><a href="#!" onClick={this.toggleShowMore2}>{this.state.showMore2 === true ? '-' : '+'}Show {this.state.showMore2 === true ? 'Less' : 'More'}</a></b><br /><br/>
                            { this.state.showMore2 === true ?
                                <React.Fragment>
                                  <RemoteContent
                                    domain={domain}
                                    section={section}
                                    page={page}
                                    field="legacy40plus"
                                    className="list-padding-left legacy-company-list"
                                  />                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <p className="paragraph-header--blue">35+ Years</p>
                       {/*     <p>+ See more </p>*/}
                            <b><a href="#!" onClick={this.toggleShowMore3}>{this.state.showMore3 === true ? '-' : '+'}Show {this.state.showMore3 === true ? 'Less' : 'More'}</a></b><br /><br/>
                            { this.state.showMore3 === true ?
                                <React.Fragment>
                                  <RemoteContent
                                    domain={domain}
                                    section={section}
                                    page={page}
                                    field="legacy35plus"
                                    className="list-padding-left legacy-company-list"
                                  />
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <p className="paragraph-header--blue">30+ Years</p>
                           {/* <p>+ See more </p>*/}
                            <b><a href="#!" onClick={this.toggleShowMore4}>{this.state.showMore4 === true ? '-' : '+'}Show {this.state.showMore4 === true ? 'Less' : 'More'}</a></b><br /><br/>
                            { this.state.showMore4 === true ?
                                <React.Fragment>
                                  <RemoteContent
                                    domain={domain}
                                    section={section}
                                    page={page}
                                    field="legacy30plus"
                                    className="list-padding-left legacy-company-list"
                                  />
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <p className="paragraph-header--blue">25+ Years</p>
                            <b><a href="#!" onClick={this.toggleShowMore5}>{this.state.showMore5 === true ? '-' : '+'}Show {this.state.showMore5 === true ? 'Less' : 'More'}</a></b><br /><br/>
                            { this.state.showMore5 === true ?
                                <React.Fragment>
                                  <RemoteContent
                                    domain={domain}
                                    section={section}
                                    page={page}
                                    field="legacy25plus"
                                    className="list-padding-left legacy-company-list"
                                  />
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </Col>
                    </Row>
                </Container>
                <Row className="legacy-slider">
                    <Col className="legacy-slider-container">
                        <Row className="no-margin">
                            <Col className="no-padding" sm={12} md={12} lg={6} xl={6}>
                                <Carousel>
                                    {galleryImages.map(image => {
                                        return <Carousel.Item key={image}>
                                            <img
                                                className="d-block w-100"
                                                src={image}
                                                alt=""
                                            />
                                        </Carousel.Item>
                                    })}
                                </Carousel>
                            </Col>
                            <Col className="carousel-gray" sm={12} md={12} lg={6} xl={6}>
                                <div className="carousel-content">
                                    <p className="paragraph-header--blue">Enjoy these photos from our Legacy
                                        Celebrations!</p>
                                    <p>Over the years, we've honored a wide variety of companies and entrepreneurs who've been doing business in Henrico for 50 years or more. Take a look.</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container>
                    <Row>
                        <Col>
                            <p className="paragraph-header--blue">Are you a Legacy Company?</p>
                            <p> Download a logo you can use for your website or social media. Click
                                <a target="_blank" rel="noopener noreferrer" href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/16470-MicrosoftTeams-image%20%283%29.png" style={{color:'#054496'}}> here </a> for those celebrating 25+ and
                                <a target="_blank" rel="noopener noreferrer" href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/164647-MicrosoftTeams-image%20%282%29.png" style={{color:'#054496'}}> here </a>  for 50+ years of operating in Henrico. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="paragraph-header--blue">Has your company been doing business in Henrico for 25
                                years or more?</p>
                            <p> If so, fill out <a href="/existing-business/major-employers-legacy-companies/legacy-company-form">this short form</a> so we can
                                recognize you as a Henrico Legacy Company.</p>
                        </Col>
                    </Row>
                    <NewsInsights/>
                </Container>
            </div>
        );
    }
};

export default MajorEmployersLegacyCompanies;
