import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import CovidHero from "./CovidHero";
import "./Covid19.scss";

const Covid19 = () => {
    return (
        <div>
            <CovidHero />
            <Container>
            <Row>
                    <Col>
                        <p>Here’s where to get the latest news regarding COVID-19, also known as Coronavirus.</p>
                        <p>To help minimize the potential impact of the virus on your business, the Henrico community, and Virginia’s economy, please use the following sources for information you can trust.</p>
                    </Col>
                </Row>                
                <Row>
                  <Col>
                    <ul className="covid-unordered-list">
                      <li>Go here <a href="https://henrico.maps.arcgis.com/apps/MapSeries/index.html?appid=7b124889835440b99e343ffb7bfe692e" target="_blank" rel="noopener noreferrer">for latest information from the CDC, Johns Hopkins, and other leading authorities</a>. This site also provides printable posters and handouts in English, Spanish, and simplified Chinese.</li>
                      <li>The Richmond and Henrico Health Districts have opened a public hotline at <strong>205-3501</strong> for health and medical questions related to COVID-19. The line is staffed every day from 8 a.m. to 8 p.m.<br /><strong>Additional resources:</strong><br /><a href="https://henrico.us/coronavirus/" target="_blank" rel="noopener noreferrer">Henrico agency and facilities updates</a><br /><a href="https://henrico.maps.arcgis.com/apps/MapSeries/index.html?appid=7b124889835440b99e343ffb7bfe692e" target="_blank" rel="noopener noreferrer">COVID-19 dashboard</a>.</li>
                      <li>VEC: ATTN workers whose jobs have been affected by the Coronavirus: <a href="https://www.vec.virginia.gov/" target="_blank" rel="noopener noferrer">https://www.vec.virginia.gov/</a></li>
                      <li>Find out more about the latest VA COVID-19 announcements and updates from the <a href="https://www.virginia.gov/coronavirus/" target="_blank" rel="noopener noreferrer">Official Site of the Commonwealth of Virginia</a>.</li>
                      <li>VA Department of Health Testing Hotline number: <a href="tel:877.275.8343">877.275.8343</a>.</li>
                      <li>LabCorp is a medical testing laboratory here and a good source for testing. They have numerous laboratories in Henrico and surrounding areas. Take a look at their website – <a href="https://www.labcorp.com/information-labcorp-about-coronavirus-disease-2019-covid-19" target="_blank" rel="noopener noreffer">https://www.labcorp.com/information-labcorp-about-coronavirus-disease-2019-covid-19</a>.</li>
                      <li><a href="https://restoreyoureconomy.org/index.php" target="_blank" rel="noopener noreferrer">RestoreYourEconomy</a> is your resource for up-to-date information related to COVID-19 and its economic impacts. It is managed by the International Economic Development Council (IEDC) with generous support from the U.S. Economic Development Administration and IEDC's Economic Development Research Partners program.</li>
                      <li>Virginia Tourism is working closely with the U.S. Travel Association, the Office of the Governor, the Virginia Department of Health, and other state agencies to provide an up-to-date COVID-19 response toolkit for our industry partners. Please see the following link to find out more: <a href="https://www.vatc.org/coronavirus/" target="_blank" rel="noopener noreferrer">https://www.vatc.org/coronavirus/</a>.</li>
                      <li>Fraudsters have already begun targeting small business owners during these economically difficult times. Find out more about these scams and where to report frauds <a href="https://static1.squarespace.com/static/56e0660b01dbae42186b5ca5/t/5e9753d0ebea8e60259a55c7/1586975696157/Scams+and+Fraud+Schemes.pdf" target="_blank" rel="noopener noreferrer">HERE</a>.</li>
                      <li>CDC guide for cleaning and disinfecting spaces: <a href="https://www.cdc.gov/coronavirus/2019-ncov/community/reopen-guidance.html/" target="_blank" rel="noopener noreferrer">https://www.cdc.gov/coronavirus/2019-ncov/community/reopen-guidance.html/</a>.</li>
                      <li>If you're ready to return to work and considering placing your children into child care, there are a few things you should take into consideration. Read these <a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Family+Facts_Child+Care+%26+COVID_7.21.20+(1)+(1).pdf" target="_blank" rel="noopener noreferrer">helpful tips</a> courtesy of the <a href="https://www.dss.virginia.gov/" target="_blank" rel="noopener noreferrer">Virginia Department of Social Services</a> and <a href="https://www.dss.virginia.gov/cc/index.html" target="_blank" rel="noopener noreferrer">ChildCareVA.com</a>.</li>
                      <li>There are several COVID-19 <a href="https://www.vdh.virginia.gov/richmond-city/richmond-and-henrico-area-covid-19-testing-sites/" target="_blank" rel="noopener noreferrer">public testing sites</a> throughout the Richmond and Henrico area – some offering free testing. Find out locations, hours, how to register, and more.</li>
                    </ul>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h3>Henrico COVID-19 Emergency Rental Assistance</h3>
                    <p>For residents who have been economically impacted by the pandemic, the Henrico COVID-19 Emergency Rental Assistance (COVID-19-ERA) program is providing grants to help cover rental and utility expenses. To learn more about the program, including eligibility requirements and how to apply, please review the documents below.</p>
                    <ul className="covid-unordered-list">
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Henrico+COVID+Emergency+Rental+Assistance+Flyer+for+Housing+Providers.pdf" target="_blank" rel="noopener noreferrer"><strong>Henrico COVID Emergency Rental Assistance Flyer for Housing Providers</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Henrico+COVID+Emergency+Rental+Assistance+Flyer+for+Tenants.pdf" target="_blank" rel="noopener noreferrer"><strong>Henrico COVID Emergency Rental Assistance Flyer for Tenants</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/HENRICO-COVID-19-ERA-PROGRAM-GUIDELINES-1.pdf" target="_blank" rel="noopener noreferrer"><strong>Henrico EDA Covid-19 ERA Program Guidelines</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/HENRICO-COVID-19-ERA-PROGRAM-GUIDELINES-SPANISH.pdf" target="_blank" rel="noopener noreferrer"><strong>Henrico EDA Covid-19 ERA Program Guidelines (en Español)</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/HENRICO-COVID-19-ERA-APPLICATION-1.pdf" target="_blank" rel="noopener noreferrer"><strong>Henrico EDA Covid-19 ERA Program Application</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/HENRICO-COVID-19-ERA-APPLICATION-SPANISH.pdf" target="_blank" rel="noopener noreferrer"><strong>Henrico EDA Covid-19 ERA Program Application (en Español)</strong></a></li>
                    </ul>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h3>Recursos Para COVID-19 en Español</h3>
                    <p>Haga un clic aquí para descargar una carpeta de documentos incluyendo información sobre las pruebas de COVID-19, información básica del virus, guías para las fases y otros recursos.</p>
                    <ul className="covid-unordered-list">
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Virginia-Forward-Phase-Three-Guidelines-SP.pdf" target="_blank" rel="noopener noreferrer"><strong>MÁS SEGURO EN CASA: FASE TRES PAUTAS PARA TODOS LOS SECTORES COMERCIALES</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Know+the+Basics+Final+Flyers_Spanish.pdf" target="_blank" rel="noopener noreferrer"><strong>Conoce los fundamentos</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Limiting+the+Transmission+of+COVID-19_Spanish.pdf" target="_blank" rel="noopener noreferrer"><strong>Limitar la transmisión de COVID-19</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Phase+1_Food+Establishments_Spanish.pdf" target="_blank" rel="noopener noreferrer"><strong>Fase 1 Establecimientos de alimentos</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Richmond+and+Chesterfield+Partner+Against+Covid-19+Disparity_Spanish.pdf" target="_blank" rel="noopener noreferrer"><strong>Richmond y Chesterfield se asocian contra la disparidad de Covid-19</strong></a></li>
                      <li><a href="https://henrico-eda-site-assets.s3.amazonaws.com/covid19/Valuable+Resources+in+Richmond+%26+Henrico_Spanish.pdf" target="_blank" rel="noopener noreferrer"><strong>Recursos valiosos en Richmond y Henrico</strong></a></li>
                    </ul>
                  </Col>
                </Row>
  
                  <Row className="mt-2">
                    <Col>
                        <div className="covid-video-container">
                            <iframe title="video1" width="100%" height="500px" src="https://www.youtube.com/embed/iHAqvBadHyw"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5><strong>Henrico Restaurant and Retail Reopening Town Hall (5/19/20)</strong></h5>
                        <div className="covid-video-container">
                            <iframe className="iframe-full-width" title="video2" width="100%" height="500px" src="https://player.vimeo.com/video/422883248"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        </div>
                    </Col>
                </Row>            
            </Container>
        </div>
);
};

export default Covid19;
