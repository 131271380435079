import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import AboutUsHero from "../../AboutUs/AboutUsHero";

const PrivacyPolicy = () => {
    return (
        <div>
            <AboutUsHero/>
            <Container>

                <Row>
                    <Col>
                        <p className="paragraph-header--blue">HENRICO EDA INTERNET PRIVACY POLICY STATEMENT</p>
                        <p>The following information explains the Henrico County Economic Development Authority’s (“EDA”) Internet Privacy Policy (the “Privacy Policy”). The information contained herein is intended to explain the EDA’s current Internet privacy practices, but should not be interpreted by the reader or user as a contract of any nature, whether stated or implied. The EDA reserves the right to amend its Privacy Policy and Privacy Policy statement at any time without notice.</p>
                        <p>The Privacy Policy contained herein applies only to web pages on the EDA’s website. The EDA’s website may contain links to other websites controlled by other entities, which are not under the control of the EDA and may be subject to a different privacy policy or no privacy policy at all. When a user links to another website, he or she will be subject to the privacy policy of that new site. The provision of links to such websites by the EDA does not constitute or imply the EDA’s endorsement, recommendation, or approval of the sites or organizations linked to or the accuracy of information found on such sites. The EDA expressly disclaims any and all liability for losses caused by reliance on the accuracy, reliability, or timeliness of information found on linked sites. Anyone relying on such information does so at his or her own risk.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--blue"> Privacy Policy</p>
                        <p className="paragraph-header--black">Virginia Law</p>
                        <p>The EDA protects its records in accordance with applicable Virginia statutes, including but not limited to the “Government Data Collection and Dissemination Practices Act”, Virginia Code § 2.2-3800 et seq., the “Virginia Freedom of Information Act” (“FOIA”), Virginia Code § 2.2-3700 et seq., and applicable U.S. federal laws. All policies and procedures instituted by the EDA with regard to information collection, retention, and dissemination are in compliance with such laws.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Information collected by the EDA’s website</p>
                        <p>The EDA attempts to collect only the minimum information needed to provide services to its users.</p>
                        <p>When a user browses or downloads information on or from the EDA’s website, the EDA automatically collects and stores the following information from the user’s visit:</p>
                        <ul>
                            <li>The Internet domain and IP address used to access the site.</li>
                            <li>The type of browser and operating system used for access.</li>
                            <li>The web pages visited by the user.</li>
                            <li>The time and date the user visited the site.</li>
                            <li>If the user accessed the EDA’s website through a link on another site, the address of that website.</li>
                        </ul>
                        <p>The information collected in this manner is used by the EDA to help the EDA to understand how people are using its pages, in order that it may improve the content of its online services. No attempt is made by the EDA to connect the information collected above with the individuals from whom the information was collected. If a user sends the EDA an email message during his or her visit, the EDA will collect the e-mail address and contents of the message, which will include any audio, video, or graphic information formats contained in the message. The collected information may be used to respond to the user, to address issues identified by the user, to improve the website, or to forward the message to another party for appropriate action requested in the message.</p>
                        <p>The EDA does collect personal information directly from individuals who voluntarily subscribe to the EDA’s newsletter and who voluntarily provide such information when soliciting information from the EDA about locating their business in Henrico County. The collection of personal information in this manner is necessary so that the EDA can provide the requested services. Such information is collected, retained, and used only to the extent necessary for the EDA to administer its business and provide services requested by customers.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Use of Collected Information</p>
                        <p>Routing information – such as the Internet domain and IP address from which the site was accessed – is used to route the EDA webpage requested to the user’s computer for viewing. The requested webpage and routing information is sent to the EDA’s internet service provider or other entities involved in the transmission of the requested page. The EDA does not control the privacy practices of such entities. The technical information collected allows the EDA to appropriately respond to user requests or respond in a personal manner and assists the EDA in improving its website. Additionally, transaction routing information may be used in a statistical format to review site content and performance of servers. These statistical summaries may be shared with the EDA’s business partners.
                            Optional information voluntarily provided by the user – such as when a user sends the EDA an e-mail message or subscribes to the EDA’s newsletter – helps the EDA to provide services and information tailored specifically to the requester or enables the EDA to forward the message to another party better suited to handle the request, and additionally allows the EDA to improve its website.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Data Retention</p>
                        <p>Although the EDA may retain user information indefinitely, routing information is ordinarily deleted after transmission of the requested web page. Rarely, in the event that an unauthorized party attempts to breach the EDA’s computer security, routing information logs may be retained to further a security investigation. In such instances, the logs may be forwarded to law enforcement agencies, together with any other relevant information in the EDA’s possession.</p>
                        <p>The EDA retains optional information in accordance with the records retention schedules promulgated by the Library of Virginia.</p>
                        <p>Any personal information collected and retained by the EDA is maintained in compliance with Virginia Code §§ 2.2-3800 and 2.2-3803.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">FOIA</p>
                        <p>Under FOIA, any records in the possession of the EDA may be subject to disclosure to members of the public. In such instance, all identifiable personal information will be removed prior to disclosure.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Restrictions on Disclosure of Information</p>
                        <p>The EDA does not sell or rent optional information provided by users to any outside company or organization. Additionally, the EDA does not reveal personally identifiable subscriber information to unaffiliated third parties except where required to do so by law.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Website Security</p>
                        <p>The EDA has instituted security procedures to prevent unauthorized access, removal, or alteration of collected data. Information on individuals attempting to obtain unauthorized access to the EDA’s website or collected information will be released to law enforcement agencies to aid in the investigation of suspected criminal activity.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Public Disclosure</p>
                        <p>The EDA is subject to the requirements for the administration of information systems promulgated by the Government Data Collection and Dissemination Practices Act, found in Virginia Code §§ 2.2-3800 and 2.2-3803. The EDA collects and retains personal information in compliance with Virginia Code § 2.2-3803.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Cookies</p>
                        <p>A cookie is a small piece of data sent from a website and stored in a user's web browser while a user is browsing a website. Some parts of the EDA’s website may use cookies in an effort to personalize certain aspects of the website. Cookies may also be used to compile information regarding site usage to help improve the website.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Plug-ins</p>
                        <p>Some of the content on the EDA’s website may require plug-ins, or downloadable web-browsing programs (such as Adobe Acrobat and Flash) to properly view. Any plug-ins required to view content are free to download and use and will be linked on every page they are required.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Copyright</p>
                        <p>The copyright to the EDA’s webpage is owned by the Henrico County Economic Development Authority and notice of this copyright is provided at the bottom of each page with “© 2020 Henrico County Economic Development Authority.” The EDA grants every person visiting its website permission to fairly use the contents of the website.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="paragraph-header--black">Linking Policy</p>
                        <p>This website may contain links to external websites maintained by organizations other than the EDA. The determination of which external links will be provided is at the sole discretion of the EDA and is not intended as an endorsement of any product, service, or views referenced or expressed on the linked website or the organization maintaining or sponsoring the website. Links may be removed or replaced at the sole discretion of the EDA at any time without notice.</p>
                    </Col>
                </Row>
            </Container>
</div>
)
}

export default PrivacyPolicy;

