import React, {  } from "react";
import { Col } from "react-bootstrap";
import { useHistory } from 'react-router';
import "./NewsCard.scss";

const NewsCard = (props) => {   
        const history = useHistory();

        const handleClick = (e, articleSlug, postDate, articleId, externalLink) => {
            e.preventDefault();

            const url = `/news/press-releases-and-announcements/${postDate}/${articleSlug}`;

            externalLink ? window.open(articleSlug) : (history.push(url, {
                articleId
            }))
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
        

        return (
            <Col className="news-card-container justify-content-center text-center" xs={props.columnsXs} sm={props.columnsSm} md={props.columnsMd} lg={props.columnsLg} xl={props.columnsXl}>
                <div className="news-card-image-wrapper">
                    <a 
                        href={props.link}
                        onClick={(e) => handleClick(e, props.link, props.date, props.id, props.externalLink)}
                    ><img className="news-card-image" src={props.imageUrl} alt="" height="200" width="200"/></a>
                </div>
                <div className="news-card-description">
                    {props.cardTitle}
                </div>
                <div className="news-card-description pt-1 date-row-text">
                    {props.date}
                </div>
            </Col>
            
        );
}

export default NewsCard;
