import React from "react";
import { Container } from "react-bootstrap";
import AboutUsHero from "../AboutUs/AboutUsHero"
import SiteSelectorsNav from "../shared/Navs/AboutUsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import { Map } from '../shared/Map';
import './FindUs.scss';

const pageTitle = 'Find Us';

const FindUs = (props) => {
    const handleRedirect = (e) => {
        window.open('https://www.google.com/maps/place/4300+E+Parham+Rd,+Richmond,+VA+23228/@37.630247,-77.5173962,17z/data=!3m1!4b1!4m5!3m4!1s0x89b115a0051e3525:0xb3cf21adc4b3f1ab!8m2!3d37.630247!4d-77.5152075');
    }
    return (
        <div>
            <AboutUsHero/>
            <Container>
                <SiteSelectorsNav/>
                <PageTitle title={pageTitle} />
                <p className="company-title">Henrico Economic Development Authority</p>
                <p>4300 E. Parham Road<br/>
                Henrico, VA 23228 USA<br/>
                <a href="tel:1-804-501-7654">804.501.7654</a><br/>
                804.501.7890 (fax)</p>
                <br/>

                <Map
                  isMarkerShown
                  lat={37.630179}
                  lng={-77.513663}
                  onClick={handleRedirect}
                  height={31.25}
                />
            </Container>
        </div>
    )
}

export default FindUs;
