import React from "react";
import HeroImage from './images/whiteOak.jpg';
import Image from 'react-bootstrap/Image';
import "./WhiteOakQuickFacts.scss";
// import header from './images/WOTP-Logowhite.png'
import headerImg from './images/wotp-hero-text.png';
import "./WhiteOakHero.scss";

const heroStyle = {
    width: "100%",
    height: "500px",
    backgroundImage: `url(${HeroImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
};

const WhiteOAKHero = () => {
    return (
        <div>
            <div className="hero" style={heroStyle}>
                {/* <Image className='header-image-text img-fluid' src={headerImg} alt=""></Image> */}
            </div>
        </div>
    );
};

export default WhiteOAKHero;