import { Col, Container, Row} from "react-bootstrap";
import React from "react";
import { useState } from 'react';
import NewsInsights from '../shared/NewsInsights/NewsInsights.js';
import LogosImage from '../TargetSectors/images/advanced-manufacturing-logos.png';
import LogosImage2 from '../TargetSectors/images/advanced-manufacturing-logos-2.png';
import "../TargetSectors/TargetSectors.scss";
import Quote from "../shared/Quote/Quote.js";
import DataCard from '../shared/DataCard/DataCard.js';
import FindAPropertyHero from './FindAPropertyHero.js';
import FindAPropertyNav from '../shared/Navs/FindAPropertyNav.js';
import PageTitle from '../shared/PageTitle/PageTitle.js';
import IndustrialPropertiesAccordion from './IndustrialPropertiesAccordion.js';
import './IndustrialProperties.scss';

const pageTitle = 'Industrial Properties in Henrico';

const quoteData = {
    copy: "“Our U.S. expansion project is an exciting opportunity for future growth. We explored U.S. locations and selected Virginia and Henrico among several that were considered. Not only is this location in close proximity to most of our U.S. customers – it provides us with a foundation for future strategic growth and expansion.”",
    signature: "Lionel Genix, President and CEO, Seppic, Inc., and Manager, Polykon Manufacturing"
}

const cards = [
    {
        titleLine1: "Since",
        titleLine2: "1611",
        text: 'Henrico has been a center for commerce and trade.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "Reach Customers Quickly",
        titleLine2: "",
        text: 'More than 55% of the U.S. population and major manufacturing sites are located within 750 miles of Henrico.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "140M Pounds of Cargo",
        titleLine2: "",
        text: 'are handled annually at Richmond International Airport.',
        categoryColor: '66A7CF',
    }
]

const IndustrialProperties = () => {
    return (
        <div>
            <FindAPropertyHero />
            <Container>
                <FindAPropertyNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>Strategically positioned in the middle of the East Coast, Henrico offers ample commercial property for sale. Whether you’re in the advanced manufacturing, data center, semiconductor or industries, Henrico has proven it’s the best location for your business. In fact, we have +/- 1,400 sweeping acres of shovel-ready, industrial-zoned land available. It’s why industry giants like Fareva, a world subcontracting leader in the industrial and household, cosmetics and pharmaceuticals fields, social metaverse company <a href="https://datacenters.atmeta.com/wp-content/uploads/2023/08/Virginia-Henrico.pdf" target="_blank">Meta</a>, and <a href="https://www.henrico.com/news/press-releases-and-announcements/2021-09-27/cocacola" target="_blank">Coca-Cola Consolidated</a> – the largest independent Coca-Cola bottler in the U.S. – all have a presence in our locality. And they’ve also discovered that the perks of being in Henrico don’t end with our premium properties.</p>
                    <p class="page-title">Available Industrial Properties</p>
                    <p>Check out our industrial properties currently available below, and find the best location for your business. Or, take a deeper dive into all of the commercial property for sale in Henrico with our handy <a href="https://www.henrico.com/site-selectors/available-property-search" target="_blank;">property search feature</a>. We make it easy to find your ideal listing with MyScan, the most comprehensive database of industrial properties and sites in Virginia. Get real-time data, generate detailed reports, discover possible incentives, and so much more.<br/><br/></p>
                    <div>
                        <IndustrialPropertiesAccordion/>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/competitivetaxstructure.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Competitive Tax Structure and Incentives</h3></p>
                    <p>In addition to our extensive commercial real estate, Henrico and our home state of Virginia both offer a wide range of economic development <a href="https://www.henrico.com/site-selectors/incentives" target="_blank">incentives</a> to boost your bottom line. In fact, the Commonwealth provides a commercial and industrial sales and use tax exemption for eligible equipment used directly in your manufacturing and research and development operations. So, when you want to save money on operating costs and invest more in business development, come to Henrico. You’ll also benefit from:
                        <ul><li>90¢ per $100 of assessed value <a href="https://www.henrico.com/news/press-releases-and-announcements/2022-04-18/board-of-supervisors" target="_blank">R&D tax rate</a> – the lowest in Virginia</li><li>30¢ per $100 of assessed value machinery and tools tax</li><li>85¢ per $100 of assessed value real estate tax</li></ul>
                        For more information about how our incentives can move the needle for your business, reach out to our Managing Director <a href="mailto:andrew@henrico.com" target="_blank">Andrew Larsen</a>. He helps existing businesses who are looking to grow their company, as well as new businesses who want to establish operations in Henrico.
                    </p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/nextlevelbusinesssuspport.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Next-Level Business Support</h3></p>
                    <p>Henrico offers a pro-business climate. We’re thrilled to go the extra mile on behalf of our business community and partner with you to ensure you have all the resources and tools needed to succeed. For instance, we have a streamlined review and approval process that includes fast-track (FT) permitting for eligible businesses. Projects may be considered for the “Gold” FT process when they provide major economic benefits for Henrico, and “Silver” if they offer significant redevelopment or other community benefits for our locality.</p>
                    <p>In addition, we enjoy a strong partnership with Dominion Energy at a critical time in their evolution when they’re developing <a href="https://coastalvawind.com/" target="_blank">Coastal Virginia Offshore Wind</a>, the largest offshore wind project in the U.S. and continuing to expand the <a href="https://www.dominionenergy.com/projects-and-facilities/solar-energy-facilities-and-projects" target="_blank">second-largest solar fleet</a> in the nation. In Henrico, you can grow your business, deliver on your <a href="https://www.henrico.com/why-henrico/esg" target="_blank">ESG</a> commitments, and meet your future energy needs with an industry leader that has a vision for becoming the most sustainable energy company in the nation.</p>
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/robustinfrastructure+(2).png" width="100%"/>
                    <br/><br/>
                    <p><h3>Robust Infrastructure</h3></p>
                    <p>Our industrial properties are just minutes from Henrico’s renowned White Oak Technology Park, which is now fully leased. But the proximity means you can benefit from everything the master-planned, publicly owned industrial park has to offer including unbeatable access to Interstates 64 and 295. Henrico knows that commercial properties are only as good as their foundation, and to that end we’ve made our infrastructure resilient. At our industrial sites, you’ll enjoy:
                        <ul><li>Significant wet utilities boasting county-wide 55 MGD excess capacity of water and 30 MGD excess capacity of sewer</li><li>Proximity to <a href="https://www.henrico.com/site-selectors/data-connectivity" target="_blank">DE-CIX and QTS Richmond NAP</a>, an internet exchange and complete global communications solution that facilitate world-class connectivity – at the fastest data speeds on the planet</li><li>Robust dark and lit fiber networks, providing world-wide interconnection with low latency</li></ul>
                    </p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/excellentqualityoflife.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Excellent Quality of Life</h3></p>
                    <p>From vibrant mixed-use developments voted two of the best places to live in America, to visionary ecodistricts like the upcoming <a href="https://www.greencityva.com/" target="_blank">GreenCity</a>, to quieter suburban homes, Henrico offers ample opportunity to <a href="https://www.henrico.com/why-henrico/qualityoflife" target="_blank">Live Your Best</a> – all at a cost of living that’s 2.5% below the national average. So whether you’re an outdoor enthusiast, avid art lover, or dedicated foodie, there is something for you in Henrico.<ul><li>Nearly 100 miles of multiuse trails</li><li>Top 10 Food Destination</li><li>40+ Craft Breweries</li><li>Top 10 U.S. Botanical Garden</li><li><a href="https://www.youtube.com/watch?v=YhFCZuvtAiw&t=4s" target="_blank">Five-Star Public Library System</a></li></ul>With so much to see and do in Henrico there’s only one problem: choosing your next move. But with our average 22-minute commute time – one of the nation’s best – we even make that easier for you.</p>
                    </Col>                                       
                </Row>                           
                {/* <Quote copy={quoteData.copy} signature={quoteData.signature} /> */}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default IndustrialProperties;