import React from "react";
import { Row, Col } from "react-bootstrap";
import SubscribeNewsletterHome from "../Forms/SubscribeNewsletterHome";

import "./ContactHenrico.scss";

const ContactHenrico = () => {
    return (
        <div className="wrapper-contact-henrico main-body-container">
            {/* <p className="contact-henrico-text">Contact the Henrico EDA</p> */}
            <Row>
                <Col>
                    <SubscribeNewsletterHome />
                </Col>
            </Row>
        </div>
    )
}

export default ContactHenrico;

