import { Col, Container, Row} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import airport from "./images/Airport.png";
import port from "./images/Port.png";
import rail from "./images/Rail.png";
import roadways from "./images/Roadways.png";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SiteSelectorsHero from './SiteSelectorsHero';
import SiteSelectorsNav from '../shared/Navs/SiteSelectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import "./Transportation.scss";
import { remoteData } from '../shared/Cms';

const Transportation = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'site-selectors',
    page: 'Transportation'
  };
  const [loading, setLoading] = useState(true);
  const [selector, setSelector] = useState([]);

  useEffect(() => {
    remoteData({domain, section, page})
      .then(data => {
        setSelector(data[0]);
        setLoading(false);
        console.log(data);
      });
  }, []);

  if(loading) {
    return <></>;
  }
    return (
        <div>
            <SiteSelectorsHero />
            <Container>
                <SiteSelectorsNav />
                  <PageTitle title={
                    selector.title
                  } />
                <Row>
                    <Col dangerouslySetInnerHTML={{__html: selector.s1}} />
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col lg={10} sm={10} md={10} xl={10}>
                                <div dangerouslySetInnerHTML={{__html: selector.roadways}} />
                            </Col>
                            <Col lg={2} sm={2} md={2} xl={2}>
                                <img alt="" src={roadways}></img>
                            </Col>
                        </Row>
                        <Row className="transportation-row">
                            <Col lg={10} sm={10} md={10} xl={10}>
                              <div dangerouslySetInnerHTML={{__html: selector.rail}} />
                            </Col>
                            <Col  lg={2} sm={2} md={2} xl={2}>
                                <img alt="" src={rail}></img>
                            </Col>
                        </Row>
                        <Row className="transportation-row">
                            <Col lg={10} sm={10} md={10} xl={10}>
                             <div dangerouslySetInnerHTML={{__html: selector.port}} />
                            </Col>
                            <Col  lg={2} sm={2} md={2} xl={2}>
                                <img alt="" src={port}></img>
                            </Col>
                        </Row>
                        <Row className="transportation-row">
                            <Col lg={10} sm={10} md={10} xl={10}>
                              <div dangerouslySetInnerHTML={{__html: selector.airports}} />
                            </Col>
                            <Col  lg={2} sm={2} md={2} xl={2}>
                                <img alt="" src={airport}></img>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <NewsInsights />
            </Container>
        </div>
    );
};

export default Transportation;
