import { Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useInputChange } from '../Forms/UseInputChange';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert'

const SubscribeNewsletterFeaturedProperties = () => {
    const [input, handleInputChange] = useInputChange();
    const [show, setShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [formShow, setFormShow] = useState(true);
    const [disableButton, setDisableButton] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisableButton(true);
        axios.post(`${process.env.REACT_APP_NEWS_URL}/forms/newsletter-featured-properties`, {
            firstName: input.firstName,
            lastName: input.lastName,
            email: input.email,
        })
            .then(res => {
                setAlertType(res.data.alertType);
                setAlertMessage(res.data.message);
                setShow(true);
                setFormShow(false);
            });
    }

    const handleAlertClose = (e) => {
        setShow(false);
    }

    return (
        <div className="contact-us-form-row">
            <Row>
                <Col>
                    <p className="paragraph-header--blue">Sign Up for the “Property Now” E-Newsletter</p>
                </Col>
            </Row>
            {show && (
                <Alert variant={alertType} onClose={(e) => handleAlertClose(e)} dismissible>
                    <Alert.Heading>{alertMessage}</Alert.Heading>
                    <div className="d-flex justify-content-end">
                    </div>
                </Alert>
            )}
            {formShow && (
                <Form>
                    <Form.Label><p></p></Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} controlId="firstName">
                            <Form.Control name="firstName" onChange={handleInputChange} />
                            <Form.Text className="text-muted">
                                <p>First Name</p>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} controlId="lastName">
                            <Form.Control name="lastName" onChange={handleInputChange} />
                            <Form.Text className="text-muted">
                                <p>Last Name</p>
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Control name="email" onChange={handleInputChange} type="email" />
                            <Form.Text><p className="text-muted">Email</p></Form.Text>
                        </Form.Group>
                    </Form.Row>
                    <Button className="button-primary button-green button-large" type="submit" disabled={disableButton} onClick={handleSubmit}>
                        Submit
                    </Button>
                </Form>
            )}
        </div>
    );
};

export default SubscribeNewsletterFeaturedProperties;