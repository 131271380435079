import React from 'react';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const mapStyle = {
  height: '100%'
};

export const Map = compose(
  withProps(props => {
    return {
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
      containerElement: props.containerElement || <div style={{ height: props.height ? `${props.height}rem` : `40rem`, width: '100%' }} />,
      loadingElement: <div style={mapStyle} />,
      mapElement: <div style={mapStyle} />

    }}),
  withScriptjs,
  withGoogleMap
)
((props) =>
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: props.lat, lng: props.lng }}
    zoom={12}
  >
      {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} onClick={props.onClick} />}
        </GoogleMap>
);
