import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import resources from './images/resources.png';
import growyour from './images/grow your biz.png';
import work from './images/workforce assist.png';
import financial from './images/financial assist.png';
import start from './images/startabiz.png';
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "./ExistingBusinessHero";
import ExistingBusinessNav from '../shared/Navs/ExistingBusinessNav';
import './BusinessRetentionAndExperience.scss';
import PageTitle from '../shared/PageTitle/PageTitle';
import { RemoteContent } from '../shared/Cms';

const BusinessRetentionExpansion = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'existing-business',
    page: 'Business Retention & Expansion'
  };

    return (
        <div>
            <ExistingBusinessHero />
            <Container>
                <ExistingBusinessNav />
                  <PageTitle title={
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="title"
                    />
                  } />
                <Row>
                    <Col>
                      <RemoteContent
                        domain={domain}
                        section={section}
                        page={page}
                        field="s1"
                      />
                    </Col>
                </Row>
                <Row className="retention-card-row">
                    <Col xs={6} md={4} lg={2}>
                        <a href="/existing-business/start-a-business"><img alt="" className="retention-cards img-fluid" src={start}></img></a>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <a href="/existing-business/grow-your-business"><img alt="" className="retention-cards img-fluid" src={growyour}></img></a>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <a href="/existing-business/workforce-assistance"><img alt="" className="retention-cards img-fluid" src={work}></img></a>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <a href="/existing-business/financial-assistance"><img alt="" className="retention-cards img-fluid" src={financial}></img></a>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <a href="/existing-business/additional-resources"><img alt="" className="retention-cards img-fluid" src={resources}></img></a>
                    </Col>
                </Row>
                {/* <Row>
                    <p>
                      <strong>Get to Know GreenCity</strong>
                      <br />
                      GreenCity is a visionary ecodistrict proposed for the former Best Products headquarters site in Henrico. The $2.3 billion multi-use community would offer ample space for virtually any business, spanning nearly 200 acres and generating about 2.3 million square feet of office and retail space, 2,400 housing units, and two hotels. As a beacon of innovation and environmental sustainability, several buildings would provide net-positive energy and water and produce zero waste. Discover more about the proposal on GreenCity’s <a href="https://www.greencityva.com/" target="_blank" rel="noopener noreferrer">official site</a> – and keep checking back here for the most up-to-the-minute GreenCity developments.
                    </p>
                </Row>
                <Row>
                  <div>  
                    <div class="gallery_item">
                      <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19342-Green%20city%201.png" target="_blank" rel="noopener noreferrer">    
                        <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19342-Green%20city%201.png" width="100%" alt="Green City"/><br/>
                      </a>
                    </div>
                    <div class="gallery_item">
                      <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19422-GREEN%2BCITY%2Baerial%2Brendering%2BPARK%2BSYSTEM.jpeg" target="_blank" rel="noopener noreferrer">    
                        <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19422-GREEN%2BCITY%2Baerial%2Brendering%2BPARK%2BSYSTEM.jpeg" width="100%" alt="Green City"/><br/>
                      </a>
                    </div>
                    <div class="gallery_item">
                      <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19443-Green%20city3%201.png" target="_blank" rel="noopener noreferrer">    
                        <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19443-Green%20city3%201.png" width="100%" alt="Green City"/><br/>
                      </a>
                    </div>
                  </div>
                </Row> */}
                <NewsInsights />
            </Container>
        </div>
    );
};

export default BusinessRetentionExpansion;
