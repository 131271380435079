import React from "react";
import { Row, Col } from "react-bootstrap";
import "./WebinarsArchivePost.scss";

const WebinarsPost = ({ webinars, loading }) => {
  if (loading) {
    return <h2>Loading...</h2>;
  }

  const opts = {
    height: "300",
    width: "300",
    playerVars: {
      autoplay: 0
    }
  };

  return (
    <div>
      <Row className="mt-0">
        {webinars.slice(0, 3).map(webinar => (
          <Col
            className="webinar-container"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={4}
          >
            <Row className="webinar-thumbnail-row">
              <a
                href={webinar.webinarUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={webinar.imageUrl} />
              </a>
            </Row>
            <Row className="webinar-title-row">
              <a
                href={webinar.webinarUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {webinar.title && (
                  <p className="webinar-title">{webinar.title}</p>
                )}
              </a>
            </Row>
          </Col>
        ))}
      </Row>
      <Row className="mt-0">
        {webinars.slice(3, 6).map(webinars => (
          <Col
            className="webinar-container"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={4}
          >
            <Row className="webinar-thumbnail-row">
              <a
                href={webinars.webinarUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={webinars.imageUrl} />
              </a>
            </Row>
            <Row className="webinar-title-row">
              <a
                href={webinars.webinarUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {webinars.title && (
                  <p className="webinar-title">{webinars.title}</p>
                )}
              </a>
            </Row>
          </Col>
        ))}
      </Row>
      <Row className="mt-0">
        {webinars.slice(6, 9).map(webinars => (
          <Col
            className="webinar-container"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={4}
          >
            <Row className="webinar-thumbnail-row">
              <a
                href={webinars.webinarUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={webinars.imageUrl} />
              </a>
            </Row>
            <Row className="webinar-title-row">
              <a
                href={webinars.webinarUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {webinars.title && (
                  <p className="webinar-title">{webinars.title}</p>
                )}
              </a>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default WebinarsPost;
