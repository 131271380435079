import React, { useState, useEffect } from 'react';
import { remoteData } from '../shared/Cms';
import { Col, Row } from "react-bootstrap";
import '../../components/shared/DataCard/DataCard.scss';
import henricomap from './images/Stragtegically-Located-Map1.png';
import _ from 'lodash';
import { getSectionItems } from '../../utils/cms';

export const FactGrid = ({page, numFacts, isHome}) => {
  const { domain, section } = {
    domain: 'henrico',
    section: 'why-henrico',
  };
  const dataMap = {
    'quality-of-life': 'Great Food',
    'fast-facts': 'Amazing Art',
    'pro-business': 'AAA A',
    'skilled-workforce': 'Diverse Population',
    'competitive-operating-costs': 'Class B',
    'market-access': 'Market Access',
    'infrastructure': 'Infrastructure'
  };

  const isFastFacts = page.toLowerCase() === 'fast-facts';

  const randomizeDataSet = (data) => {
    let randomizedSet = [];
    const dataSizeMax = data.length - 1;
    const numFastFacts = numFacts || 11;

    while(randomizedSet.length < numFastFacts) {
      let iterator = _.random(0,dataSizeMax);
      let it = _.random(1, getSectionItems(data[iterator]).length);
      let gridDataItem = data[iterator][`s${it}`];
      let basedGridDataItem = btoa(gridDataItem);

      if(gridDataItem === undefined) continue;

      let gridDataHash = {
        id: basedGridDataItem,
        color: data[iterator].color,
        itemData: gridDataItem
      };

      if(gridDataItem && !randomizedSet.some(item => item.id === basedGridDataItem)) {
        randomizedSet.push(gridDataHash);
      }
    }

    return randomizedSet;
  };

  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(isFastFacts) {
      remoteData({domain, section})
      .then(data => {
        setGridData(randomizeDataSet(data));
        setLoading(false);
      });
    } else {
      remoteData({domain, section, page: dataMap[page]})
      .then(data => {
        setGridData(data);
        setLoading(false);
      });
    }


  }, [page]);

  const renderItem = (item) => {
    const itemCards = getSectionItems(item);

    return itemCards.map(itemData => (item[itemData] && item[itemData].length > 0 &&
      <Col className="card-column" xs={6} sm={6} md={6} lg={4} xl={3}>
          <div className={`data-card-wrapper`}>
            <div className={`data-card-header color-${item.color}`}>
              </div>
                <div className={`data-card`}>
                  <div className="data-card-body" dangerouslySetInnerHTML={{ __html: item[itemData]}}>
                      </div>
                        </div>
                          </div>
                            </Col>
    ))
  };

  const renderFastFacts = () => {
    const itemCards = gridData;
    const homeClassPrefix = isHome ? 'home-' : '';

    return itemCards.map(item => (
      <Col className="card-column" xs={6} sm={6} md={6} lg={isHome ? 3 : 4} xl={isHome ? 2 : 3}>
          <div className={`${homeClassPrefix}data-card-wrapper`}>
            <div className={`${homeClassPrefix}data-card-header color-${item.color}`}>
              </div>
                <div className={`${homeClassPrefix}data-card ${isHome ? 'small' : ''}`}>
                  <div className={`${homeClassPrefix}data-card-body`} dangerouslySetInnerHTML={{ __html: item.itemData}}>
                      </div>
                        </div>
                          </div>
                            </Col>
    ))
  }

  if(loading) {
    return <></>;
  }
  return (
    <Row>
      {isFastFacts
          ? gridData && renderFastFacts()
          : gridData && gridData.map(item => (renderItem(item)))
      }
      {!isHome &&
      <Col className="card-column" xs={6} sm={6} md={6} lg={4} xl={3}>
        <div className="data-card-wrapper">
          <img className="why-henrico-map" src={henricomap} alt="map" />
            </div>
              </Col>
      }
                </Row>
  );
}
