export const pathToTitle = (path) => {
  return path.substring(1)
    .toLowerCase()
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .split('/')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' | ');
};

export const objKeyToTitle = (obj) => {
  obj = obj.split('_');
  obj = obj.map(function(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  });

  return obj.join(' ');
};
