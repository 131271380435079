import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import heroImage from "../../HomePage/images-home/home-hero-background.png";
import Hero from "../Hero/Hero";

const title = 'Newsletter - Henrico';

const SubscribeNewsletterSuccess = () => {
    return (
        <div>
            <Hero height="200px" image={heroImage} title={title} />
            <Container>
                <Row>
                    <Col className="newsletter-success-copy">
                        <p className="paragraph-header--blue">THANK YOU.</p>
                        <p>You’re one step closer to staying connected to all the good things going on in Henrico County</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SubscribeNewsletterSuccess;