import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import axios from 'axios';
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from "../shared/Navs/NewsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import Pagination from "../shared/Pagination/Pagination";
import PressReleasesAndAnnouncementsPost from "./PressReleasesAndAnnouncementsPost";

const pageTitle = 'Press Releases & Announcements';
const PressReleasesAndAnnouncements = (data) => {
    const [sortedPressReleasesAndAnnouncements, setSortedPressReleasesAndAnnouncements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(9);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_NEWS_URL}/press-releases-and-announcements`)
            .then(res => {
                setLoading(true);
                const pressReleasesAndAnnouncements = res.data.pressReleasesAndAnnouncements;
                const sortedPressReleasesAndAnnouncements = pressReleasesAndAnnouncements.sort(function (a, b) {
                    return (b.date < a.date) ? -1 : ((b.date > a.date) ? 1 : 0);
                });
                setSortedPressReleasesAndAnnouncements(sortedPressReleasesAndAnnouncements);
                setLoading(false);
            })
    }, []);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedPressReleasesAndAnnouncements.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    return (
        <div>
            <NewsHero />
            <Container>
                <NewsNav />
                <PageTitle title={pageTitle} />       
                <PressReleasesAndAnnouncementsPost posts={currentPosts} loading={loading} />
                <Row>
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={sortedPressReleasesAndAnnouncements.length}
                        paginate={paginate}
                    />
                </Row>
            </Container>
        </div>
    )
}

export default PressReleasesAndAnnouncements;