import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Container } from "react-bootstrap";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import PageTitle from '../shared/PageTitle/PageTitle';
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from '../shared/Navs/NewsNav';
import HenricoNowPost from "./HenricoNowPost";
import Pagination from "../shared/Pagination/Pagination";
import { sortArticles } from '../shared/Utilities/Sort';

import './HenricoNowArchive.scss';

const pageTitle = 'Henrico NOW';

const HenricoNowAllArticles = () => {
  const [henricoNowArticles, sethenricoNowArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_NEWS_URL}/henrico-now-articles`)
      .then(res => {
        setLoading(true);
        const articles = sortArticles(res.data.henricoNowArticles);

        sethenricoNowArticles(articles);
        setLoading(false);
      })
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = henricoNowArticles.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  return (
    <div>
      <NewsHero />
        <Container>
          <NewsNav />
            <PageTitle title={pageTitle} />
              <p className="paragraph-header--blue">All Articles</p>
                <HenricoNowPost henricoNowArticles={currentPosts} loading={loading} />
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={henricoNowArticles.length}
                    paginate={paginate}
                  />
                      <NewsInsights />
                        </Container>
                          </div>
  );
};

export default HenricoNowAllArticles;
