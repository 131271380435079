import TagManager from 'react-gtm-module';

const gtmArgs = {
  gtmId: `${process.env.REACT_APP_GTM_ID}`,
}

export const initGtm = () => {
  TagManager.initialize(gtmArgs);
};

export const sendPageView = (path, title) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageView',
      pagePath: path,
      pageTitle: title
    }
  });
};

export const sendStackAdaptEvent = (eventName) => {
  if (!eventName) return;

  if(window.saq) {
    window.saq(
      'ts',
      process.env.REACT_APP_STACK_ADAPT_PIXEL_ID,
      {
        button_click: eventName
      }
    );
  }
};

export const sendLinkedInEvent = () => {
  window.linktrk && window.lintrk('track', { conversion_id: process.env.REACT_APP_LINKEDIN_CONVERSION_ID });
};
