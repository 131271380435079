import React from "react";
import NapLogo from "./images/cropped-NAP-logo_Final.png";
import "./RichmondNapIntro.scss";
import { Row, Col, Container, Image } from "react-bootstrap";
import { RemoteContent } from "../shared/Cms";

const IntroSection = (data) => {
  const { domain, section, page } = {
    domain: "henrico",
    section: "white-oak-tech-park",
    page: "Richmond NAP",
  };

  return (
    <div className="intro w-100">
      <Container className="nap-home">
        <Row>
          <Col md="auto" className="show-paragraph-small text-center">
            <Image src={NapLogo} height="80px" width="200px" />
          </Col>
          <Col className="intro-paragraph show-paragraph-small">
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s1"
            />
          </Col>
          <Col className="intro-paragraph show-paragraph-large">
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s1"
            />
          </Col>
          <Col md="auto" className="show-paragraph-large">
            <Image className="nap-logo" src={NapLogo} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IntroSection;
