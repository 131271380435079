import React from "react";

const BloggerEmbed = () => {
    return (
        <div>
            <iframe src='https://list.ly/list/2K1y/framed?embed_type=iframe&layout=full&per_page=25&show_list_headline=false&show_list_badges=false&show_list_stats=false&show_list_title=false&show_list_description=false&show_author=false&show_list_tools=false&show_item_tabs=false&show_item_filter=false&show_item_sort=false&show_item_layout=false&show_item_search=false&show_item_numbers=false&show_item_timestamp=false&show_item_voting=false&show_item_relist=false&show_item_comments=false&show_sharing=false' seamless width="100%" height="600" scrolling='' />
        </div>
    )
}

export default BloggerEmbed;