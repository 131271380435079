import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import SiteSelectorsHero from './SiteSelectorsHero';
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SiteSelectorsNav from '../shared/Navs/SiteSelectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import { remoteData } from '../shared/Cms';

const Utilities = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'site-selectors',
    page: 'Utilities'
  };
  const [loading, setLoading] = useState(true);
  const [selector, setSelector] = useState([]);

  useEffect(() => {
    remoteData({domain, section, page})
      .then(data => {
        setSelector(data[0]);
        setLoading(false);
      });
  }, []);

  if(loading) {
    return <></>;
  }

    return (
        <div>
            <SiteSelectorsHero />
            <Container>
                <SiteSelectorsNav />
                <PageTitle title={selector.title} />
                <Row>
                    <Col>
                        <h3 className="text-center"><b></b></h3>
                    </Col>
                </Row>
                <Row>
                  <Col>
                    <p dangerouslySetInnerHTML={{__html: selector.fiberOptics}} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p dangerouslySetInnerHTML={{__html: selector.electricPower}} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <p className="paragraph-header--blue text-center">{selector.h3}</p>

                    <p className="assetContainer" dangerouslySetInnerHTML={{__html: selector.image1}}/>
                  </Col>
                </Row>
                <Row>
                    <Col>
                      <p dangerouslySetInnerHTML={{__html: selector.waterAndSewer}} />
                    </Col>
                </Row>
                <Row>
                  <Col>
                    <p dangerouslySetInnerHTML={{__html: selector.naturalGas}} />
                  </Col>
                </Row>
                <NewsInsights />
            </Container>
        </div>
    );
};

export default Utilities;
