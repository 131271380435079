import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SiteSelectorsHero from "./SiteSelectorsHero";
import SiteSelectorsNav from '../shared/Navs/SiteSelectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import './CostOfLiving.scss';
import { remoteData } from '../shared/Cms';
import { getListItems } from '../../utils/cms';

const CostOfLiving = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'site-selectors',
    page: 'Cost of Living'
  };
  const [loading, setLoading] = useState(true);
  const [selector, setSelector] = useState([]);

  useEffect(() => {
    remoteData({domain, section, page})
      .then(data => {
        setSelector(data[0]);
        setLoading(false);
      });
  }, []);

  if(loading) {
    return <></>;
  }

    return (
        <div>
            <SiteSelectorsHero />
            <Container>
                <SiteSelectorsNav />
                <PageTitle title={selector.title} />
                <Row>
                  <Col>
                    <p dangerouslySetInnerHTML={{__html: selector.s1}} />
                    <ul className="ul-added-space">
                      <span dangerouslySetInnerHTML={{__html: selector.s2}} />
                    </ul>
                  </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                      <p className="assetContainer" dangerouslySetInnerHTML={{__html: selector.costOfLivingAsset}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                      <p dangerouslySetInnerHTML={{__html: selector.imageCopy}} />
                    </Col>
                </Row>
                <NewsInsights />
            </Container>
        </div>
    );
};

export default CostOfLiving;
