import React from "react";
import Hero from '../shared/Hero/Hero';
import heroImage from "./images/Lewis-Ginter-Botanical-Gardens-min.jpg";
const title = "Why Henrico?";

const WhyHenricoHero = () => {
    return (
        <div>
            <Hero image={heroImage} title={title} heroCaption="Lewis Ginter Botanical Garden"/>
        </div>
    );
};

export default WhyHenricoHero;