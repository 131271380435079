import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link, } from 'react-router-dom';
import Hero from "../shared/Hero/Hero";
import heroImage from "../HomePage/images-home/home-hero-background.png";
import "./NotFound.scss";
const title = "Sorry, Page Not Found";

const NotFoundPage = () => {
    return (
        <div>
            <Hero height="200px" image={heroImage} title={title} />
            <Container>
                <Row>
                    <Col>
                        <div className="not-found-page">
                            <p className="paragraph-header--blue" style={{ textAlign: "center" }}>
                                <Link to="/">Go to Home</Link>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default NotFoundPage;