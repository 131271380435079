import React, { useState, useEffect } from "react";
import "./RichmondNapPage.scss";
import Container from "react-bootstrap/Container";
import RichmondNapHero from "./RichmondNapHero";
import IntroSection from "./RichmondNapIntro";
import { Col, Row } from "react-bootstrap";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import NapBuilding from "./images/nap_front1.png";
import { Image } from "react-bootstrap";
import axios from "axios";
import { Button } from "react-bootstrap";
import runway from "./images/distance_line.png";
import PercentageChart from "../shared/PercentageChart/PercentageChart.js";
import PageTitle from "../shared/PageTitle/PageTitle";
import FindAPropertyNav from "../shared/Navs/FindAPropertyNav";
import Quote from "../shared/Quote/Quote";
import "./RichmondNapPage.scss";
import { remoteData } from "../shared/Cms";

const quoteData = {
  copy:
    "If you don’t understand that once on the Internet your business is global, then you’re missing growth opportunities and not thinking big enough.",
  signature: "Clint Heiden, Chief Revenue Officer, QTS Realty Trust, Inc."
};

const pageTitle = "The world’s fastest data transmission starts in Henrico.";

const RichmondNap = data => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'white-oak-tech-park',
    page: 'Richmond NAP'
  };

  const [cableData, setCableData] = useState({});
  const [donutData, setDonutData] = useState("300");
  const [distanceData, setDistanceData] = useState("6000");
  const [cityData, setCityData] = useState("Bilbao, Spain to Virginia Beach");
  const [ownersData, setOwnersData] = useState(
    "Partnership between Telxius, META and Microsoft"
  );
  const [factoidData, setFactoidData] = useState(
    "MAREA (tide in English) is the world's highest-capacity transatlantic subsea cable."
  );
  const cableUrl = `../nap-assets/cable-info.json`;
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    remoteData({domain: domain, section: section, page: page})
      .then(data => {
        setPageData(data[0]);
        setLoading(false);
      });
    axios.get(cableUrl).then(response => {
      // console.log(response);
      const cables = response.data;
      setCableData({ cables });
    });
  }, [cableUrl]);

  let individualCableData = [];

  let filterResponse = (rawCableData, id) => {
    return (
      (individualCableData = rawCableData.cables.filter(
        cable => cable.id === id
      )),
      setDistanceData(individualCableData[0].distance),
      setCityData(individualCableData[0].locations),
      setDonutData(individualCableData[0].speed),
      setFactoidData(individualCableData[0].factoid),
      setOwnersData(individualCableData[0].owners)
    );
  };

  if(loading) {
    return <>Loading...</>;
  }

  return (
    <div>
      <RichmondNapHero />
      <Container>
        <FindAPropertyNav />
        <PageTitle
          title={
            pageTitle
          }
        />
      </Container>
      <IntroSection />
      <Container>
        <Quote data={pageData.s2} copy={quoteData.copy} signature={quoteData.signature} />
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} className="qts-col-1 no-padding">
            <Image src={NapBuilding} className="image-nap-building" />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className="qts-col-2">
            <Row>
              <Col>
               <div dangerouslySetInnerHTML={{ __html: pageData.s3 }} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col className="text-center charts-title">
            <p className="paragraph-header--blue paragraph-header--nap charts-second-title">
              Gain Unprecedented Access to Highest-Capacity,
              <br />
              Lowest-Latency Subsea Cables
            </p>
          </Col>
        </Row>
        <Row className="text-center pt-1 pb-4 charts-title-subheading">
          <Col>
            <p>Click to learn about their unique specifications.</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="hide-smaller">
          <Row>
            <Col className="text-center" xs={12} md={12} lg={3} xl={3}>
              <Button
                variant="charts"
                onClick={() => filterResponse(cableData, "1")}
                active
              >
                MAREA
              </Button>
            </Col>
            <Col className="text-center" xs={12} md={12} lg={3} xl={3}>
              <Button
                variant="charts"
                onClick={() => filterResponse(cableData, "2")}
              >
                BRUSA
              </Button>
            </Col>
            <Col className="text-center" xs={12} md={12} lg={3} xl={3}>
              <Button
                variant="charts"
                onClick={() => filterResponse(cableData, "3")}
              >
                DUNANT
              </Button>
            </Col>
            <Col className="text-center" xs={12} md={12} lg={3} xl={3}>
              <Button
                variant="charts"
                onClick={() => filterResponse(cableData, "4")}
              >
                SAEx1
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="text-center">
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col className="runway-align">
                <p className="overlay-miles">{distanceData} km</p>
                <Image src={runway}></Image>
              </Col>
            </Row>
            <Row>
              <Col className="runway-align-small">{cityData}</Col>
            </Row>
          </Col>
          <Col className="text-center">
            <div className="overlay-text1">
              <div className="overlay-style1">{donutData}</div>
              <div className="overlay-tbs1">Tb/s capacity</div>
            </div>
            <PercentageChart data={donutData}></PercentageChart>
          </Col>
          <Col className="text-center owners-align">
            <span>{ownersData}</span>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col className="text-center tidbit1">
            <span>{factoidData}</span>
          </Col>
        </Row>
        <hr className={"lineSeparator"} />
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{ __html: pageData.s4 }} />
          </Col>
        </Row>
        <NewsInsights />
      </Container>
    </div>
  );
};

export default RichmondNap;
