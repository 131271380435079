import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
// import Quote from "../shared/Quote/Quote";
import "./PressReleasesAndAnnouncementsPost.scss";

const PressReleaseAndAnnoucnementPost = ({ posts, loading }) => {
    const history = useHistory();
    if (loading) {
        return <h2>Loading...</h2>;
    }

    const handleClick = (e, articleSlug, postDate, articleId, externalLink) => {
        e.preventDefault();

        const url = `press-releases-and-announcements/${postDate}/${articleSlug}`;
        externalLink ? window.open(articleSlug) : (history.push(url, {
            articleId
        })) 
    }

    // const quoteOneData = {
    //     copy: "Quote 1",
    //     signature: "Lorem ipsum dolor sit amet, consectetur adipiscing"
    // }
    // const quoteTwoData = {
    //     copy: "Quote 2",
    //     signature: "Lorem ipsum dolor sit amet, consectetur adipiscing"
    // }

    return (
            <div>
                <Row>
                    {posts.slice(0, 3).map(post =>
                        (
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <Row>
                                    <Col>
                                    {post.title_archive && 
                                        <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}><img className="press-release-archive-image" src={post.image} alt={post.title_archive} /></a>
                                    }
                                    </Col>
                                </Row>
                                <Row className="archive-title-row">
                                    <Col>
                                    {post.title_archive && 
                                            <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}>{post.title_archive}</a>
                                    }
                                    </Col>
                                </Row>
                                <Row className="date-row date-row-text">
                                    <Col>
                                    {post.date && 
                                            <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}>{post.date}</a>
                                    }
                                    </Col>
                                </Row>
                            </Col>
                        )
                    )}
                </Row>
                {/* {posts.length >= 3 &&
                <Row>
                    <Quote copy={quoteOneData.copy} signature={quoteOneData.signature} />
                </Row>
                } */}
                <Row>
                    {posts.slice(3, 6).map(post =>
                        (
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <Row>
                                    <Col>
                                    {post.title_archive && 
                                        <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}><img className="press-release-archive-image" src={post.image} alt={post.title_archive} /></a>
                                    }
                                    </Col>
                                </Row>
                                <Row className="title-row">
                                    <Col>
                                    {post.title_archive && 
                                        <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}>{post.title_archive}</a>
                                    }
                                    </Col>
                                </Row>
                                <Row className="date-row date-row-text">
                                    <Col>
                                    {post.date && 
                                        <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}>{post.date}</a>
                                    }
                                    </Col>
                                </Row>
                            </Col>
                        )
                    )}
                </Row>
                {/* {posts.length >= 6 &&
                <Row>
                    <Quote copy={quoteTwoData.copy} signature={quoteTwoData.signature} />
                </Row>
                } */}
                <Row>
                    {posts.slice(6, 9).map(post =>
                        (
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <Row>
                                    <Col>
                                        {post.title_archive &&
                                            <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}><img className="press-release-archive-image" src={post.image} alt={post.title_archive} /></a>
                                        }
                                    </Col>
                                </Row>
                                <Row className="title-row">
                                    <Col>
                                        {post.title_archive &&
                                            <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}>{post.title_archive}</a>
                                        }
                                    </Col>
                                </Row>
                                <Row className="date-row date-row-text">
                                    <Col>
                                        {post.date &&
                                            <a className="link--black" onClick={(e) => handleClick(e, post.slug, post.date, post._id, post.external_link)} href={post.slug}>{post.date}</a>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        )
                    )}
                </Row>
            </div>
    )};

export default PressReleaseAndAnnoucnementPost;
