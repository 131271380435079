import React from 'react';
import "./Pagination.scss";

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleClick = (e, number) => {
        e.preventDefault();
        paginate(number);
    }

    return (
        <nav className="pagination-row">  
            <ul className="pagination">
                {pageNumbers.map(number => (
                     <li key={number} className="page-item">
                        <a onClick={(e) => handleClick(e, number)} href="#!" className="page-link">
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Pagination;