import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import Quote from "../shared/Quote/Quote";
import YouTube from 'react-youtube';
import "./VideoArchivePost.scss";

const VideoPost = ({ posts, loading }) => {
    if (loading) {
        return <h2>Loading...</h2>;
    }

    // const quoteOneData = {
    //     copy: "Quote 1",
    //     signature: "Lorem ipsum dolor sit amet, consectetur adipiscing"
    // }
    // const quoteTwoData = {
    //     copy: "Quote 2",
    //     signature: "Lorem ipsum dolor sit amet, consectetur adipiscing"
    // }

    const opts = {
        height: '300',
        width: '350',
        playerVars: {
            autoplay: 0,
        },
    };

    return (
        <div>
            <Row className="mt-0">
                {posts.slice(0, 3).map(post =>
                    (
                        <Col className="video-container" xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Row className="youtube-video-row">
                                <YouTube videoId={post.video_id} opts={opts} />
                            </Row>
                            <Row className="youtube-title-row">
                                {post.title &&
                                    <p className="youtube-video-title">{post.title}</p>
                                }
                            </Row>
                        </Col>
                    )
                )}
            </Row>
            {/* {posts.length >= 3 &&
                <Row>
                    <Quote className="quote-container-videos" copy={quoteOneData.copy} signature={quoteOneData.signature} />
                </Row>
                } */}
            <Row className="mt-0">
                {posts.slice(3, 6).map(post =>
                    (
                        <Col className="video-container" xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Row className="youtube-video-row">
                                <YouTube videoId={post.video_id} opts={opts} />
                            </Row>
                            <Row className="youtube-title-row">
                                {post.title &&
                                    <p className="youtube-video-title">{post.title}</p>
                                }
                            </Row>
                        </Col>
                    )
                )}
            </Row>
            {/* {posts.length >= 6 &&
                <Row>
                    <Quote copy={quoteTwoData.copy} signature={quoteTwoData.signature} />
                </Row>
                } */}
            <Row className="mt-0">
                {posts.slice(6, 9).map(post =>
                    (
                        <Col className="video-container" xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Row className="youtube-video-row">
                                <YouTube videoId={post.video_id} opts={opts} />
                            </Row>
                            <Row className="youtube-title-row">
                                {post.title &&
                                    <p className="youtube-video-title">{post.title}</p>
                                }
                            </Row>
                        </Col>
                    )
                )}
            </Row>
        </div>
    )
};

export default VideoPost;