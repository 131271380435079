import React, { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
const Dropdown = ({children, ...rest}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <NavDropdown
      nocaret="true"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      OnMouseOver={() => null}
      onMouseOut={() => null}
      show={hovered}
      {...rest}
    >
      {children}
    </NavDropdown>
  );
}

export default Dropdown;
