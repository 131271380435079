import { Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useInputChange } from './UseInputChange';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert'
import "./QualityOfLifeFormInput.scss";
import { sendLinkedInEvent, sendStackAdaptEvent } from '../../../utils/analytics';


const QualityOfLifeFormInput = () => {
    const [input, handleInputChange] = useInputChange();
    const [show, setShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [formShow, setFormShow] = useState(true);
    const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableButton(true);
    axios.post(`${process.env.REACT_APP_NEWS_URL}/forms/quality-of-life`, {
      firstName: input.firstName,
      lastName: input.lastName,
      email: input.email,
    })
      .then(res => {
        sendLinkedInEvent();
        sendStackAdaptEvent('lead_form');

        setAlertType(res.data.alertType);
        setAlertMessage(res.data.message);
        setShow(true);
        setFormShow(false);
      });
  }

    const handleAlertClose = (e) => {
        setShow(false);
    }

    return (
        <div className="contact-us-form-row">
            {show && (
                <Alert variant={alertType} onClose={(e) => handleAlertClose(e)} dismissible>
                    <Alert.Heading>Thank you! <a href="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/HEN23001_Annual+Report_CMYK_FINAL_Website+Version-compressed.pdf" target="_blank">Click HERE</a> to download your NEW Quality of Life Report</Alert.Heading>
                    <div className="d-flex justify-content-end">
                    </div>
                </Alert>
            )}
            {formShow && (
                <Form>
                    <Form.Label><p></p></Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} controlId="firstName">
                            <Form.Control name="firstName" onChange={handleInputChange} />
                            <Form.Text className="formText">
                                <p>First Name</p>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} controlId="lastName">
                            <Form.Control name="lastName" onChange={handleInputChange} />
                            <Form.Text className="formText">
                                <p>Last Name</p>
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Control name="email" onChange={handleInputChange} type="email" />
                            <Form.Text><p className="formText">Email</p></Form.Text>
                        </Form.Group>
                    </Form.Row>
                    <div class="buttonContainer">
                        <Button style={{backgroundColor:"#5CB7E7", border:"none", fontWeight:"600"}} className="button-primary button-large" type="submit" disabled={disableButton} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </Form>
            )}
        </div>
    );
};

export default QualityOfLifeFormInput;
