import React from 'react';
import { Route } from 'react-router-dom';
import { pathToTitle } from '../Utilities/Conversions';
import { Helmet } from 'react-helmet';
import { sendPageView } from '../../../utils/analytics';

const DefaultLayout = ({ title, ...rest }) => {
  const resolvedPath = rest['computedMatch']['url'];
  const pageTitle = title || pathToTitle(resolvedPath);

  return <>
    <Helmet
      defaultTitle="Henrico EDA"
      titleTemplate="Henrico EDA | %s"
      onChangeClientState={
        (newState) => sendPageView(resolvedPath, newState.title)
      }>
      <title>{pageTitle}</title>
    </Helmet>
    <Route {...rest} />
  </>;
};

export { DefaultLayout };
