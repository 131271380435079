import { Col } from "react-bootstrap";
import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useInputChange } from './UseInputChange';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert'
import './RequestAVisitForm.scss'

const RequestAVisitForm = () => {
    const [input, handleInputChange] = useInputChange();
    const [show, setShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [formShow, setFormShow] = useState(true);
    const [disableButton, setDisableButton] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisableButton(true);
        axios.post(`${process.env.REACT_APP_NEWS_URL}/forms/request-a-visit`, {
            companyName: input.companyName,
            address1: input.address1,
            address2: input.address2,
            city: input.city,
            state: input.state,
            zip: input.zip,
            country: input.country,
            firstName: input.firstName,
            lastName: input.lastName,
            title: input.title,
            email: input.email,
            telephone: input.telephone,
            message: input.message
        })
        .then(res => {
            setAlertType(res.data.alertType);
            setAlertMessage(res.data.message);
            setShow(true);
            setFormShow(false);
        });
    }

    const handleAlertClose = (e) => {
        setShow(false);
    }

    return (
        <div className="contact-us-form-row">
            {show && (
                <Alert variant={alertType} onClose={(e) => handleAlertClose(e)} dismissible>
                    <Alert.Heading>{alertMessage}</Alert.Heading>
                    <div className="d-flex justify-content-end">
                    </div>
                </Alert>
            )}
            {formShow && (
                <Form>
                    <Form.Label><p>Company Name*</p></Form.Label>
                    <Form.Group controlId="companyName">
                        <Form.Control name="companyName" onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Label><p>Company Address*</p></Form.Label>
                    <Form.Group controlId="address1">
                        <Form.Control name="address1" onChange={handleInputChange} />
                        <Form.Text className="text-muted">
                            Address 1
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="address2">
                        <Form.Control name="address2" onChange={handleInputChange} />
                        <Form.Text className="text-muted">
                            Address 2
                        </Form.Text>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="city">
                            <Form.Control name="city" onChange={handleInputChange} />
                            <Form.Text className="text-muted">
                                City
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} controlId="state">
                            <Form.Control name="state" onChange={handleInputChange} />
                            <Form.Text className="text-muted">
                                State
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="zip">
                        <Form.Control name="zip" onChange={handleInputChange} />
                        <Form.Text className="text-muted">
                            Zip/Postal Code
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="country">
                        <Form.Control name="country" onChange={handleInputChange} />
                        <Form.Text className="text-muted">
                            Country
                        </Form.Text>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="firstName">
                            <Form.Control name="firstName" onChange={handleInputChange} />
                            <Form.Text className="text-muted">
                                First Name
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} controlId="lastName">
                            <Form.Control name="lastName" onChange={handleInputChange} />
                            <Form.Text className="text-muted">
                                Last Name
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>

                    <Form.Label><p>Your Title*</p></Form.Label>
                    <Form.Group controlId="title">
                        <Form.Control name="title" onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Label><p>Email Address*</p></Form.Label>
                    <Form.Group controlId="email">
                        <Form.Control name="email" onChange={handleInputChange} type="email" />
                    </Form.Group>

                    <Form.Label><p>Phone*</p></Form.Label>
                    <Form.Group controlId="phone">
                        <Form.Control name="telephone" onChange={handleInputChange} type="tel" />
                        <Form.Text className="text-muted">
                            (###) ### ###
                        </Form.Text>
                    </Form.Group>

                    <Form.Label><p>Please tell us why you'd like a visit or any specific topics you'd like to address. *</p></Form.Label>
                    <Form.Group controlId="message">
                        <Form.Control name="message" onChange={handleInputChange} type="text" as="textarea" />
                    </Form.Group>

                    <Button className="button-primary button-green button-large" type="submit" disabled={disableButton} onClick={handleSubmit}>
                        Submit
                    </Button>
                </Form>
            )}
        </div>
    );
};

export default RequestAVisitForm;
