import React from "react";
// import './../hero/hero.scss';
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import './WhyHenrico.scss';
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import WhyHenricoHero from './WhyHenricoHero';
import { FactGrid } from './FactGrid';
import { slugify, titlefy } from '../../utils';
import { sendPageView } from '../../utils/analytics';
import { pathToTitle } from '../shared/Utilities/Conversions';
import { Helmet } from 'react-helmet';

class WhyHenrico extends React.Component{
    constructor(props) {
      super();
      this.state = {
        modalShow: false,
        cards: [],
        category: 'Pro-Business',
        modalData:{}
      }
      this.modalHandler = this.modalHandler.bind(this)
      this.onHide = this.onHide.bind(this);
      this.setModalShow = this.setModalShow.bind(this);
      this.getRandom = this.getRandom.bind(this);
      this.setInitialCategory = this.setInitialCategory.bind(this);
    }

    setModalShow(val){
        this.setState({modalShow: val});
    }

    onHide(){
        this.setModalShow(false);
    }

  setInitialCategory() {
    return window.location.pathname.replace('/why-henrico/', '');
  }

    componentDidMount() {
      this.setState({
        category: titlefy(this.setInitialCategory())
      });
    }
    getRandom(arr, n) {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    updateCategory(e, category) {
      this.setState(
        {
          category: category
        }
      );
      e.nativeEvent.preventDefault();
      window.history.pushState({}, null, `/why-henrico/${slugify(category)}`);
      document.title = `Henrico EDA | ${pathToTitle(window.location.pathname)}`;
      sendPageView(window.location.pathname, pathToTitle(window.location.pathname));
    }
    modalHandler(data){
        this.setModalShow(true);
        this.setState({modalData:data});
    }

    render() {
        function MyVerticallyCenteredModal(props) {
            return (
                <Modal
                    onHide={() => props.onHide()}
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                {/* TODO: Add header with colored cards */}
                    <Modal.Body>
                        <p style={{fontSize: '40px',color:"white"}}>{props.modalData.titleLine1}</p>
                        <p style={{color:"white"}}>{props.modalData.titleLine2}</p>
                        <p style={{color:"white"}}>
                            {props.modalData.text}
                        </p>
                        <Button style={{color:"white"}} className="button-primary button-modal" onClick={props.onHide}>Close</Button>
                    </Modal.Body>
                </Modal>
            );
        }

        return <div>
            <WhyHenricoHero />
            <Container>
            <div className="category-button-wrapper">
                <Row className="button-row button-row-1">
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/pro-business" onClick={(event) => this.updateCategory(event, 'Pro-Business')}  className="button-primary button-henrico-category" >
                            PRO-BUSINESS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/market-access" onClick={(event) => this.updateCategory(event, 'Market Access')} className="button-primary button-henrico-category" >
                             MARKET ACCESS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/skilled-workforce" onClick={(event) => this.updateCategory(event, 'Skilled Workforce')} className="button-primary button-henrico-category" >
                            WORKFORCE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/competitive-operating-costs" onClick={(event) => this.updateCategory(event, 'Competitive Operating Costs')} className="button-primary button-henrico-category" >
                            OPERATING COSTS
                        </a>
                    </Col>
                </Row>
                <Row className="button-row button-row-1">
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/infrastructure"  onClick={(event) => this.updateCategory(event, 'Infrastructure')} className="button-primary button-henrico-category" >
                            INFRASTRUCTURE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/qualityoflife" className="button-primary button-henrico-category" >
                            QUALITY OF LIFE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/fast-facts" onClick={(event) => this.updateCategory(event, 'Fast Facts')} className="button-primary button-henrico-category" >
                            FAST FACTS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/swam-certification" onClick={(event) => this.updateCategory(event, 'SWaM Certification')} className="button-primary button-henrico-category" >
                            SWAM
                        </a>
                    </Col> 
                </Row>
            </div>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <p className="heading-secondary why-henrico-category">{this.state.category}</p>
                    </Col>
                </Row>
                <FactGrid page={slugify(this.state.category)} />               
            </Container>
            <NewsInsights />
            <MyVerticallyCenteredModal
                show={this.state.modalShow}
                modalData = {this.state.modalData}
                onHide={() => {this.setModalShow(false)}}
            />
        </div>
    }
}

export default WhyHenrico;
