import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./FeaturedPropertiesPost.scss";

const FeaturedProperties = ({ posts, loading }) => {
    const history = useHistory();
    if (loading) {
        return <h2>Loading...</h2>;
    }

    const handleClick = (e, featuredPropertyId, slug) => {
        e.preventDefault();
        const url = `/find-a-property/featured-properties/${slug}`;
        history.push(url, {
            featuredPropertyId
        })
    }

    const officeSpaceProperties = posts.filter(property => property.property[0].type === 'office-space');
    const industrialManufacturingProperties = posts.filter(property => property.property[0].type === 'industrial-and-manufacturing-space');
    const landAcreageProperties = posts.filter(property => property.property[0].type === 'land-and-acreage-parcels');

    return (
        <div>
            <Row>
                <Col xs={12} lg={4}>
                    <p className="paragraph-header--blue carousel-property-type">Office Space</p>
                    <Carousel
                        className="featured-property-carousel"
                        showStatus={false}
                        showIndicators={false}
                        infiniteLoop={true}
                    >
                        {officeSpaceProperties.map(property => {
                            return property.images[0] ? <div
                                onClick={(e) => handleClick(e, property._id, property.slug)}
                                className="slider-column"
                            >  
                                <img className="carousel-image" alt="" src={property.images[0].featured.image} />
                                {property.images[0].featured.caption !== '' ? <p className="legend">{property.images[0].featured.caption}</p> : ''}
                            </div> : <img alt="" src='' />
                        })}
                    </Carousel>
                </Col>
                <Col xs={12} lg={4}>
                    <p className="paragraph-header--blue carousel-property-type">Industrial & Manufacturing</p>
                    <Carousel
                        className="featured-property-carousel"
                        showStatus={false}
                        showIndicators={false}
                        infiniteLoop={true}
                    >
                        {industrialManufacturingProperties.map(property => {
                            return property.images[0] ? <div
                                onClick={(e) => handleClick(e, property._id, property.slug)}
                                className="slider-column"
                            >
                                <img className="carousel-image" alt="" src={property.images[0].featured.image} />
                                {property.images[0].featured.caption !== '' ? <p className="legend">{property.images[0].featured.caption}</p> : ''}
                            </div> : <img alt="" src='' />
                        })}
                    </Carousel>
                </Col>
                <Col xs={12} lg={4}>
                    <p className="paragraph-header--blue carousel-property-type">Land & Acreage Parcels</p>
                    <Carousel
                        className="featured-property-carousel"
                        showStatus={false}
                        showIndicators={false}
                        infiniteLoop={true}
                    >
                        {landAcreageProperties.map(property => {
                            return property.images[0] ? <div
                                onClick={(e) => handleClick(e, property._id, property.slug)}
                                className="slider-column"
                            >
                                <img className="carousel-image" alt="" src={property.images[0].featured.image} />
                                {property.images[0].featured.caption !== '' ? <p className="legend">{property.images[0].featured.caption}</p> : ''}
                            </div> : <img alt="" src='' />
                        })}
                    </Carousel>
                </Col>
            </Row>
        </div>
    )
};

export default FeaturedProperties;