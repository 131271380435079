import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/data-centers-logos.png';
import "./TargetSectors.scss";
import "./DataCenters.scss";
import Quote from "../shared/Quote/Quote.js";
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';

const pageTitle = 'Data Centers';

// const paragraphStyles = {
//     fontWeight: 600
// };

// const copyTitle = {
//     color: '#054496',
//     fontWeight: '600'
// }

const quoteData = {
    copy: "“As we continue expansion of the DE-CIX global interconnection and peering platform, Henrico – with its strategic location along the East Coast, diversity from Ashburn, and proximity to subsea cables – was the logical choice to make our footprint.”",
    signature: "Ivo Ivanov, CEO, DE-CIX International"
}

const quote2Data = {
    copy: "“We are proud to be part of the Henrico County community. When considering locations, we not only look for renewable energy solutions, but great partnerships within the local community, a strong pool of local talent, excellent access to fiber, and a robust electric grid. Henrico and the Commonwealth of Virginia has it all.”",
    signature: "Amber Tillman, Community Development Regional Manager, Meta"
}

const FinanceAndInsurance = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                <Row className="no-margin">
                    <Col>
                    <div className="featured-video-text" ><h4>The Future is Here.</h4></div>
                    <div className="featured-video-text2" ><p>When it comes to revolutionizing global internet infrastructure, Henrico is paving the way. Just how will amaze you. Watch.</p></div>
                    <div className="featured-video" ><iframe width="860" height="484" src="https://www.youtube.com/embed/o-_4HtwIYn0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                    </Col>
                </Row> 
                {/* <Quote copy={quoteData.copy} signature={quoteData.signature} />                                 */}
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>
                        Henrico has an internet infrastructure so robust that it can advance the success of even the largest data center operator through the digital age and beyond. We house both the <a href="/richmond-nap">QTS Richmond Network Access Point (NAP)</a> and <a href="https://www.de-cix.net/en/locations/richmond" target="_blank;">DE-CIX Richmond</a>, offering unbeatable access to the subsea cables MAREA, BRUSA, SAEx1, and Dunant, and providing a gateway to the largest interconnection ecosystem in North America. This dynamic duo is the basis of our powerful digital ecosystem, ensuring that data flows constantly and companies can easily reach business epicenters and emerging markets worldwide.
                        <br/><br/>
                        Build your data center in Henrico and discover what Meta already knows: The entire framework for your business can be built and sustained in one great location.
                        Discover more about how Henrico can support your business by downloading our convenient <a href="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/HEN22014_Data_Centers_8.5x11_v4.pdf" target="_blank;" title="Data Centers Brochure PDF">Data Center brochure</a>.
                    </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/datacenter1.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Unrivaled Connectivity</h3></p>
                    <p>Through DE-CIX and the QTS Richmond NAP, businesses can take advantage of access to 20+ different fiber networks and four subsea cables, 
                        in addition to more than 3,000 network operators and more than 500 data centers worldwide. Maybe best of all, 
                        Richmond DE-CIX is data center and carrier neutral, an exceptional feature that allows businesses to enjoy the reliability, 
                        flexibility, scalability, and adaptability that a full suite of connectivity options affords. 
                        A presence in Henrico means open access to a Data Autobahn, where you can drive your business toward success.
                        A presence in Henrico means you are located in a strategically designed 
                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/HEN23001_HEN23001_VA+Digital+Connection+1-Pager_8.5x11_v1.pdf" target="_blank"> global communications hub</a> with open access to a Data Autobahn. 
                        Here, you can drive your business toward success.                       
                    </p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/datacenter2.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Fast-track Construction</h3></p>
                    <p>As the go-to destination for data centers looking for new business opportunities, we have a rich history of getting projects up and operational quickly. And to make your build even simpler, Henrico is also proud to offer perfectly zoned sites at our premier <a href="/white-oak/quick-facts">White Oak Technology Park</a>.
                    <br/><br/>This high-tech industrial park is master planned and designed to meet the scale, robust power, and high-speed connection requirements of enterprise, colocation, and hyperscale data centers. Grow your data center here and have access to shovel-ready sites in addition to a fast-track planning and permitting process. Site work on Facebook’s 2.5M-sq.-ft. Henrico Data Center began just four months after the announcement was made, thanks to our quick approval process.</p>
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/datacenter3.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Unsurpassed Data Speeds</h3></p>
                    <p>Henrico offers the fastest data speeds on the planet. Google’s Dunant cable offers a record-breaking 250 Tbps capacity – fast enough to transmit the entire digitized Library of Congress three times every second. Thanks to Richmond DE-CIX, local businesses can send data to Spain and back in one-third of a second – the time it takes to blink. And in two milliseconds – two flashes of a camera – your data can be sent to 80% of the U.S. population.</p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/datacenter4.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Subsea Cable Convergence</h3></p>
                    <p>Through the QTS Richmond NAP in Henrico, businesses can tap into MAREA, BRUSA, SAEx1, and Dunant. The four subsea cables span the globe, extending to Spain, Rio de Janeiro, Brazil, and Puerto Rico, South Africa, and France, respectively.</p>
                    </Col>                                       
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/datacenter5.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Low Electricity Costs</h3></p>
                    <p>Looking for a superior location to build your data center operations, all powered by affordable, reliable, and clean energy? You’ve found it. Henrico’s industrial electric rates are 31% below the national average. And it’s all brought to you by Dominion Energy. The leading energy company offers a <a href="https://www.dominionenergy.com/virginia/renewable-energy-programs/green-power" target="_blank">Green Power program</a> where businesses can match their electricity use with renewable energy.</p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/datacenter6.png" width="100%"/>
                    <br/><br/>
                    <p><h3><a href="/site-selectors/taxes" target="_blank;">Low Tax Rates</a></h3></p>
                    <p>We work with the data center industry to improve its bottom line. Our data center tax rate is $0.40/$100 on computer and related equipment. Electric rates are more than 20% below the southeast region average. In 2022, we lowered our real estate tax rate from $0.87 to $0.85</p>
                    </Col>                                       
                </Row>                                  
                {/* <Quote copy={quote2Data.copy} signature={quote2Data.signature} /> */}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default FinanceAndInsurance;