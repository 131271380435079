import { Col, Container, Row } from "react-bootstrap";
import React from "react";


import NewsInsights from '../shared/NewsInsights/NewsInsights';
import FindAPropertyHero from './FindAPropertyHero';
import FindAPropertyNav from "../shared/Navs/FindAPropertyNav";
import PageTitle from "../shared/PageTitle/PageTitle";

const pageTitle = "GreenCity";

const GreenCity = () => {
  return (
    <div>
      <FindAPropertyHero />
      <Container>
        <FindAPropertyNav />
        <PageTitle title={pageTitle} />
        <Row>
          <Col lg={12} sm={12} md={12} xl={12}>
            <p>
            <strong>Get to Know GreenCity</strong>
            </p>
            <p>
            GreenCity is a visionary ecodistrict proposed for the former Best Products headquarters site in Henrico. The $2.3 billion multi-use community would offer ample space for virtually any business, spanning nearly 200 acres and generating about 2.3 million square feet of office and retail space, 2,400 housing units, and two hotels. As a beacon of innovation and environmental sustainability, several buildings would provide net-positive energy and water and produce zero waste. Discover more about the proposal on GreenCity’s <a href="https://www.greencityva.com/" target="_blank" rel="noopener noreferrer">official site</a> – and keep checking back here for the most up-to-the-minute GreenCity developments.
            </p>
          </Col>
        </Row>
        <NewsInsights />
      </Container>
    </div>
  );
};

export default GreenCity;
