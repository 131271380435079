import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import chart from "./images/image-asset.png";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "./ExistingBusinessHero";
import ExistingBusinessNav from "../shared/Navs/ExistingBusinessNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import DataCard from "../shared/DataCard/DataCard";
import "./WorkforceAssistance.scss";
import { RemoteContent } from "../shared/Cms";

const cards = [
  {
    titleLine1: "88%",
    titleLine2: "",
    text: "of the workforce has high school diploma",
    categoryColor: "5CB4AA", // green
  },
  {
    titleLine1: "43%",
    titleLine2: "",
    text: "of the workforce has a bachelor’s degree or higher",
    categoryColor: "5CB4AA", // blue
  },
  {
    titleLine1: "12%",
    titleLine2: "",
    text: "of the population represents 110 countries and multiple languages",
    categoryColor: "5CB4AA",
  },
];

const WorkforceAssistance = () => {
  const { domain, section, page } = {
    domain: "henrico",
    section: "existing-business",
    page: "Workforce Assistance",
  };
  return (
    <div>
      <ExistingBusinessHero />
      <Container>
        <ExistingBusinessNav />
        <PageTitle
          title={
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="title"
            />
          }
        />
        <Row>
          <Col>
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s1"
            />
          </Col>
        </Row>
        <Row className="mt-0">
          <Col xs={12} sm={12} md={12} lg={9}>
            <Row>
              <Col>
                <RemoteContent
                  domain={domain}
                  section={section}
                  page={page}
                  field="s2"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RemoteContent
                  domain={domain}
                  section={section}
                  page={page}
                  field="s3"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RemoteContent
                  domain={domain}
                  section={section}
                  page={page}
                  field="s4"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RemoteContent
                  domain={domain}
                  section={section}
                  page={page}
                  field="s6"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RemoteContent
                  domain={domain}
                  section={section}
                  page={page}
                  field="s7"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RemoteContent
                  domain={domain}
                  section={section}
                  page={page}
                  field="s8"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3} className="image-column">
            <Row>
              <img alt="" className="chart-image" src={chart}></img>
            </Row>
            <Row className="card-row-three-cards">
              {cards.map((card) => {
                return (
                  <Col
                    className="card-column"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={12}
                    xl={12}
                  >
                    <DataCard
                      key={card.titleLine1}
                      cardClass={`${card.categoryColor}`}
                      titleLine1={card.titleLine1}
                      titleLine2={card.titleLine2}
                      text={card.text}
                      fontSize="0.9rem"
                      cardSize="large"
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <NewsInsights />
      </Container>
    </div>
  );
};

export default WorkforceAssistance;
