import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./SecondaryNav.scss";

const AboutUsNav = (props) => {
    return (

        <div>
            <Navbar expand="xs" className="secondary-navabar">
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/about-us/our-team"
                >
                    Our Team
                </NavLink>
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/about-us/contact-us"
                >
                    Contact Us
                </NavLink>
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/about-us/find-us"
                >
                    Find Us
                </NavLink>
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/about-us/careers"
                >
                    Careers
                </NavLink>
                <span className="secondary-nav-separator">•</span>                
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/about-us/meeting-minutes"
                >
                    Meeting Minutes
                </NavLink>
            </Navbar>
        </div>
    );
}

export default AboutUsNav;