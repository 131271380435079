import React from "react";
import heroImage from './images/findAProperty-new.png';
import Hero from '../shared/Hero/Hero';
import AlertBanner from '../shared/AlertBanner/AlertBanner';

const title = "Find A Property";

const FindAProperty = () => {
    return (
        <div>
            <Hero image={heroImage} title={title} heroCaption="Innsbrook"/>
            <AlertBanner /> 
        </div>
    );
};

export default FindAProperty;