import { Row}  from "react-bootstrap";
import './NewsInsights.scss';
import NewsCard from './NewsCard';
// import { useHistory } from 'react-router';
import axios from 'axios';
import React, { useState, useEffect } from "react";

// import interconnectivity from "./images/news-insights-interconnectivity.png";
// import exercise from "./images/exercise_image.png";
// import running from "./images/running_image.png";
// import facebook from "./images/facebook-card.jpg";

// const cards = [
//     {
//         title: 'HENRICO EDA MAKES $2 MILLION DEPOSIT WITH VIRGINIA COMMUNITY CAPITAL',
//         imageUrl: 'https://henrico-eda-site-assets.s3.amazonaws.com/news-insights/2M.jpg',
//         link: '/news/press-releases-and-announcements/2020-04-22/henrico-eda-makes-2M-deposit-with-virginia-community-capital',
//     },
//     {
//         title: 'CHAMBERRVA: PAYCHECK PROTECTION PROGRAM (PPP) UPDATE',
//         imageUrl: 'https://henrico-eda-site-assets.s3.amazonaws.com/news-insights/chamber.jpg',
//         linkUrl: 'https://www.chamberrva.com/news/coronavirus-resources-for-rva-businesses/',
//     },
//     {
//         title: 'Henrico Offers the Best of Active Living',
//         imageUrl: exercise,
//         linkUrl: 'https://www.henrico.com/press-releases-1/2019/11/8/richmond-virginia-one-of-worths-ten-cities-to-watch',
//     },
//     {
//         title: 'Why Millennials Choose to Live in Henrico',
//         imageUrl: running,
//         linkUrl: 'https://www.henrico.com/press-releases-1/2019/12/16/henrico-virginia-commute-time-among-best-in-nation',
//     }
// ]

const NewsInsights = (props) => {
    // const history = useHistory();

    const [newsInsights, setNewsInsights] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_NEWS_URL}/press-releases-and-announcements`)
            .then(res => {
                setLoading(true);
                const pressReleasesAndAnnouncements = res.data.pressReleasesAndAnnouncements;
                const newsInsights = pressReleasesAndAnnouncements.sort(function (a, b) {
                    return (b.date < a.date) ? -1 : ((b.date > a.date) ? 1 : 0);
                });
                const fourNewsInsights = newsInsights.slice(0,4);
                setNewsInsights(fourNewsInsights);
                setLoading(false);
            })
    }, []);

    if (loading) {
        return <h2>Loading...</h2>;
    }

    return (
        <div className="main-body-container">
            <Row className="justify-content-center row-news-insights">
                <p className="heading-secondary news-insights-title">News & Insights</p>
            </Row>
            <Row className="justify-space-between news-card-row">
                {newsInsights.map(card => {
                    return <NewsCard 
                        key={card.title}
                        cardTitle={card.title_archive}
                        imageUrl={card.image_news_insights}
                        link={card.slug}
                        date={card.date}
                        externalLink={card.external_link}
                        id={card._id}
                        columnsXs={6}
                        columnsSm={6}
                        columnsMd={3}
                        columnsLg={3}
                        columnsXl={3}
                        height='300'
                        width='200'
                    />
                })}
            </Row>
        </div>
    )
}

export default NewsInsights;