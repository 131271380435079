import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NewsHero from "../shared/NewsHero/NewsHero";
import NewsNav from "../shared/Navs/NewsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import DOMPurify from "dompurify";
import "./SinglePressReleaseAndAnnouncement.scss";

const pageTitle = "Press Releases & Announcements";
const PressReleasesAndAnnouncements = (props) => {
  const location = useLocation();
  let queryData;
  let queryType;

  if (location.state && location.state.pressReleaseId !== undefined) {
    queryData = location.state.pressReleaseId;
    queryType = "id";
  } else {
    queryData = location.pathname.split("/").slice(-1)[0];
    queryType = "title";
  }

  const [contact, setContact] = useState();
  const [title, setTitle] = useState();
  const [headline, setHeadline] = useState();
  const [image, setImage] = useState();
  const [copy, setCopy] = useState();
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_NEWS_URL}/press-releases-and-announcements/single`,
        {
          queryData,
          queryType,
        }
      )
      .then((res) => {
        setLoading(true);
        setContact(
          res.data.pressRelease && res.data.pressRelease.contact_single
        );
        setTitle(res.data.pressRelease && res.data.pressRelease.title_single);
        setImage(res.data.pressRelease && res.data.pressRelease.image);
        setCopy(res.data.pressRelease && res.data.pressRelease.copy);
        setDate(res.data.pressRelease && res.data.pressRelease.date);
        setHeadline(
          res.data.pressRelease && res.data.pressRelease.headline_single
        );
        setLoading(false);
      });
  }, []);

  const cleanContact = DOMPurify.sanitize(contact);
  const cleanTitle = DOMPurify.sanitize(title);
  const cleanHeadline = DOMPurify.sanitize(headline);

  // Add ability to load iframe as part of copy
  const cleanHtmlCopy = DOMPurify.sanitize(copy, {
    ADD_TAGS: ["iframe"], // ALLOWED_TAGS
    ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"], // ALLOWED_ATR
  });

  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
    if (
      !node.hasAttribute("target") &&
      (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
    ) {
      node.setAttribute("xlink:show", "new");
    }
  });

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div>
      <NewsHero />
      <Container>
        <NewsNav />
        <PageTitle title={pageTitle} />
        <Row>
          <Col className="post-contact">
            <p
              className="single-press-release-contact"
              dangerouslySetInnerHTML={{ __html: cleanContact }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="post-logo">
            <img src={image} alt={title} />
          </Col>
        </Row>
        <Row className="single-title-row">
          <Col>
            <h1
              className="single-press-release-title"
              dangerouslySetInnerHTML={{ __html: cleanTitle }}
            />
          </Col>
        </Row>
        <Row className="single-date-row">
          <Col>
            <p className="date">{date}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2
              className="single-press-release-headline"
              dangerouslySetInnerHTML={{ __html: cleanHeadline }}
            />
          </Col>
        </Row>
        <Row className="single-copy-row">
          <Col xs={12}>
            <Row>
              <Col>
                <p dangerouslySetInnerHTML={{ __html: cleanHtmlCopy }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PressReleasesAndAnnouncements;
