import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import WhyHenricoHero from "./WhyHenricoHero";
import WhyHenricoNav from "../shared/Navs/WhyHenricoNav";
import PageTitle from "../shared/PageTitle/PageTitle";

const pageTitle = "Annual Report";

const AnnualReport = () => {
  return (
    <div>
      <WhyHenricoHero />
      <Container>
        <WhyHenricoNav />
        <PageTitle title={pageTitle} />
        <Row>
          <Col lg={12} sm={12} md={12} xl={12}>
            <p>
            Eager to know how the Henrico EDA is achieving our mission of cultivating a vibrant economy and promoting an outstanding quality of life for all? Check out these highlights from a successful past year in our annual report.
            </p>
            <p>
            Read the Henrico EDA’s{" "}
              <a
                href="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/HEN23001_Annual+Report_CMYK_FINAL_Website+Version-compressed.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                2024 Look Book
              </a>
               .<br></br>Download our <a href="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/Henrico-County-EDA-Strategic-Plan-FINAL-0422-2020.pdf" download>Economic Development Strategic Plan</a>. 
            </p>            
          </Col>
        </Row>
        <NewsInsights />
      </Container>
    </div>
  );
};

export default AnnualReport;
