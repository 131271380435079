import { Container } from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import SiteSelectorsHero from './SiteSelectorsHero';
import HenricoPropertySearch from "../shared/AvailablePropertySearch/HenricoPropertySearch";
import SiteSelectorsNav from '../shared/Navs/SiteSelectorsNav';

const SsAvailablePropertySearch = () => {
    return (
        <div>
            <SiteSelectorsHero />
            <Container>
                <SiteSelectorsNav />
                <HenricoPropertySearch />
                <NewsInsights />
            </Container>
        </div>
);
};

export default SsAvailablePropertySearch;