import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import React from "react";
import "./ResourcesAndDownloads.scss";
import dataSheet from "../FindAProperty/images/Group 33.png";
import report from "../FindAProperty/images/Group 32.png";
import plain from "../FindAProperty/images/Group 34.png";
import { RemoteContent } from "../..//shared/Cms";

const ResourceAndDownloads = () => {
  const { domain, section, page } = {
    domain: "henrico",
    section: "white-oak-tech-park",
    page: "Resources And Downloads",
  };
  return (
    <div>
      <Row className="resources-downloads">
        <Col>
          <p className="heading-secondary downloads-title">
            Resources / Downloads
          </p>
          <Row className="row-downloads">
            <Col xs={12} sm={12} md={12} lg={4}>
              <Row className="internal-resources-row">
                <Col xs={5} sm={5} className="additional-resources-icon-column">
                  <Image alt="" src={dataSheet}></Image>
                </Col>
                <Col xs={7} sm={7}>
                  <RemoteContent
                    domain={domain}
                    section={section}
                    page={page}
                    field={"s1"}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <Row className="internal-resources-row">
                <Col xs={5} sm={5} className="additional-resources-icon-column">
                  <Image alt="" src={report}></Image>
                </Col>
                <Col xs={7} sm={7}>
                  <RemoteContent
                    domain={domain}
                    section={section}
                    page={page}
                    field={"s2"}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <Row className="internal-resources-row">
                <Col xs={5} sm={5} className="additional-resources-icon-column">
                  <Image alt="" src={plain}></Image>
                </Col>
                <Col xs={7} sm={7}>
                  <RemoteContent
                    domain={domain}
                    section={section}
                    page={page}
                    field={"s3"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ResourceAndDownloads;
