import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { initializeVimeoPlayer } from './VimeoSetup';
import "./HenricoNowArchivePost.scss";

const HenricoNowArticle = ({ henricoNowArticles, loading }) => {
    const history = useHistory();
    if (loading) {
        return <h2>Loading...</h2>;
    }

    const handleClick = (e, articleSlug, articleId) => {
        e.preventDefault();
        const url = `${articleSlug}`;
        history.push(url, {
            articleId
        })
    }

    return (
            <div>
                {henricoNowArticles.map(newsArticle => (
                    <Row key={newsArticle._id}>
                        <Col xs={12} sm={12} md={3} lg={2}>
                            <a onClick={(e) => handleClick(e, newsArticle.slug, newsArticle._id)} href={newsArticle.slug}><img className="henrico-now-archive-thumbnail" src={newsArticle.image_small} alt="thumbnail" /></a>
                        </Col>
                        <Col xs={12} sm={12} md={9} lg={10}>
                            <p className="article-title paragraph-header--blue"><a onClick={(e) => handleClick(e, newsArticle.slug, newsArticle._id)} href={newsArticle.slug}>{newsArticle.title}</a></p>
                            <p className="article-excerpt">{newsArticle.excerpt}</p>
                            <p className="article-date">{newsArticle.date}</p>
                        </Col>
                    </Row>
                ))}
            </div>
        );
};

export default HenricoNowArticle;