export const slugify = (str) => {
  return str ? str.toLowerCase().replace(/\s/g , "-") : "";
};

export const titlefy = (str) => {
  return str
    ? str
    .replace(
      "#",""
    )
    .replace(
      /(^\w{1})|(-{1}\w{1})/g,
      match => match.toUpperCase()
    ).replace(
      /-/g, " "
    ) : "";
}
