import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NewsInsights from "../../components/shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "../ExistingBusiness/ExistingBusinessHero";
import "./GrowYourBusiness.scss";
import ExistingBusinessNav from "../shared/Navs/ExistingBusinessNav";
import { RemoteContent } from "../shared/Cms";
import PageTitle from "../shared/PageTitle/PageTitle";

const GrowYourBusiness = () => {
  const { domain, section, page } = {
    domain: "henrico",
    section: "existing-business",
    page: "Grow Your Business",
  };

  return (
    <div>
      <ExistingBusinessHero />
      <Container>
        <ExistingBusinessNav />
        <PageTitle
          title={
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="title"
            />
          }
        />
        <Row>
          <Col>
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s1"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s2"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="paragraph-header--black1">
              <b>
                Call the Henrico EDA at{" "}
                <a href="tel:1-804-501-7654">(804) 501-7654</a> today to discuss
                expansion opportunities.
              </b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s3"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s4"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div id="container">
              <a href="/existing-business/grow-your-business/request-a-visit">
                <button className="button-primary button-green button-grow-business">
                  <strong>REQUEST A VISIT</strong>
                </button>
              </a>
              <a href="/existing-business/grow-your-business/business-first-survey">
                <button className="button-primary button-green button-grow-business button-large">
                  <strong>VOLUNTEERS CLICK HERE</strong>
                </button>
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s5"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="s6"
            />
          </Col>
        </Row>
        <NewsInsights />
      </Container>
    </div>
  );
};

export default GrowYourBusiness;
