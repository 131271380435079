import React from "react";
import heroImage from './images/site-selectors-hero.png';
import Hero from '../shared/Hero/Hero';

const title = "Site Selectors";


const SiteSelectorsHero = () => {
    return (
        <div>
        <Hero image={heroImage} title={title} heroCaption="Port of Richmond"/>
        </div>
    );
};

export default SiteSelectorsHero;