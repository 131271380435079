import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "./ExistingBusinessHero"
import ExistingBusinessNav from '../shared/Navs/ExistingBusinessNav';
import HenricoLegacyCompaniesForm from "../shared/Forms/HenricoLegacyCompaniesForm";
import './MajorEmployersAndLegacyCompanies.scss';

import PageTitle from '../shared/PageTitle/PageTitle';
// import { image } from "d3";

const pageTitle = 'Henrico Legacy Companies';

const MajorEmployersLegacyCompaniesForm = () => {
    return (
        <div>
            <ExistingBusinessHero/>
            <Container>
                <ExistingBusinessNav/>
                <PageTitle title={pageTitle}/>
                <Row>
                    <Col>
                        <p>Yes! My business has operated in Henrico County for 25 years or more. Please add my information.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <HenricoLegacyCompaniesForm />
                    </Col>
                </Row>
                <NewsInsights/>
            </Container>
        </div>
    );
};

export default MajorEmployersLegacyCompaniesForm;