import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/supply-chain-management-logos.png';
import "./TargetSectors.scss";
import DataCard from '../shared/DataCard/DataCard';
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import "./SupplyChainManagement.scss";

const pageTitle = 'Supply Chain Management';

const cards = [
    {
        titleLine1: "Since",
        titleLine2: "1611",
        text: 'Henrico has been a center for commerce and trade.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "Reach Your Markets Quickly",
        titleLine2: "",
        text: 'More than 55% of the U.S. population and major manufacturing sites are located within 750 miles of Henrico.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "140M Pounds of Cargo",
        titleLine2: "",
        text: 'are handled annually at Richmond International Airport.',
        categoryColor: '66A7CF',
    }
]

const SupplyChainManagement = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                        <p>
                            With our strategic East Coast location and multiple transportation hubs, Henrico is the perfect place for businesses dealing 
                            in supply chain management and logistics. Our access to road, rail, and airport facilities, as well our proximity to the 
                            Port of Virginia, allows you to get your products to market easily and efficiently.
                        </p>

                        <p>
                            In 2021, an e-commerce titan loved all the ways our location could support its global business and invested heavily 
                            in our locality as a result. Watch this video about "Project Speedway" and see how Henrico partnered with 
                            Clark Construction Group to fast track the safe completion of a a 2.7 million square foot facility – in 
                            just 12 months.
                        </p>
                        <div className="video-container text-center embed-responsive embed-responsive-16by9 text-center">
                            <iframe className="video-iframe" 
                                src="https://www.youtube.com/embed/juizaPszgJw" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen 
                            >
                            </iframe>
                        </div>
                        <p>
                            Learn more about the advantages of choosing Henrico – including our low taxes and vast labor pool – 
                            in this handy 
                            <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/17337-HEN23001_Supply%20Chain%20Management_8.5%20X%2011_v6.pdf" target="_blank;"> Supply Chain Management brochure</a>.
                        </p>
                        <p>
                            Looking to boost your bottom line? Find out more about our 
                                <a href="/site-selectors/incentives"> supply chain management incentives </a>                       
                            available for eligible businesses.
                        </p>
                    </Col>
                </Row>
                <Row className="card-row-three-cards">
                    {cards.map(card => {
                        return <Col className="card-column" xs={6} sm={6} md={6} lg={4}>
                            <DataCard
                                key={card.titleLine1}
                                cardClass={`${card.categoryColor}`}
                                titleLine1={card.titleLine1}
                                titleLine2={card.titleLine2}
                                text={card.text}
                                fontSize="0.9rem"
                                cardSize="large"
                            />
                        </Col>
                    })}
                </Row>
                <Row className="justify-content-center">
                    <img className="logo-space" src={LogosImage} width="100%" />
                </Row>
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default SupplyChainManagement;
