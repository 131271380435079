export const sortArticles = (articles) => {
  const sortedArticles = articles.sort(function (a, b) {
    return (b.date < a.date) ? -1 : ((b.date > a.date) ? 1 : 0);
  });

  return sortedArticles;
};

export const sortEvents = (events) => {
  const sortedEvents = events.sort((a, b) => {
    return (a.archive_date < b.archive_date) ? -1 : ((a.archive_date > b.archive_date) ? 1 : 0);
  });

  return sortedEvents;
};