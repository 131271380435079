import React from "react";

import Hero from '../shared/Hero/Hero';
import heroImage from "./images/eda-building.jpg";
const title = "About Us";

const AboutUsHero = () => {
    return (
        <div>
            <Hero image={heroImage} title={title} heroCaption="Henrico Economic Development Authority"/>
        </div>
    );
};

export default AboutUsHero;