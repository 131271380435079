import React from "react";

import Hero from '../shared/Hero/Hero';
import heroImage from "./images/existing-business-hero.png";
const title = "Existing Businesses";

const ExistingBusinessHero = () => {
    return (
        <div>
            <Hero image={heroImage} title={title} heroCaption="Alfa Laval"/>
        </div>
    );
};

export default ExistingBusinessHero;