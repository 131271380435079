import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NewsInsights from "../../components/shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "../ExistingBusiness/ExistingBusinessHero";
import ExistingBusinessNav from '../shared/Navs/ExistingBusinessNav';
import './ExportingAssistance.scss';
import PageTitle from '../shared/PageTitle/PageTitle';
import { RemoteContent } from '../shared/Cms';

const ExportingAssistance = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'existing-business',
    page: 'Exporting Assistance'
  };
    return (
        <div>
            <ExistingBusinessHero />
            <Container>
                <ExistingBusinessNav />
                  <PageTitle title={
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="title"
                    />
                  } />
                <Row>
                  <Col>
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="s1"
                    />
                  </Col>
                </Row>
                <Row className="video-center">
                      <Col md={6} sm={12} xs={12}>
                        <div className="exporting-assitance-videos-wrapper">
                            <iframe title="video1" width="100%" height="100%" src="https://www.youtube.com/embed/H8zYiq4gZXY"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                        </div>
                      </Col>
                    <Col md={6} sm={12} xs={12}>
                        <div className="exporting-assitance-videos-wrapper">
                            <iframe title="video2" width="100%" height="100%" marginLeft="40px" src="https://www.youtube.com/embed/idYLJSG70Mc"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                        </div>

                    </Col>
                  </Row>
                <Row>
                  <Col>
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="s2"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="s3"
                    />
                  </Col>
                </Row>
                <NewsInsights />
            </Container>
        </div>
    );
};

export default ExportingAssistance;
