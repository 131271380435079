import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import NewsHero from "../shared/NewsHero/NewsHero";
import NewsNav from "../shared/Navs/NewsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import Pagination from "../shared/Pagination/Pagination";
import WebinarsArchivePost from "./WebinarsArchivePost";
import "./WebinarsArchive.scss";

const pageTitle = "Webinars";
const Webinars = data => {
  const [sortedVideos, setSortedVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  const webinars = [
    {
      imageUrl:
        "https://henrico-eda-site-assets.s3.amazonaws.com/upcoming-events/webthumb.jpg",
      title:
        "Henrico Internet Infrastructure Webinar Series: The Past, Present & Future",
      webinarUrl:
        "https://youtu.be/ajYRnlEetkk"
    },
    {
      imageUrl:
        "https://henrico-eda-site-assets.s3.amazonaws.com/press-releases-and-announcements/2021/webinar610211.png",
      title:
        "Henrico Internet Infrastructure Webinar Series: Accelerating Digital Transformation",
      webinarUrl:
        "https://www.youtube.com/watch?v=QfvQTtYTK5E"
    },
    {
      imageUrl:
        "https://henrico-eda-site-assets.s3.amazonaws.com/upcoming-events/HEDA-Speaker-Square---1200-X-1200---0215-(1)2.jpg",
      title:
        "Henrico Internet Infrastructure Webinar Series: Global Connectivity Enhancements",
      webinarUrl:
        "https://www.youtube.com/watch?v=aL9MuiNkgfU&feature=youtu.be"
    },
    {
      imageUrl:
        "https://henrico-eda-site-assets.s3.amazonaws.com/upcoming-events/webinarthumbnail2.jpg",
      title:
        "Henrico Internet Infrastructure Webinar Series: Building a Sustainable Future",
      webinarUrl:
        "https://www.henrico.com/news/press-releases-and-announcements/2020-12-16/infrastructure-webinar-series"
    },       
    {
      imageUrl:
        "https://henrico-eda-site-assets.s3.amazonaws.com/upcoming-events/webinarthumbnail1.jpg",
      title: "Henrico, Virginia's Thriving Data Center Market",
      webinarUrl: "https://www.youtube.com/watch?v=ASRU2OJYVrE&feature=emb_logo"
    }
  ];

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div>
      <NewsHero />
      <Container>
        <NewsNav />
        <PageTitle title={pageTitle} />
        <WebinarsArchivePost webinars={webinars} loading={loading} />
        <Row>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={sortedVideos.length}
            paginate={paginate}
          />
        </Row>
      </Container>
    </div>
  );
};

export default Webinars;
