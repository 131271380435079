import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const remoteData = async ({domain, section, page, field}) => {
  const _action = page ? "section-pages/page" : "section-pages";

  const {data:response} = await axios
    .get(`${process.env.REACT_APP_CMS_URL}/${domain}/${_action}?sectionname=${section}&pagename=${encodeURIComponent(page)}`);
  return response;
};

export const RemoteContent = ({domain, section, page, field, className}) => {
  console.log(page);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const _action = page ? "section-pages/page" : "section-pages";
  const _r = /<(\w+)>/i;
  const generateContent = () => {
    if(className) {
      return content[`${field}`].replace(_r, `<$1 class="${className}">`);
    } else {
      return field ? content[`${field}`] : content;
    }
  }
  const noHtmlTags = () => {
    return content[`${field}`].match(_r) === null;
  };


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_CMS_URL}/${domain}/${_action}?sectionname=${section}&pagename=${encodeURIComponent(page)}`)
      .then(res => {
        page ? setContent(res.data[0]) : setContent(res.data);
        setLoading(false);
      })
  }, []);

  return (
    <>
    { loading === false &&
      <span className={(className && noHtmlTags()) ? className : ""} dangerouslySetInnerHTML={{
        __html: generateContent()
      }} />
    }
    </>
  );
};
