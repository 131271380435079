import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import FindAPropertyHero from "../FindAProperty/FindAPropertyHero";
import FindAPropertyNav from "../shared/Navs/FindAPropertyNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import DOMPurify from 'dompurify';
import "./SingleFeaturedProperties.scss";
import SubscribeNewsletterFeaturedProperties from "../shared/Forms/SubscribeNewsletterFeaturedProperties";
import { Map } from '../shared/Map';
import { remoteData } from '../shared/Cms';
import _ from 'lodash';

const pageTitle = 'Featured Properties';

const FeaturedProperties = (props) => {
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState([]);

    useEffect(() => {
      remoteData({domain: 'henrico', section: 'featured-properties'})
        .then(data => {
        setProperty(_.find(data, (p) => { return p.slug === props.match.params.featuredPropertyTitle }));
        setLoading(false);
      });
    }, []);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://cse.google.com/cse.js?cx=012338158314937675759:ont6r72uk0k";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);



    const handleRedirect = (e) => {
        let newTab = window.open();
        newTab.opener = null;
        newTab.location = `https://www.google.com/maps/search/?api=1&query=${parseFloat(property.maplat)},${parseFloat(property.maplng)}`;
    }

    DOMPurify.addHook('afterSanitizeAttributes', function(node) {
        if ('target' in node) {
            node.setAttribute('target','_blank');
            node.setAttribute('rel', 'noopener noreferrer');
        }
        if (!node.hasAttribute('target')
            && (node.hasAttribute('xlink:href')
                || node.hasAttribute('href'))) {
            node.setAttribute('xlink:show', 'new');
        }
    });

    const renderMap = () => {
      return <Map
        isMarkerShown
        lat={parseFloat(property.maplat)}
        lng={parseFloat(property.maplng)}
        onClick={handleRedirect}
        height={20}
        />;
    }

    if (loading) {
        return <h2>Loading...</h2>;
    }

    return (
        <div>
            <FindAPropertyHero />
            <Container>
                <FindAPropertyNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col xs={12} sm={12} md={8}>
                        <Row className="title-row">
                            <Col>
                                <p className="featured-property-title paragraph-header--blue">{property.property[0].title}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="">
                                <img className="featured-image" src={property.imageurl} alt={property.property[0].title} />
                            </Col>
                        </Row>
                        <Row className="single-copy-row">
                            <Col xs={12}>
                                <Row>
                                    <Col>
                                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(property.description) }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                        {property.resources &&
                            <Row className="property-resources">
                                <Col>
                                    <h2 className="paragraph-header--blue">RESOURCES</h2>
                                    <p dangerouslySetInnerHTML={{ __html: property.resources }} />
                                </Col>
                            </Row>
                        }
                        <Row className="property-section-google-map">
                            <Col>
                                <p className="paragraph-header--blue property-section-title">MAP IT</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {renderMap()}
                            </Col>
                        </Row>
                        <hr />
                        <Row className="property-section-contact-us">
                            <Col>
                                <p className="paragraph-header--blue property-section-title">CONTACT US</p>
                                <p>4300 E. Parham Road<br />Henrico, VA 23228<br />Phone: <a href="tel:804-501-7654">(804) 501-7654</a></p>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="property-section-property-now">
                            <Col>
                                <p className="paragraph-header--blue property-section-title">PROPERTY NOW</p>
                                <p>Be one of the first to receive news on featured property and real estate in Henrico.</p>
                                <SubscribeNewsletterFeaturedProperties />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FeaturedProperties;
