import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./SecondaryNav.scss";

const WhyHenricoNav = (props) => {
  return (
    <div>
      <Navbar expand="xs" className="secondary-navabar">
        <NavLink
          activeClassName="secondary-nav-link-active"
          className="secondary-nav-link"
          to="/why-henrico/fast-facts"
        >
          Why Henrico
        </NavLink>
        <span className="secondary-nav-separator">•</span>
        <NavLink
          activeClassName="secondary-nav-link-active"
          className="secondary-nav-link"
          to="/why-henrico/swam-certification"
        >
          SWaM Certification
        </NavLink>
        <span className="secondary-nav-separator">•</span>
        <NavLink
          activeClassName="secondary-nav-link-active"
          className="secondary-nav-link"
          to="/why-henrico/strategic-plan"
        >
          Strategic Plan
        </NavLink>
        <span className="secondary-nav-separator">•</span>
        <NavLink
          activeClassName="secondary-nav-link-active"
          className="secondary-nav-link"
          to="/why-henrico/annual-report"
        >
          Annual Report
        </NavLink>        
        <span className="secondary-nav-separator">•</span>
        <NavLink
          activeClassName="secondary-nav-link-active"
          className="secondary-nav-link"
          to="/why-henrico/esg"
        >
          ESG
        </NavLink>
      </Navbar>
    </div>
  );
};

export default WhyHenricoNav;
