import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Container } from "react-bootstrap";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import PageTitle from '../shared/PageTitle/PageTitle';
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from '../shared/Navs/NewsNav';
import PropertyNowArchiveContent from "./PropertyNowArchiveContent";
import SubscribeNewsletterPropNow from '../shared/Forms/SubscribeNewsletterPropNow';

import './PropertyNowArchive.scss';

const pageTitle = 'Property NOW';

const PropertyNowAllArticles = () => {

  return (
    <div>
      <NewsHero />
        <Container>
          <NewsNav />
            <PageTitle title={pageTitle} />
              {/* <p className="paragraph-header--blue">All Articles</p> */}
              <PropertyNowArchiveContent/>
              <br/><br/><br/>
              <SubscribeNewsletterPropNow/>
              <NewsInsights />
        </Container>
    </div>
  );
};

export default PropertyNowAllArticles;
