import WhiteOakHero from "./WhiteOakHero";
import React, { useState, useEffect } from "react";
import {Col, Container, Row} from "react-bootstrap";
import ResourcesAndDownloads from "../shared/FindAProperty/ResourcesAndDownloads";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import location3 from './images/HenricoImage.png'
import location2 from './images/location2.png'
import Quote from "../shared/Quote/Quote";
import henrico from  './images/henrico.png'
import richmond from './images/richmond.png'
import virginia from './images/virginia.png'
import './WhiteOakLocation.scss'
import WhiteOakNav from "../shared/Navs/WhiteOakNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import Lightbox from 'react-image-lightbox';
import eastCoast from "./images/east-coast-map.jpg";
import location from "./images/Location-Henrico-Map.jpg";
import { remoteData } from '../shared/Cms';

const pageTitle = "Location";

const quoteContent = "Our U.S. expansion project is an exciting opportunity for future growth. We explored U.S. locations and selected Virginia and Henrico among several that were considered. Not only is this location in close proximity to most of our U.S. customers – it provides us with a foundation for future strategic growth and expansion."
const quoteSignature = "Lionel Genix, General Manager, Air Liquide Medical Systems Polykon Manufacturing is a joint venture between two subsidiaries of Air Liquide – SEPPIC Inc. and Shülke Inc."



const WhiteOakLocation = () => {
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    remoteData({domain: 'henrico', section: 'white-oak-tech-park', page: 'Location'})
      .then(data => {
        setPageData(data[0]);
        setLoading(false);
      });
  }, []);

    const image1 = [
        `${location}`
    ];
    const image2 = [
        `${eastCoast}`,
    ]

    const [photoIndex1] = useState(0);
    const [photoIndex2] = useState(0);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    if(loading) {
      return <>Loading...</>;
    }

    return (
      <div>
          <WhiteOakHero />
          <Container>
              <WhiteOakNav/>
              <PageTitle title={pageTitle} />
              <Row>
                  <Col xs={12} sm={12} md={7}>
                      <p dangerouslySetInnerHTML={{ __html: pageData.s1 }} />
                      <p dangerouslySetInnerHTML={{ __html: pageData.s2 }} />
                  </Col>
                    <Col className="location-image-enlarge-column" xs={12} sm={12} md={5}>
                        {isOpen1 && (
                            <Lightbox
                                mainSrc={image1[photoIndex1]}
                                // nextSrc={image1[(photoIndex1 + 1) % image1.length]}
                                // prevSrc={image1[(photoIndex1 + image1.length - 1) % image1.length]}
                                onCloseRequest={() => setIsOpen1(false)}
                            />
                        )}
                        {isOpen2 && (
                            <Lightbox
                                mainSrc={image2[photoIndex2]}
                                onCloseRequest={() => setIsOpen2(false)}
                            />
                        )}
                        <p>
                            <img
                                alt=""
                                src={location3}
                                onClick={() => setIsOpen1(true)}>
                            </img>
                        </p>
                        <p>
                            <img
                                alt=""
                                src={location2}
                                onClick={() => setIsOpen2(true)}>
                            </img>
                        </p>
                  </Col>
              </Row>
              <Row>
                  <Col className="copy-quotes quotes-container-location">
                      <Quote data={pageData.s6} copy={quoteContent} signature={quoteSignature} />
                  </Col>
              </Row>

              <Row>
                  <Col>
                    <Row className="location-image-row">
                        <Col xs={12} lg={8}>
                          <p dangerouslySetInnerHTML={{ __html: pageData.s3 }} />
                        </Col>
                        <Col className="location-image-column" xs={12} lg={4}>
                            <img alt="" className="location-image"src={henrico} />
                        </Col>
                    </Row>
                    <Row className="location-image-row">
                        <Col xs={12} lg={8}>
                          <p dangerouslySetInnerHTML={{ __html: pageData.s4 }} />
                        </Col>
                        <Col className="location-image-column" xs={12} lg={4}>
                            <img alt="" className="location-image"src={richmond} />
                        </Col>
                    </Row>
                    <Row className="location-image-row">
                            <Col xs={12} lg={8}>
                            <p dangerouslySetInnerHTML={{ __html: pageData.s5 }} />
                        </Col>
                        <Col className="location-image-column" xs={12} lg={4}>
                            <img alt="" className="location-image"src={virginia} />
                        </Col>
                    </Row>
                  </Col>
              </Row>
            </Container>
            <ResourcesAndDownloads />
            <Container>
              <NewsInsights/>
          </Container>
      </div>
    );
}

export default WhiteOakLocation;
