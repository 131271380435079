import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "./WhiteOakQuickFacts.scss";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import WhiteOakHero from './WhiteOakHero';

import WhiteOakNav from "../shared/Navs/WhiteOakNav"
// import availableProperty from './images/Bitmap.png'
import ResourcesAndDownloads from "../shared/FindAProperty/ResourcesAndDownloads";
import PageTitle from "../shared/PageTitle/PageTitle";
import TheSiteMap from "./the-site/TheSiteMap";
import { remoteData } from '../shared/Cms';

const paragraphStyles = {
    fontWeight: 600,
};

const pageTitle = "The Site";

const WhiteOakQuickSite = () => {
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    remoteData({domain: 'henrico', section: 'white-oak-tech-park', page: 'The Site'})
      .then(data => {
        setPageData(data[0]);
        setLoading(false);
      });
  }, []);

  if(loading) {
    return <>Loading...</>;
  }

    return (
        <div>
            <WhiteOakHero />
            <Container>
                <WhiteOakNav/>
                <PageTitle title={pageData.title} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                      <p dangerouslySetInnerHTML={{ __html: pageData.s1 }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className="text-center">Available Properties</h3>
                        <p className="text-center">(Click map to reveal site data)</p>
                    </Col>
                </Row>
                <TheSiteMap pageData={pageData} />
            </Container>
            <ResourcesAndDownloads />
            <Container>
                <NewsInsights />
            </Container>
        </div>
);
};

export default WhiteOakQuickSite;
