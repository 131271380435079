import { Col, Container, Row} from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from '../TargetSectors/images/advanced-manufacturing-logos.png';
import LogosImage2 from '../TargetSectors/images/advanced-manufacturing-logos-2.png';
import "../TargetSectors/TargetSectors.scss";
import Quote from "../shared/Quote/Quote.js";
import DataCard from '../shared/DataCard/DataCard';
import FindAPropertyHero from './FindAPropertyHero';
import FindAPropertyNav from '../shared/Navs/FindAPropertyNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import './InnsbrookTechnologyZone.scss';

const pageTitle = 'Innsbrook Technology Zone';

const quoteData = {
    copy: "“Our U.S. expansion project is an exciting opportunity for future growth. We explored U.S. locations and selected Virginia and Henrico among several that were considered. Not only is this location in close proximity to most of our U.S. customers – it provides us with a foundation for future strategic growth and expansion.”",
    signature: "Lionel Genix, President and CEO, Seppic, Inc., and Manager, Polykon Manufacturing"
}

const cards = [
    {
        titleLine1: "Since",
        titleLine2: "1611",
        text: 'Henrico has been a center for commerce and trade.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "Reach Customers Quickly",
        titleLine2: "",
        text: 'More than 55% of the U.S. population and major manufacturing sites are located within 750 miles of Henrico.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "140M Pounds of Cargo",
        titleLine2: "",
        text: 'are handled annually at Richmond International Airport.',
        categoryColor: '66A7CF',
    }
]

const InnsbrookTechnologyZone = () => {
    return (
        <div>
            <FindAPropertyHero />
            <Container>
                <FindAPropertyNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>Just when businesses didn’t think <a href="https://innsbrook.com/" target="_blank" rel="noreferrer noopener">Innsbrook</a> could get any better, in 2022, Henrico introduced the <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/0348-HEN22006_Flyer_RGB_v3.pdf" target="_blank" rel="noreferrer noopener">Innsbrook Technology Zone</a> to accelerate the community’s ascent as a premier live, work, and play destination.</p>
                    <p>Executives and employees alike were already enjoying a vibrant community. In 2023, Stacker recognized the 630-acre urban center as a Top 20 Best Place to Live in the U.S. More than 500 companies – including Fortune 500 companies Dominion Energy and Markel, as well as Fortune 1000 business management consultancy ASGN – have a business presence here. Every one of those businesses contributes to an impressive $3 billion-plus gross annual product, and more than 28,000 employees are enjoying the bustle of work, delicately balanced with an intimate sense of place.</p>
                    <p>But in Henrico, we know that world-class locations are built by design. So in order to continue to advance business success, attract and retain <a href="https://www.henrico.com/why-henrico/skilled-workforce" target="_blank" rel="noreferrer noopener">top talent</a>, and fuel Innsbrook’s longevity, we created the Innsbrook Technology Zone.</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/office-space/Rectangle+9.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Innsbrook Technology Zone</h3></p>
                    <p>Henrico focuses on the fine details that matter to your bottom line. It’s why we offer a wide range of economic development and tax exemptions to eligible businesses – so you can put less money into operations and more money into growing your company. Then you build, expand, or relocate in Innsbrook, Virginia, your business may be able to take advantage of special incentives, including:<br></br><br></br><ul><li>Building and permit fee waivers</li><li>Flex spaces to accommodate the modern hybrid workplace</li><li>Direct collaboration with the Henrico EDA for custom-tailored financial solutions specific to individual business needs</li></ul>For more detailed information, review the official approved <a href="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/office-space/Innsbrook+Technology+Zone+Ordinance+(1).PDF">Innsbrook Technology Zone Ordinance</a>. Think your business qualifies? Fill out our <a href="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/office-space/Innsbrook+Technology+Zone+Fee+Waiver+Application.docx">Innsbrook Technology Zone Fee Waiver Application</a> and email it to <a href="mailto:ashley@henrico.com">Ashley@henrico.com</a> today.</p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/office-space/Rectangle+2.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Low Virginia Business Tax</h3></p>
                    <p>When you’re searching for Virginia business opportunities, come to Henrico. Here, we’ve cultivated the type of stable economic environment and pro-business climate that businesses crave. <a href="https://www.henrico.com/about-us/contact-us" target="_blank">Reach out</a> to us when you want to find the perfect location in Innsbrook. Gain the competitive edge with low local and Virginia business taxes and benefits that include:<br></br><br></br><ul><li>6% corporate income tax rate – one of the lowest in the U.S.</li><li>Local real estate tax that is 85¢ per $100 of assessed value</li><li>AAA bond rating from the big three (S&P, Moody’s and Fitch Group)</li><li>$23.95 average per-square-foot, per-year office lease rate</li></ul></p>
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/industrial-and-manufacturing/iStock-973715182.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Insurance and Tech Companies in Virginia</h3></p>
                    <p>Join the leading insurance and tech companies in Virginia that call Innsbrook home. In 2022:<br></br><br></br><ul><li>Bermuda-headquartered specialty insurer Hamilton Select announced the establishment of its U.S. headquarters in the mixed-use community.</li><li>EAB, the leading provider of education research, technology, and marketing and enrollment solutions, announced it was investing at least $6 million to expand in Innsbrook.</li><li>BHE GT&S, a subsidiary of Berkshire Hathaway Energy, revealed it was moving its headquarters to Innsbrook.</li></ul><br/>In 2023:<br/><br/><ul><li>Specialty insurer Richmond National reported it was nearly doubling its footprint in Innsbrook just two years after its founding.</li><li>Berkley Insurance Company, a subsidiary of W. R. Berkley Corporation, announced it would invest $6.1 million to grow its current home office in Henrico’s premier mixed-use community.</li><li>Dominion Energy continued its work on a $100 million phased, sustainable renovation to its Innsbrook Technical Center.</li></ul></p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/office-space/Rectangle+4.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Superior Quality of Life</h3></p>
                    <p>Grow in Innsbrook and <a href="https://www.henrico.com/why-henrico/qualityoflife" target="_blank">Live Your Best</a>, all at a cost of living that’s 2.5% below the national average. The picturesque community offers:<br/><br/><ul><li>Three stunning lakes and five miles of trails for those who long for green space and revel in the great outdoors</li><li>A dynamic mixed-use development at Innsbrook’s <a href="https://www.highwoodsnorthend.com/" target="_blank">North End</a> on the way, as well as single and multifamily housing available for lease at Metropolis and Innslake Place – two modern, amenity-rich developments</li><li>Sunset Yoga for those who want to unwind, corporate events like the annual Anthem Corporate Run for those who want to rev it up, and a variety of restaurants like Hurley’s Tavern for those who want to cut loose</li></ul><br/>Want to experience even more? When you live in Innsbrook, Virginia’s vibrant capital city of Richmond is only a short drive away. In fact, nearly everything you could want is a short drive away, thanks to Innsbrook’s location at the union of I-64 and I-295, and to Henrico’s <a href="https://www.prnewswire.com/news-releases/henrico-virginia-commute-time-among-best-in-nation-300980122.html" target="_blank">22-minute average commute</a>, one of the nation’s best.</p>
                    </Col>                                       
                </Row>                           
                {/* <Quote copy={quoteData.copy} signature={quoteData.signature} /> */}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default InnsbrookTechnologyZone;