import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from 'axios';
import FindAPropertyHero from "../FindAProperty/FindAPropertyHero";
import FindAPropertyNav from "../shared/Navs/FindAPropertyNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SubscribeNewsletterFeaturedProperties from "../shared/Forms/SubscribeNewsletterFeaturedProperties";
// import Pagination from "../shared/Pagination/Pagination";
import FeaturedPropertiesPost from "./FeaturedPropertiesPost";
import { RemoteContent, remoteData } from '../shared/Cms';

const FeaturedProperties = (data) => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'find-a-property',
    page: 'Featured Properties'
  };
  const [sortedFeaturedProperties, setSortedFeaturedProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    remoteData({domain, section: 'featured-properties'})
      .then(data => {
        setSortedFeaturedProperties(data);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <FindAPropertyHero />
        <Container>
          <FindAPropertyNav />
            <PageTitle title={
              <RemoteContent
                domain={domain}
                section={section}
                page={page}
                field="title"
              />
            } />
                <Row>
                  <Col>
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="header1"
                    />
                        </Col>
                          </Row>
                            <FeaturedPropertiesPost posts={sortedFeaturedProperties} loading={loading} />
                              <SubscribeNewsletterFeaturedProperties />
                                <Row>
                                  <NewsInsights />
                                    </Row>
                                      </Container>
                                        </div>
  )
}

export default FeaturedProperties;
