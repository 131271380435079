import React from "react";
import { Container } from "react-bootstrap";
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from "../shared/Navs/NewsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import BloggerEmbed from "./BloggerEmbed";
import "./HenricoTIMESArchive.scss";

const pageTitle = 'HenricoTIMES';
const HenricoTIMES = () => {
    return (
        <div>
            <NewsHero />
            <Container>
                <NewsNav />
                {/* <PageTitle title={pageTitle} /> */}
                <div class="spacer"></div>
                <BloggerEmbed />
            </Container>
        </div>
    )
}

export default HenricoTIMES;