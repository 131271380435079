import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NewsInsights from "../../components/shared/NewsInsights/NewsInsights";
import ExistingBusinessHero  from "../ExistingBusiness/ExistingBusinessHero";
import ExistingBusinessNav from '../shared/Navs/ExistingBusinessNav';
import './AdditionalResources.scss';
import whereToPostJobsForFree from "./pdfs/Where+to+post+jobs+for+free_ONLINE_2019.pdf";
import cteResources from "./pdfs/Career+and+Technical+Education_ONLINE_2019.pdf";
import whereToFindFreeMarketResearch from "./pdfs/Market+Research_ONLINE_2019.pdf";
import potentialTaxCreditsIncentivesSummary from "./pdfs/Tax+Credits_ONLINE_2019.pdf";
import PageTitle from '../shared/PageTitle/PageTitle';
import { RemoteContent } from '../shared/Cms';

const AdditionalResources = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'existing-business',
    page: 'Additional Resources'
  };
    return (
        <div>
            <ExistingBusinessHero />
            <Container>
                <ExistingBusinessNav />
                  <PageTitle title={
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="title"
                    />
                  } />
                <Col >
                    <Row>
                        <Col>
                          <RemoteContent
                            domain={domain}
                            section={section}
                            page={page}
                            field="Section1"
                          />
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                        <RemoteContent
                          domain={domain}
                          section={section}
                          page={page}
                          field="Section2"
                        />
                      </Col>
                    </Row>
                </Col>
                <NewsInsights />
            </Container>
        </div>
    );
};

export default AdditionalResources;
