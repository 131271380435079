import React from "react";
import { Row, Col } from "react-bootstrap";

import "./SubscribeNewsletterHome.scss";

const SubscribeNewsletterHome = () => {
    return (
            <Row className="mt-0">
                <Col xs={12} sm={6}>
                    <div className="contact-box-2019">
                        <p className="contact-henrico-text">Contact the Henrico EDA</p>
                        <p className="sign-up-title">Sign up for our e-newsletters</p>
                        <div className="contact-form-2019">
                            <form action="https://cl.exct.net/subscribe.aspx" method="post" id="connect-form-2019">
                                <Row>
                                    <Col xs={12} sm={12} md={6}>
                                        <div className="question">
                                            <input type="checkbox" id="hen_now" name="lid" value="498" />
                                            <label htmlFor="hen_now" className="pl-2">Henrico Now</label>
                                        </div>
                                        <div className="question">
                                            <input type="checkbox" id="prop_now" name="lid" value="30019" />
                                            <label htmlFor="prop_now" className="pl-2">Property Now</label>
                                        </div>
                                        <div className="email">
                                            <input type="text" id="subscribe-email" name="Email Address" className="input-email" placeholder="Your Email Address" />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                        <div className="form-button">
                                        <input className="button-primary submit-button subscribe-newsletter-home" type="submit" value="Submit" />
                                        </div>
                                        <input type="hidden" name="thx" value={`${process.env.REACT_APP_HOME_URL}/subscribe-newsletter-success`} />
                                        <input type="hidden" name="err" value={`${process.env.REACT_APP_HOME_URL}/subscribe-newsletter-error`} />
                                        <input type="hidden" name="MID" value="1053940" />
                                        <input type="hidden" name="SubAction" value="sub_add_update" />
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <div className="info-henrico">
                        <p className="contact-henrico-text">Explore Henrico</p>
                        <div class="ExploreLogoWrapper">
                            <a href="https://henricosea.com/" target="_blank;">
                                <img class="ExploreLogo1" src="https://henrico-eda-site-assets.s3.amazonaws.com/home/HSEA_Tennis_Full-Color+1.png"/>
                            </a>
                            <a href="https://henrico.us" target="_blank;">
                                <img class="ExploreLogo2" src="https://henrico-eda-site-assets.s3.amazonaws.com/home/Portal-login-logo+1.png"/>
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
    )
}

export default SubscribeNewsletterHome;

