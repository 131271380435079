import React, { useEffect } from "react";
import Logo from "./images/HEDA_Logo.png";
import { Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Dropdown from '../Navs/Dropdown';
import "./Header.scss";

const Header = () => {
  let featureText = (
    <span>
      <span style={{ color: "#6EBC4C" }}>&nbsp;</span>
      {/* <span style={{ color: "#6EBC4C" }}>FEATURE: </span>
      <span style={{ color: "#FFFFFF" }}>WHITE OAK TECHNOLOGY PARK</span> */}
    </span>
  );

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://cse.google.com/cse.js?cx=012338158314937675759:ont6r72uk0k";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  var removePlaceholder = function (div) {
    var inputField = div.querySelector("input.gsc-input");

    // Change the placeholder
    inputField.placeholder = "Search Henrico";

    // Remove the background
    inputField.style.background = "none";

    // The background will get re-attached on blur, so add an event that will also remove it on blur
    // Another way to do this would be to de-attach the element from the DOM and then re-attach again, hence wiping the listeners
    inputField.addEventListener("blur", function () {
      inputField.style.background = "none";
    });
  };

  const refreshPage = (_href) => {
    window.location = _href;
    window.location.reload();
  };

  return (
    <div>
      <Navbar expand="xl" className="navbar-primary">
        <a href="/">
          <Image className="header-brand-logo" src={Logo} />
        </a>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end navbar-toggleable-lg"
        >
          <Nav className="ml-auto">
            <div className="row-1">
              <Dropdown
                title="WHY HENRICO?"
                id="nav-dropdown-why"
              >
                <NavDropdown.Item
                  eventKey="1.1"
                  onClick={(event) => refreshPage("/why-henrico/pro-business")}
                  href="/why-henrico/pro-business"
                >
                  Pro-Business
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.2"
                  onClick={(event) => refreshPage("/why-henrico/market-access")}
                  href="/why-henrico/market-access"
                >
                  Market Access
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.3"
                  onClick={(event) =>
                    refreshPage("/why-henrico/skilled-workforce")
                  }
                  href="/why-henrico/skilled-workforce"
                >
                  Skilled Workforce
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.4"
                  onClick={(event) =>
                    refreshPage("/why-henrico/competitive-operating-costs")
                  }
                  href="/why-henrico/competitive-operating-costs"
                >
                  Competitive Operating Costs
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.5"
                  onClick={(event) =>
                    refreshPage("/why-henrico/infrastructure")
                  }
                  href="/why-henrico/infrastructure"
                >
                  Infrastructure
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.6"
                  onClick={(event) =>
                    refreshPage("/why-henrico/qualityoflife")
                  }
                  href="/why-henrico/qualityoflife"
                >
                  Quality of Life
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.7"
                  onClick={(event) => refreshPage("/why-henrico/fast-facts")}
                  href="/why-henrico/fast-facts"
                >
                  Fast Facts
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.8"
                  href="/why-henrico/swam-certification"
                >
                  SWaM Certification
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.9"
                  href="/why-henrico/strategic-plan"
                >
                  Strategic Plan
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="1.10"
                  href="/why-henrico/annual-report"
                >
                  Annual Report
                </NavDropdown.Item>                
                <NavDropdown.Item
                  eventKey="1.11"
                  href="/why-henrico/esg"
                >
                  ESG
                </NavDropdown.Item>
              </Dropdown>
              <Dropdown
                title="SITE SELECTORS"
                id="nav-dropdown-site"
              >
                <NavDropdown.Item
                  eventKey="2.1"
                  href="/site-selectors/available-property-search"
                >
                  Henrico Property Search
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="2.2" href="/site-selectors/taxes">
                  Taxes
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="2.3"
                  href="/site-selectors/utilities"
                >
                  Utilities
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="2.4"
                  href="/site-selectors/incentives"
                >
                  Incentives
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="2.5"
                  href="/site-selectors/demographic-data"
                >
                  Demographic Data
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="2.6"
                  href="/site-selectors/transportation"
                >
                  Transportation
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="2.7"
                  href="/site-selectors/cost-of-living"
                >
                  Cost of Living
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="2.8"
                  href="/site-selectors/data-downloads"
                >
                  Downloads
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="2.9"
                  href="/site-selectors/data-connectivity"
                >
                  Data Connectivity
                </NavDropdown.Item>
              </Dropdown>
              <Dropdown
                title="TARGET SECTORS"
                id="nav-dropdown-target"
              >
                <NavDropdown.Item
                  eventKey="3.1"
                  href="/target-sectors/corporate-and-regional-headquarters"
                >
                  Corporate and Regional Headquarters
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3.2"
                  href="/target-sectors/advanced-manufacturing"
                >
                  Advanced Manufacturing
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3.3"
                  href="/target-sectors/finance-and-insurance"
                >
                  Finance &amp; Insurance
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3.4"
                  href="/target-sectors/health-and-life-sciences"
                >
                  Health &amp; Life Sciences
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3.5"
                  href="/target-sectors/supply-chain-management"
                >
                  Supply Chain Management
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3.6"
                  href="/target-sectors/professional-services"
                >
                  Professional Services
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3.7"
                  href="/target-sectors/data-centers"
                >
                  Data Centers
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3.8"
                  href="/target-sectors/international-companies"
                >
                  International
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3.9"
                  href="/target-sectors/semiconductors"
                >
                  Semiconductors
                </NavDropdown.Item>
              </Dropdown>
              <Dropdown
                title="EXISTING BUSINESSES"
                id="nav-dropdown-business"
              >
                <NavDropdown.Item
                  eventKey="4.1"
                  href="/existing-business/business-retention-expansion"
                >
                  Business Retention/Expansion
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.2"
                  href="/existing-business/major-employers-legacy-companies"
                >
                  Major Employers/Legacy Companies
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.3"
                  href="/existing-business/start-a-business"
                >
                  Start A Business
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.4"
                  href="/existing-business/grow-your-business"
                >
                  Grow Your Business
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.5"
                  href="/existing-business/workforce-assistance"
                >
                  Workforce Assistance
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.6"
                  href="/existing-business/exporting-assistance"
                >
                  Exporting Assistance
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.7"
                  href="/existing-business/financial-assistance"
                >
                  Financial Assistance
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.8"
                  href="/existing-business/additional-resources"
                >
                  Additional Resources
                </NavDropdown.Item>
              </Dropdown>
              <Dropdown
                title="FIND A PROPERTY"
                id="nav-dropdown-property"
              >
                <NavDropdown.Item
                  eventKey="5.1"
                  href="/find-a-property/available-property-search"
                >
                  Henrico Property Search
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                  eventKey="5.2"
                  href="/find-a-property/featured-properties"
                >
                  Featured Properties
                </NavDropdown.Item> */}
                <NavDropdown.Item eventKey="5.3" href="/white-oak/quick-facts">
                  White Oak Tech Park
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="5.4" href="/find-a-property/greencity">
                  GreenCity
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="5.5" href="/richmond-nap">
                  Richmond NAP
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="5.6" href="/find-a-property/innsbrook-technology-zone">
                  Innsbrook Technology Zone
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="5.7" href="/find-a-property/industrial-properties">
                  Industrial Properties in Henrico
                </NavDropdown.Item>
              </Dropdown>
              <Dropdown
                title="NEWS"
                id="nav-dropdown-news"
              >
                <NavDropdown.Item eventKey="6.1" href="/news/henrico-now">
                  Henrico NOW
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="6.2" href="/news/property-now">
                  Property NOW
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="6.3"
                  href="/news/press-releases-and-announcements"
                >
                  Press Releases & Announcements{" "}
                </NavDropdown.Item>
                {/*   <NavDropdown.Item eventKey="6.3" href="/news/upcoming-events">Upcoming Events</NavDropdown.Item> */}
                {/* <NavDropdown.Item eventKey="6.4" href="/news/media-coverage">
                  Media Coverage
                </NavDropdown.Item> */}
                <NavDropdown.Item eventKey="6.5" href="/news/videos">
                  Videos
                </NavDropdown.Item>
                {/* <NavDropdown.Item eventKey="6.6" href="/news/webinars">
                  Webinars
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item eventKey="6.7" href="/covid19">
                  Covid-19
                </NavDropdown.Item> */}
                <NavDropdown.Item eventKey="6.8" href="/news/henricotimes">
                  HenricoTIMES
                </NavDropdown.Item>
              </Dropdown>
              <Dropdown
                title="ABOUT US"
                id="nav-dropdown-about"
              >
                <NavDropdown.Item eventKey="7.1" href="/about-us/our-team">
                  Our Team
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="7.2" href="/about-us/contact-us">
                  Contact Us
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="7.3" href="/about-us/find-us">
                  Find Us
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="7.4" href="/about-us/careers">
                  Careers
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="7.5" href="/about-us/meeting-minutes">
                  Minutes
                </NavDropdown.Item>
              </Dropdown>
            </div>
            <div className="row-2">
              <Nav.Link href="/white-oak/quick-facts" id="nav-dropdown-feature">
                {featureText}
              </Nav.Link>
              <div className="gsc-searchbox-container-header">
                <div className="gcse-searchbox-only"></div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
