import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./Quote.scss";

const Quote = (props) => {
  const [quoteText, setQuoteText] = useState(null);
  const [quoteAuthor, setQuoteAuthor] = useState(null);
  const [loading, setLoading] = useState(true);
  const _r = /(<p>.+?:?<\/p>)?(.+)/g;

  useEffect(() => {
    if(props.data) {
      setQuoteText(props.data.match(_r)[0]);
      setQuoteAuthor(props.data.match(_r)[1]);
    }
    setLoading(false);
  }, []);

  if(loading) {
    return <>Loading...</>;
  }

        return (
            <Container className="quote-container">
                <Row>
                    <Col>
                      <p className="quote-copy" dangerouslySetInnerHTML={{ __html: quoteText || props.copy }} />
                    </Col>
                </Row>
                <Row>
                    <Col className="quote-signature">
                      <div className="quote-signature-caption" dangerouslySetInnerHTML={{ __html: quoteAuthor || props.signature }} />
                    </Col>
                </Row>
                <Row>
                    <Col className="quote-signatureTitle">
                      <div className="quote-signatureTitle-caption" dangerouslySetInnerHTML={{ __html: quoteAuthor || props.signatureTitle }} />
                    </Col>
                </Row>                
            </Container>
        );
}

export default Quote;
