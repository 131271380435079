import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import "./Footer.scss";

const Footer = () => {
    const email = "Henrico Economic Development Authority";
    const streetAddress = "4300 E. Parham Rd.";
    const cityStateZip = "Henrico, VA 23228 USA";
    const phoneNumber = "804.501.7654";
    const date = new Date();
    const year = date.getFullYear();
    const copyright = `© ${year} Henrico Economic Development Authority`;

    return(
        <div className="footer">
            <Container className="footer-text">
                <Row className="text-center footer-address">
                    <Col className="hide-on-lg">
                        <div>{email}</div>
                        <div>{streetAddress}<span className="secondary-nav-separator">•</span>{cityStateZip}<span className="secondary-nav-separator">•</span><br/><a className="footer-tel" href={`tel:1${phoneNumber}`}>{phoneNumber}</a></div>
                    </Col>    
                    <Col className="hide-on-mobile">
                        <div>{email}
                        <span className="secondary-nav-separator">•</span>{streetAddress}<span className="secondary-nav-separator">•</span>{cityStateZip}<span className="secondary-nav-separator">•</span><a className="footer-tel" href={`tel:1${phoneNumber}`}>{phoneNumber}</a></div>
                    </Col>
                </Row>
                <Row className="text-center mt-0 copyright">
                    <Col>
                        <span>{copyright} <span className="secondary-nav-separator">•</span></span>
                        <span><a className="footer-privacy" href="/about-us/privacy" >Privacy Notice</a></span>
                        <span> <span className="secondary-nav-separator">•</span></span>
                        <span><a className="footer-privacy" href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/155949-FOIA%20Officer%20form%20-%20EDA.pdf" target="_blank" rel="noopener noreferrer">FOIA Policy</a></span>
                    </Col>
                </Row>    
            </Container>
        </div>
    )
};

export default Footer;