import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import PageTitle from '../shared/PageTitle/PageTitle';
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from '../shared/Navs/NewsNav';
import SubscribeNewsletterHenricoNow from '../shared/Forms/SubscribeNewsletterHenricoNow';
import { sortArticles } from '../shared/Utilities/Sort';
import './HenricoNowFeaturedArticles.scss';

const pageTitle = 'Henrico NOW';

const HenricoNowFeaturedArticles = () => {
    const [featuredArticle, setFeaturedArticle] = useState();
    const [listOfArticles, setListOfArticles] = useState();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const hasNoArticles = () => {
      return (featuredArticle === undefined || featuredArticle.length === 0)
        && (listOfArticles === undefined || listOfArticles.length === 0);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_NEWS_URL}/featured-henrico-now-articles`)
            .then(res => {
                setLoading(true);
                const articles = sortArticles(res.data.featuredHenricoNowArticles);

                setFeaturedArticle(articles.slice(0, 1));
                setListOfArticles(articles.slice(1, 7));
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://cse.google.com/cse.js?cx=012338158314937675759:ont6r72uk0k";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const handleClick = (e, slug, articleId) => {
        e.preventDefault();
        const url = `henrico-now/${slug}`;
        history.push(url, {
            articleId
        })
    }

    return (
        <div>
            <NewsHero />
            <Container>
                <NewsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        {loading ? 'Loading...' :
                            <div>
                                {hasNoArticles() ? 'No articles available.' :
                                <>
                                <Row>
                                    {featuredArticle && featuredArticle.map(feature => {
                                        return <Col key={feature._id}>
                                            <Row>
                                                <Col>
                                                    {feature.image_featured_large &&
                                                        <a href={feature.slug} onClick={(e) => handleClick(e, feature.slug, feature._id)}><img className="feature-image" alt={feature.title} src={feature.image_featured_large} /></a>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {feature.slug &&
                                                        <a className="paragraph-header--blue" href="#!" onClick={(e) => handleClick(e, feature.slug, feature._id)}>{feature.title}</a>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="henrico-archive-date">
                                                <Col>
                                                    {feature.slug &&
                                                        <p>{feature.date}</p>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    })}
                                </Row>
                                <Row>
                                    {listOfArticles && listOfArticles.map(article => {
                                        return <Col key={article._id} className="list-articles-col" xs={12} sm={4}>
                                            <Row>
                                                <Col>
                                                    {article.image_small &&
                                                        <a href="#!" onClick={(e) => handleClick(e, article.slug, article._id)}><img className="henrico-now-article-grid-image" alt="" src={article.image_small} /></a>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mt-10">
                                                <Col>
                                                    {article.title &&
                                                        <a className="henrico-archive-title--black" href="#!" onClick={(e) => handleClick(e, article.slug, article._id)}>{article.title}</a>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="henrico-archive-date">
                                                <Col>
                                                    {article.date &&
                                                        <p>{article.date}</p>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    })}
                                </Row>
                                <Row className="henrico-now-see-more-button-row">
                                    <Col>
                                        <a href="/news/henrico-now/articles"><button className="button-primary see-more-btn">SEE MORE FROM HENRICO NOW</button></a>
                                    </Col>
                                </Row>
                                </>
                                }
                            </div>
                        }
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Row>
                            <Col>
                                <Row>
                                    <div className="gsc-searchbox-container-henrico-now">
                                        <div className="gcse-searchbox-only"></div>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="subscribe-row">
                            <Col>
                                <p className="paragraph-header--blue">Subscribe</p>
                                <p>Get the latest news about doing business in Henrico.<br />Sign Up Here!</p>
                            </Col>
                        </Row>
                        <Row className="mt-0">
                            <Col>
                                <SubscribeNewsletterHenricoNow />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {/* TODO: Uncomment this after prod release <MediaCoverageHenricoNow /> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HenricoNowFeaturedArticles;
