import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import axios from 'axios';
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from "../shared/Navs/NewsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import Pagination from "../shared/Pagination/Pagination";
import VideoArchivePost from "./VideoArchivePost";
import "./VideoArchive.scss";

const pageTitle = 'Videos';
const Videos = (data) => {
    const [sortedVideos, setSortedVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(9);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_NEWS_URL}/videos`)
            .then(res => {
                setLoading(true);
                const videos = res.data.videos;
                const sortedVideos = videos.sort(function (a, b) {
                    return (b.date < a.date) ? -1 : ((b.date > a.date) ? 1 : 0);
                });
                setSortedVideos(sortedVideos);
                setLoading(false);
            })
    }, []);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedVideos.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    if (loading) {
        return <h2>Loading...</h2>;
    }

    return (
        <div>
            <NewsHero />
            <Container>
                <NewsNav />
                <PageTitle title={pageTitle} />
                <VideoArchivePost posts={currentPosts} loading={loading} />
                <Row>
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={sortedVideos.length}
                        paginate={paginate}
                    />
                </Row>
            </Container>
        </div>
    )
}

export default Videos;