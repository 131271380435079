import { Container } from "react-bootstrap";
import React from "react";

import NewsInsights from '../shared/NewsInsights/NewsInsights';
import FindAPropertyHero from './FindAPropertyHero';
import FindAPropertyNav from "../shared/Navs/FindAPropertyNav";
import HenricoPropertySearch from "../shared/AvailablePropertySearch/HenricoPropertySearch";

// const paragraphStyles = {
//     fontWeight: 600,
// };

// const pageTitle = 'GIS Property Search';

const FapAvailablePropertySearch = () => {
    return (
        <div>
            <FindAPropertyHero />
            <Container>
                <FindAPropertyNav />
                <HenricoPropertySearch />
                <NewsInsights />
            </Container>
        </div>
);
};

export default FapAvailablePropertySearch;