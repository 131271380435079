import { Col, Container, Row } from "react-bootstrap";
import ESGImage1 from './images/ESG1.jpg';
import ESGImage2 from './images/ESG2.jpg';
import React from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import WhyHenricoHero from "./WhyHenricoHero";
import WhyHenricoNav from "../shared/Navs/WhyHenricoNav";
import PageTitle from "../shared/PageTitle/PageTitle";

const pageTitle = "Environmental, Social, and Corporate Governance (ESG)";

const SwamStatement = () => {
  return (
    <div>
      <WhyHenricoHero />
      <Container>
        <WhyHenricoNav />
        <PageTitle title={pageTitle} />
        <Row>
          <Col lg={12} sm={12} md={12} xl={12}>
            <Row>
              <p>
                Shareholders, investors, employees, and consumers have all spoken – and they want proof that you’re doing your part to protect our planet, improve our communities, and conduct your business with integrity. To that end, Henrico has cultivated an environment that helps businesses execute their ESG strategies. Here, it’s easier to demonstrate industry leadership, drive profitability, and help make the world a better place.
              </p>
            </Row>
        <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src={ESGImage1} alt="Sustainability" width="100%"/>
                    <br/><br/>
                    <p><h3>Sustainability</h3></p>
                    <p>Henrico offers extraordinary access to renewable energy, and we’re relentlessly investing in our clean energy channels. Because of our efforts, business leaders can continue to be environmental stewards in their respective industries.</p>
                    <p>Enroll in the Dominion Energy Green Power program and match all or part of their energy usage with renewable energy. Tap into another clean energy source by taking advantage of our proximity to Dominion Energy’s 12-megawatt Coastal Virginia Offshore Wind farm – only the second offshore wind project in the country. Henrico’s environmental focus is further reflected by our home state. In July 2020, Clean Energy Virginia was launched. The initiative was designed with the goal of powering 100% of the Commonwealth’s electricity from carbon-free sources by 2045.</p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src={ESGImage2} alt="Inclusion" width="100%"/>
                    <br/><br/>
                    <p><h3>Equity and Inclusion</h3></p>
                    <p>We fully understand that small businesses are the lifeblood of our economy and that inclusive growth is vital to the vibrancy of the communities throughout our region. It’s why we proactively engage with new and existing companies doing business in Henrico to discuss supply chain, vendor, and contractor needs. We work to ensure that our businesses have easy access to the goods and services provided by our small, women-owned, and minority-owned (SWaM) businesses.</p>
                    <p>We also collaborate with the Henrico County Purchasing Division. Together, we host periodic webinars to educate Henrico businesses on the SWaM certification process and to further expand the database of Henrico-based certified companies. Through these actions, everyone can be equipped with the resources and network needed to drive shared success. Because we firmly believe that opportunities should be shared equally with everyone.</p>
                    </Col>                    
        </Row>   
        <Row>
              <h3>Corporate Diversity</h3>
              <p>When it comes to governance and decisions that affect your long-term vision, every voice needs to be heard. Board compositions should reflect the world in which we live not simply because it is the right thing to do, but also because it’s the right thing to do for business. When people from different backgrounds and perspectives are embraced, it gives rise to innovation that heightens competitiveness.</p>

              <p>To that end, Henrico offers a diverse, skilled labor pool from which you can draw to reflect diversity within your executive ranks. Further, our population is comprised of more women than men. From 2010-2020, Henrico added nearly 34,000 Asian, Black, Hispanic, and multiracial residents. And Virginia State and Virginia Union, two nearby universities, are historically black.</p>
            </Row>
          </Col>
        </Row>                
        <NewsInsights />
      </Container>
    </div>
  );
};

export default SwamStatement;
