import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import heroImage from "../../HomePage/images-home/home-hero-background.png";
import Hero from "../Hero/Hero";

const title = 'Newsletter - Henrico';

const SubscribeNewsletterError = () => {
    return (
        <div>
            <Hero height="200px" image={heroImage} title={title} />
            <Container>
                <Row>
                    <Col>
                        <p className="paragraph-header--blue">Sorry.</p>
                        <p>There was an issue subscribing you to our newsletter, please try again later.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SubscribeNewsletterError;