import React from "react";
import Cables from "./images/WorldMap_Cables_Rev7.mp4";
import './RichmondNapHero.scss';

const RichmondNap = () => {
    return (
        <div>
            <video width="100%" height="100%" autoPlay muted loop>
                <source src={Cables} type="video/mp4">
                </source>
            </video>
        </div>

    );
};

export default RichmondNap;