import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SiteSelectorsHero from "./SiteSelectorsHero";
import SiteSelectorsNav from '../shared/Navs/SiteSelectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import './DataConnectivity.scss';
import { remoteData } from '../shared/Cms';
import { getListItems } from '../../utils/cms';

const pageTitle = 'Data Connectivity';

const DataConnectivity = () => {
  const { domain, section, page } = {
      domain: 'henrico',
      section: 'site-selectors',
      page: 'Data Connectivity'
    };
    const [loading, setLoading] = useState(true);
    const [selector, setSelector] = useState([]);
  
    useEffect(() => {
      remoteData({domain, section, page})
        .then(data => {
          setSelector(data[0]);
          setLoading(false);
        });
    }, []);
  
    if(loading) {
      return <></>;
    }

return (
    <div>
        <SiteSelectorsHero />        
        <Container>
        <SiteSelectorsNav />
        <PageTitle title={pageTitle} />
            <Row>
                <Col>
                    <p>For businesses in search of lightning-fast global communications necessary for the modern world and the infrastructure needed to drive your digital transformation, Henrico is the place to be.</p>
                    <div style={{textAlign: 'center'}} id="videoWrapper">
                      <iframe className="youtube-video" src="https://www.youtube.com/embed/vMxt8r1kfhU" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                    </div>
                    <p><strong>QTS Richmond NAP</strong><br />The Richmond NAP is the only location in the world where four subsea cables, robust terrestrial networks, and data center management converge. Here, you can gain unprecedented access to the world’s highest capacity subsea cable, the Google-owned Dunant, which offers a record-breaking capacity of 250 terabits per second – fast enough to transmit the entire digitized Library of Congress three times every second. If you’re looking for a way to accelerate your global reach, turn your eyes to the <a href="https://www.henrico.com/richmond-nap" target="_blank;">Richmond NAP</a>.</p>
                    <p><strong>DE-CIX</strong><br />RVA-IX’s highly anticipated integration with DE-CIX interchanges in Chicago, New York, and Dallas will create the largest neutral interconnection ecosystem in North America – and provide virtually instant connection with the leading IX operator’s more than 2,100 networks and 500 data centers around the world. When connected to the network, local businesses can save money on cross-connect costs, save time with direct access to multiple networks, and gain dependable interconnection access with superior peering connectivity. Find out more in the <a href="https://www.henrico.com/news/press-releases-and-announcements/2020-12-17/de-cix" target="_blank;">official press release</a>.</p>            
                </Col>
            </Row>
            <NewsInsights />
            </Container>
        </div>
    );      
};    

export default DataConnectivity;    