import React from "react";
import { Row, Col } from "react-bootstrap";

import "./SubscribeNewsletterHenricoNow.scss";

const SubscribeNewsletterHenricoNow = () => {
    return (
            <Row className="mt-0">
                <Col>
                    <div className="contact-box-2019">
                        <form id="subscribeForm" action="http://cl.exct.net/subscribe.aspx" name="subscribeForm" method="post">
                            <input type="hidden" className="henrico-now-newsletter-input"name="thx" value={`${process.env.REACT_APP_HOME_URL}/subscribe-newsletter-success`} />
                            <input type="hidden" className="henrico-now-newsletter-input"name="err" value={`${process.env.REACT_APP_HOME_URL}/subscribe-newsletter-error`} />
                            <input type="hidden" className="henrico-now-newsletter-input"name="MID" value="1053940" />
                            <input type="hidden" className="henrico-now-newsletter-input"name="lid" value="498" />
                            <input type="hidden" className="henrico-now-newsletter-input"name="SubAction" value="sub_add_update" />
                            <div id="subscribe-form">
                                {/* <p id="form-error" className="error">Please enter all fields.</p>
                                <p id="email-error" className="error">Please enter a valid email address.</p> */}
                                <input type="text" className="henrico-now-newsletter-input"name="First Name" id="first-name" />
                                <p className="form-label-text"><label htmlFor="first-name">First Name</label></p>
                                <input type="text" className="henrico-now-newsletter-input"name="Last Name" id="last-name" />
                                <p className="form-label-text"><label htmlFor="last-name">Last Name</label></p>
                                <input type="text" className="henrico-now-newsletter-input"name="Email Address" id="subscribe-email" />
                                <p className="form-label-text"><label htmlFor="subscribe-email">Email</label></p>
                                <input type="text" className="henrico-now-newsletter-input"name="Company Name" id="company-name" />
                                <p className="form-label-text"><label htmlFor="company-name">Company Name</label></p>
                                <input type="submit" className="henrico-now-newsletter-input"value="Submit" className="button-primary submit-button subscribe-newsletter-henrico-now" />
                                {/* <p className="disclaimer">By submitting this form, you are agreeing to our collection, storage, use and disclosure of your personal info in accordance with our <a href="http://henrico.com/about-henrico-eda/privacy-statement" target="_blank">privacy policy</a>, as well as to receiving emails from us.</p> */}
                            </div>
                            {/* <div id="subscribe-success">
                            <p>Thank you! Your email address has been successfully added to our monthly e-newsletter.</p>
                        </div> */}
                        </form>
                    </div>
                
                </Col>
            </Row>
    )    
}

export default SubscribeNewsletterHenricoNow;

