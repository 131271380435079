import React, { useState } from 'react';
import './IndustrialProperties.scss';

const Accordion = ({ title, properties }) => {
  const [isOpen, setIsOpen] = useState(false);

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div className="accordion-wrapper">
      <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
        {title}
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="accordion-content">
          {properties.map((property, index) => (
            <div key={index} className="property-item">
              <a href={property.link} target="_blank" rel="noopener noreferrer">
                <img src={property.image} alt={property.name} className="property-image"/>
              </a>
              <p>
              <a href={property.link} target="_blank" rel="noopener noreferrer"><span class="property-title" dangerouslySetInnerHTML={createMarkup(property.name)}></span></a><br/>
                <span dangerouslySetInnerHTML={createMarkup(property.info)} />
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const IndustrialPropertiesApp = () => {
  
  const propertiesLessThan100 = [
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/5600-Audubon_1.png',
      name: '5600 Audubon',
      info: '&bull; ~25 acres<br/>&bull; Zoned M-1<br/>&bull; All utilities in place<br/>&bull; Less than 2 miles to I-64<br/>&bull; Less than 2 miles to Richmond International Airport',
      link: 'https://sites.vedp.org/henrico/propertyid/242926',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/airportslogisticscenterbuilding4.png',
      name: 'Airport Logistics Center - Building 4',
      info: '&bull; ~17.1 acres &bull; Building Size: 187,500 SF (750’ x 250’)<br/>&bull; Zoned M-2<br/>&bull; All utilities in place<br/>&bull; Excellent location with access to major interstates<br/>&bull; 1.5 mi from Richmond International Airport',
      link: 'https://sites.vedp.org/henrico/propertyid/288000',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/dragway.jpg',
      name: 'Dragway Site',
      info: '&bull; ~92 acres<br/>&bull; Zoned M-3<br/>&bull; All utilities in place<br/>&bull; 3.6 mi to I-95<br/>&bull; ~5 mi to RIC',
      link: 'https://sites.vedp.org/henrico/propertyid/299589',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/henrico-now/2023/franz_haas.png',
      name: 'Franz Haas Site',
      info: '&bull; 65+ acres<br/>&bull; Zoned M-2<br/>&bull; All utilities in place<br/>&bull; Excellent location with access to major interstates<br/>&bull; Less than 2 miles to Richmond International Airport',
      link: 'https://sites.vedp.org/virginia/propertyid/235170',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/Henrico-Business-Center-Lot-G_1.png',
      name: 'Henrico Business Center Lot G',
      info: '&bull; ~45 acres<br/>&bull; Zoned M-1<br/>&bull; All utilities in place<br/>&bull; Less than 2 miles to I-95<br/>&bull; Adjacent to Richmond Raceway and Amazon FC',
      link: 'https://sites.vedp.org/henrico/propertyid/298858',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/1895logisticscenter.png',
      name: 'I-895 Logistics Center',
      info: '&bull; Building Size: 583,100 SF<br/>&bull; (470’ x 1,232’)<br/>&bull; Zoned M-1<br/>&bull; All utilities in place</br>&bull; Excellent location with access to major interstates<br/>&bull; Less than 3 miles to Richmond International Airport',
      link: 'https://sites.vedp.org/henrico/propertyid/288713',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/riceastsidesite.png',
      name: 'RIC East Side Site',
      info: '&bull; 49 acres<br/>&bull; Zoned M-2C, heavy industrial (conditional)<br/>&bull; All utilities in place<br/>&bull; Excellent location with access to major interstates<br/>&bull; Adjacent to Richmond International Airport',
      link: 'https://sites.vedp.org/henrico/propertyid/299039',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/henrico-now/2023/tech_blvd2.png',
      name: 'Technology Blvd Site',
      info: '&bull; 51 acres<br/>&bull; Zoned M-1<br/>&bull; All utilities in place<br/>&bull; Excellent location with access to major interstates<br/>&bull; Adjacent to White Oak Technology Park<br/>&bull; Less than 3 miles to Richmond International Airport',
      link: 'https://sites.vedp.org/henrico/propertyid/299046',
    },
  ];

  const propertiesMoreThan100 = [
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/businesscenterlotj.png',
      name: 'Henrico Business Center, Lot J',
      info: '&bull; 100+ acres<br/>&bull; Zoned M-2C<br/>&bull; All utilities in place<br/>&bull; Excellent location with access to major interstates <br/>&bull; Adjacent to Richmond Raceway and Amazon FC',
      link: 'https://sites.vedp.org/henrico/propertyid/298859',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/farevasite.png',
      name: 'Fareva Site',
      info: '&bull; 120+ acres<br/>&bull; Zoned M-1<br/>&bull; All utilities in place<br/>&bull; Excellent location with access to major interstates<br/>&bull; Less than 3 miles to Richmond International Airport',
      link: 'https://sites.vedp.org/henrico/propertyid/245200',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/henrico-now/2023/beuleh.png',
      name: 'RIC Beulah Site',
      info: '&bull; 220 acres<br/>&bull; Zoned M-1C and M-2 heavy industrial (conditional)<br/>&bull; All utilities in place<br/>&bull; Excellent location with access to major interstates<br/>&bull; Adjacent to Richmond International airport',
      link: 'https://sites.vedp.org/henrico/propertyid/299040',
    },
    {
      image: 'https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/sauerindustrialcenter.png',
      name: 'Sauer Industrial Center',
      info: '&bull; 300+ acres, largest tract is ~70 acres<br/>&bull; Zoned M-1 &bull; All utilities in place<br/>&bull; Excellent location with access to major interstates<br/>&bull; Less than 2 miles to Richmond International Airport',
      link: 'https://sites.vedp.org/virginia/propertyid/234302',
    },
  ];

  return (
    <div>
      <Accordion title="Properties With Less than 100 Available Acres" properties={propertiesLessThan100} />
      <Accordion title="Properties With More than 100 Available Acres" properties={propertiesMoreThan100} />
    </div>
  );
};

export default IndustrialPropertiesApp;
