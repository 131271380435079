import { Container, PageItem } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import PageTitle from '../shared/PageTitle/PageTitle';
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from '../shared/Navs/NewsNav';
import UpcomingEventsPost from "./UpcomingEventsPost";
import Pagination from "../shared/Pagination/Pagination";
import axios from 'axios';
import './UpcomingEventsArchive.scss';
import {sortEvents} from "../shared/Utilities/Sort";
import { remoteData, RemoteContent } from '../shared/Cms';

const UpcomingEventsArchive = () => {
  const { domain, section } = {
    domain: 'henrico',
    section: 'upcoming-events',
  };

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    remoteData({domain, section})
      .then(data => {
        const events = sortEvents(data);
        setUpcomingEvents(events);
        setLoading(false);
      });
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = upcomingEvents.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  return (
    <div>
      <NewsHero />
        <Container>
          <NewsNav />
            <PageTitle title={
             "Upcoming Events"
            } />
              <PageTitle title/>
                <UpcomingEventsPost posts={currentPosts} loading={loading} />
                  { currentPosts.length > postsPerPage && <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={upcomingEvents.length}
                    paginate={paginate}
                  />
                  }
                      <NewsInsights />
                        </Container>
                          </div>
  );
};

export default UpcomingEventsArchive;
