import { Col, Container, Row} from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/health-and-life-sciences-logos.jpg';
import "./TargetSectors.scss";
import DataCard from '../shared/DataCard/DataCard';
import Quote from "../shared/Quote/Quote.js";
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import "./HealthAndLifeSciences.scss";

const pageTitle = 'Henrico Health & Life Sciences';

const quoteData = {
    copy: "“Our U.S. expansion project is an exciting opportunity for future growth. We explored U.S. locations and selected Virginia and Henrico from several that were considered. Not only is this location in close proximity to most of our U.S. customers – it provides us with a foundation for future strategic growth and expansion.”",
    signature: "Lionel Genix, CEO & President, Seppic Inc., Air Liquide Healthcare Specialty Ingredients"
}

const HealthAndLifeSciences = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                               
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>
                    Biotechnology, healthcare, and medical companies need the best health and life science industry location possible, and Henrico, Virginia, delivers. By providing the best business climate for growth and innovation, we help the healthcare industry companies that help protect the world. <br/><br/>This includes life science companies like <a href="https://www.thermofisher.com/us/en/home.html" target="_blank">ThermoFisher Scientific</a>. The world leader in serving science has three bioanalytical laboratories in Henrico and praised us for being a <a href="https://www.henrico.com/why-henrico/skilled-workforce" target="_blank;">talent hub</a>. Meanwhile, Henrico-headquartered laboratory testing company <a href="https://genetworx.com/" target="blank">GENETWORx</a> is undergoing rapid expansion. <br/><br/>So why choose Henrico for life sciences? We create more opportunities for healthcare industry companies to succeed.<br/><br/>Keep reading to discover more. Then download this convenient <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/HEN23001_Life+Sciences_8.5+X+11_ENGLISH_v1.pdf" target="_blank" rel="noopener noreferrer">resource</a> to keep our advantages top of mind.
                    <br/><br/>
                    </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/ls1.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Top-Tier Talent</h3></p>
                    <p>
                    Establish, expand, or relocate your business here and your <a href="https://www.henrico.com/why-henrico/skilled-workforce" target="_blank">talent pool</a> will never run dry. Through our Blue Ribbon schools and investments in STEM education, we help inspire the curiosity of future innovators. Nearly 30 nearby colleges, universities, and professional schools continue the excellence in learning – including VCU, a Top 100 life sciences research center.
                    </p>
                        <ul>
                            <li>Home to VCU Health System, the largest teaching hospital in Virginia</li>
                            <li><a href="https://soe.vcu.edu/centers/center-for-innovation-in-stem-education/" target="_blank">The Center for Innovation in STEM Education at VCU</a> empowers diverse young thinkers in our area</li>
                            <li>Virginia ranked No. 2 in the country for education and No. 3 for workforce by CNBC</li>
                        </ul>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/ls2.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Henrico Life Science Tax Incentives</h3></p>
                    <p>
                    Our locality has a proven track record of doing what it takes to bolster <a href="https://www.henrico.com/site-selectors/taxes" target="blank">your bottom line</a>. We constantly work to cultivate the type of world-class business environment that life sciences companies need to succeed.
                    </p>
                    <ul>
                            <li>Research and Development (R&D) tax rate slashed by 74% to $0.90 per $100 of assessed value – the lowest rate in Virginia</li>
                            <li>6% corporate income tax rate – one of the lowest in the U.S. – which has not fluctuated since 1972</li>
                            <li><a href="https://www.henricocitizen.com/articles/henrico-officials-planning-technology-zone-at-innsbrook/" target="_blank">Innsbrook Technology Zone</a> in development, offering building and permit fee waivers, custom incentives, and more</li>
                        </ul>                    
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/ls3.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Unparalleled Logistics Access</h3></p>
                    <p>
                    There’s a reason why fDi Intelligence recognized Henrico, Virginia, the second-best place in the world for <a href="https://content.yudu.com/web/43wcl/0A43wm9/fDidec20/html/index.html?page=112&origin=reader" target="_blank">Transport and Warehousing strategy</a>. With a <a href="https://www.henrico.com/why-henrico/market-access" target="_blank;">strategic location</a> in the middle of the East Coast, we offer all the advantages that help make global trade virtually seamless. So, when you want to get your products to healthcare facilities and customers worldwide, your global supply chain starts in Henrico.
                    </p>
                        <ul>
                            <li>Home to Richmond International Airport, one of the busiest air cargo facilities in the nation and a designated Foreign Trade Zone</li>
                            <li>Easy access to an <a href="https://www.joc.com/port-news/port-virginia-weathers-record-volumes-automation-agility_20210908.html" target="_blank">expanding Port of Virginia</a>, currently the third largest container port on the East Coast</li>
                            <li>Proximity to interstates I-95 and I-64, facilitating domestic transportation of goods to all points east and west</li>
                        </ul>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/ls4.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Available Lab Space</h3></p>
                    <p>
                    With almost 5 million square feet of laboratory space, our state is in the <a href="http://www.biohealthcapital.com/" target="_blank">BioHealth Capital Region</a> (BHCR) – and Henrico is fast becoming a hotspot for biotech companies looking to thrive. With the industry’s explosive growth and BHCR’s goal of becoming a Top 3 BioHealth hub by 2023 top of mind, Henrico is ready for now while preparing for the future.
                    </p>
                        <ul>
                            <li>Nearly 650 bioscience firms located in Richmond region</li>
                            <li>BHCR ranked #4 on <a href="https://www.genengnews.com/the-lists/top-10-us-biopharma-clusters/77901149" target="_blank">GEN’s Top 10 U.S. Biopharma Clusters List</a></li>
                            <li>Henrico in talks to build a speculative, customizable facility to house several bioscience <a href="https://www.henricocitizen.com/articles/henrico-officials-planning-push-to-attract-bioscience-wet-labs/" target="_blank">R&D “wet labs”</a> under one roof</li>
                        </ul>                   
                    </Col>                                       
                </Row>                                 
                {/* <Quote copy={quoteData.copy} signature={quoteData.signature} signatureTitle={quoteData.signatureTitle} /> */}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default HealthAndLifeSciences;