import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SiteSelectorsHero from './SiteSelectorsHero';
import SiteSelectorsNav from "../shared/Navs/SiteSelectorsNav";
import PageTitle from '../shared/PageTitle/PageTitle';
import taxes from "./images/Image 16.png";
import Data from "./images/Image 17.png";
import Data1 from "./images/Image 18.png";
import Data2 from "./images/Image 19.png";
import './Downloads.scss';
import { remoteData } from '../shared/Cms';

const Downloads = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'site-selectors',
    page: 'Data Downloads'
  };
  const [loading, setLoading] = useState(true);
  const [selector, setSelector] = useState([]);

  useEffect(() => {
    remoteData({domain, section, page})
      .then(data => {
        setSelector(data[0]);
        setLoading(false);
      });
  }, []);

  if(loading) {
    return <></>;
  }

    return (
        <div>
            <SiteSelectorsHero />
            <Container>
                <SiteSelectorsNav />
                <PageTitle title={selector.title} />
                <Row>
                  <Col lg={12} sm={12} md={12} xl={12} dangerouslySetInnerHTML={{__html: selector.s1}} />
                </Row>
                <Row className="downloads-box">
                    <Col lg={12} sm={12} md={12} xl={12}>
                        <Row>

                          <Col lg={10} sm={10} md={10} xl={10}>
                            <p className="paragraph-header--blue">Taxes</p>
                            <span dangerouslySetInnerHTML={{__html: selector.taxes}} />
                          </Col>
                          <Col lg={2} sm={2} md={2} xl={2}>
                              <img src={taxes} alt=""></img>
                          </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col lg={10} sm={10} md={10} xl={10}>
                              <p className="paragraph-header--blue">Community Data</p>
                              <span dangerouslySetInnerHTML={{__html: selector.communityData}} />
                            </Col>
                            <Col lg={2} sm={2} md={2} xl={2}>
                                <img src={Data} alt=""></img>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col lg={10} sm={10} md={10} xl={10}>
                              <p className="paragraph-header--blue">Regional &amp; International Maps </p>
                              <span dangerouslySetInnerHTML={{__html: selector.internationalMap}} />
                            </Col>
                            <Col lg={2} sm={2} md={2} xl={2}>
                                <img src={Data1} alt=""></img>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col lg={10} sm={10} md={10} xl={10}>
                                <p className="paragraph-header--blue">Incentives</p>
                                <span dangerouslySetInnerHTML={{__html: selector.incentives}} />
                            </Col>
                            <Col lg={2} sm={2} md={2} xl={2}>
                                <img src={Data2} alt=""></img>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                        <p className="paragraph-header--blue">Get a Customized Data Report</p>
                        <span dangerouslySetInnerHTML={{__html: selector.p2}} />
                    </Col>
                </Row>
                <NewsInsights />
            </Container>
        </div>
    );
};

export default Downloads;
