import WhiteOakHero from "./WhiteOakHero";
import React, { useEffect, useState } from "react";
import {Col, Container, Row} from "react-bootstrap";
import ResourcesAndDownloads from "../shared/FindAProperty/ResourcesAndDownloads";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import './WhiteOakLocation.scss'
import './WhiteOakInfrastructure.scss'
import WhiteOakNav from "../shared/Navs/WhiteOakNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import { remoteData } from '../shared/Cms';

const WhiteOakInfrastructure = () => {
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    remoteData({domain: 'henrico', section: 'white-oak-tech-park', page: 'Infrastructure'})
      .then(data => {
        setPageData(data[0]);
        setLoading(false);
      });
  }, []);

  if(loading) {
    return <>Loading...</>;
  }
    return (
        <div>
            <WhiteOakHero />
            <Container>
                <WhiteOakNav/>
                <PageTitle title={pageData.title} />
                <Row>
                    <Col>
                      <p dangerouslySetInnerHTML={{ __html: pageData.s1 }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: pageData.s2 }} />
                        <div dangerouslySetInnerHTML={{ __html: pageData.s3 }} />
                        <div dangerouslySetInnerHTML={{ __html: pageData.s4 }} />
                        <div dangerouslySetInnerHTML={{ __html: pageData.s5 }} />
                        <div dangerouslySetInnerHTML={{ __html: pageData.s6 }} />
                    </Col>
                </Row>
            </Container>
            <ResourcesAndDownloads />
            <Container>
            <NewsInsights/>
        </Container>
        </div>
    );
}

export default WhiteOakInfrastructure;
