import React from "react";
import { Container } from "react-bootstrap";
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from "../shared/Navs/NewsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import ListlyEmbed from "./ListlyEmbed";
import "./MediaCoverageArchive.scss";

const pageTitle = 'Media Coverage';
const MoreNews = () => {
    return (
        <div>
            <NewsHero />
            <Container>
                <NewsNav />
                <PageTitle title={pageTitle} />
                <ListlyEmbed />
            </Container>
        </div>
    )
}

export default MoreNews;