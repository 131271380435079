import React, { useEffect, useState } from 'react';
import { initGtm } from './utils/analytics';

import './App.scss';

import Footer from './components/shared/Footer/Footer.js';
import Social from "./components/shared/Social/Social.js";
import Header from './components/shared/Header/Header.js';
import Routes from './components/shared/Routes/Routes.js';

function App() {
  const [gtmInitialized, setGtmInitialized] = useState(false);

  useEffect(() => {
    if(gtmInitialized === false) {
      initGtm();
      setGtmInitialized(true);
    }
  }, []);

  return (
    <div className="container-fluid">
        <Header />
        <Routes />
        <Social />
        <Footer />
    </div>
  );
}

export default App;
