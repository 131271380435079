import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import WhyHenricoHero from "./WhyHenricoHero";
import WhyHenricoNav from "../shared/Navs/WhyHenricoNav";
import PageTitle from "../shared/PageTitle/PageTitle";

const pageTitle = "A Road Map for Economic Development";

const StrategicPlan = () => {
  return (
    <div>
      <WhyHenricoHero />
      <Container>
        <WhyHenricoNav />
        <PageTitle title={pageTitle} />
        <Row>
          <Col lg={12} sm={12} md={12} xl={12}>
            <p>
              Home to more than 25,000 businesses, four Fortune 500 companies,
              and 330,000 people, Henrico is a vibrant community that has worked
              together to spur significant growth over the years. We have the
              location, infrastructure, and assets necessary to bolster a wide
              variety of industries – and it shows.
            </p>
            <p>
              Success like ours does not happen by chance, but by hard work and
              design. To learn more about opportunities to develop
              community-wide success and discover the Henrico EDA's vision for
              continued growth, please read our{" "}
              <a
                href="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/Henrico-County-EDA-Strategic-Plan-FINAL-0422-2020.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                2020 Economic Development Strategic Plan
              </a>
              .
            </p>
            <p>
            To discover how we’re achieving our mission of cultivating a vibrant economy and promoting an outstanding quality of life, take a look at our{" "}
              <a
                href="https://henrico-eda-site-assets.s3.amazonaws.com/home/HEN23001_Annual+Report_CMYK_FINAL_Website+Version_2024.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Look Book
              </a>
              .
            </p>            
          </Col>
        </Row>
        <NewsInsights />
      </Container>
    </div>
  );
};

export default StrategicPlan;
