import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/data-centers-logos.png';
import "./TargetSectors.scss";
import "./DataCenters.scss";
import Quote from "../shared/Quote/Quote.js";
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';

const pageTitle = 'Henrico Professional Services';

// const paragraphStyles = {
//     fontWeight: 600
// };

// const copyTitle = {
//     color: '#054496',
//     fontWeight: '600'
// }

const quoteData = {
    copy: "“Both ASGN and Apex Systems are very proud to be a part of the Henrico County community. Apex Systems, a division of ASGN, has enjoyed tremendous growth during its more than 25 years in the county, and, as a result, ASGN recently chose to move its corporate headquarters from Calabasas, California to Henrico County. The diverse candidate pool, convenient location within the Commonwealth of Virginia, and countless corporate partnerships available locally are only a few examples of why we have made Henrico County our home.”",
    signature: "Buddy Omohundro, Chief Services Officer, Apex Systems"
}

const quote2Data = {
    copy: "“Nearly 40 years ago, Draper Aden Associates established an office in the Central Virginia region because of our growing relationship with Henrico County. Since that time, we have partnered with the County on countless projects that have improved the quality of life in our communities. Henrico’s exceptional planning, foresight, and fiscal stability make Draper Aden Associates proud to be a legacy company and Henrico-based business.”",
    signature: "W. Charles Kreye II, P.E., Managing Principal / Vice President",
    signatureTitle: "Site Development & Infrastructure / Energy Divisions, Draper Aden Associates"    
}

const ProfessionalServices = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                               
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>
                    Professional service firms are in the business of providing knowledge and expertise. So when they’re looking for the smartest location in which to thrive, they’re consistently choosing Henrico, Virginia. Located just outside the capital of Richmond in CNBC’s Best State for Business, we offer a dynamic business environment that powers our $25 billion economy. Perhaps this is why <a href="https://www.snagajob.com/" target="_blank">Snagajob</a>, America’s largest platform for hourly work, and Fortune 1000 tech consulting company <a href="https://www.asgn.com/" target="_blank">ASGN</a> both have established their headquarters here. But it’s far from the only reason. Whether you offer professional business services in hiring solutions, IT, consulting, finance, or creative markets, you’ll discover there are several advantages to growing your business in Henrico.<br/><br/>And download this convenient <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/HEN22002_Corporate+Services_8.5x11_FINAL+0926.pdf" target="_blank" rel="noopener noreferrer">resource</a> to keep our advantages handy while you're on the go.
                    <br/><br/>
                    </p>
                    <Quote copy={quoteData.copy} signature={quoteData.signature} /> 
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/professionalservices1.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Skilled Labor Pool</h3></p>
                    <p>
                        Our home of Virginia has been voted the best state for higher education with the best public schools in the South, and Henrico contributes greatly to our earned honors. We are nationally recognized for our ability to encourage achievement, inspire creativity, and cultivate the minds of future generations due to our:
                    </p>
                        <ul>
                            <li>Nationally recognized education system, which features nine Blue Ribbon Schools and 5 specialty centers</li>
                            <li>Vast talent pipeline that includes nearly 30 colleges, universities, and professional schools</li>
                            <li>Diversity of thought supported by growing Asian, Black, Hispanic, and multiracial populations that have increased by 34,000 in just ten years</li>
                        </ul>
                    <p>
                        Bottom line: Here you can easily discover <a href="https://www.henrico.com/why-henrico/skilled-workforce" target="_blank">bright talent</a> with the necessary skillset to absorb information, analyze it from unique perspectives, and provide innovative solutions for your clients.
                    </p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/professionalservices2.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Seamless Transportation</h3></p>
                    <p>
                        When you’re a part of the Henrico professional services industry, business travel is simple – whether you need to stay on the move by road, air, or rail.
                    </p>
                    <ul>
                            <li>Driving is a breeze thanks to Henrico’s proximity to I-95 and I-64, and a 22-minute average commute.</li>
                            <li>Take advantage of eight major carriers at <a href="https://flyrichmond.com/" target="_blank">Richmond International Airport</a>, which offers daily direct flights to domestic business hubs and points of connection to business epicenters around the world.</li>
                            <li>Enjoy Amtrak’s <a href="https://www.globalrailwayreview.com/news/128250/amtrak-virginia-rail-expansion-early-morning-service-main-street-station/" target="_blank;">Northeast Regional Rail 51 expansion</a> and its convenient downtown-to-downtown train service to major cities along the East Coast. With three daily options, get to Washington, D.C., for an early morning meeting or be in New York City in time for a power lunch.</li>
                        </ul>                    
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/professionalservices3.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Unbeatable Connectivity</h3></p>
                    <p>
                        Professional service firms are often only as good as their IT, and Henrico has developed a rock-solid internet infrastructure to serve as your foundation. It includes:
                    </p>
                        <ul>
                            <li>The <a href="https://www.qtsdatacenters.com/hybrid-solutions/connectivity/qts-richmond-nap" target="_blank">QTS Richmond Network Access Point</a> located in our <a href="https://www.henrico.com/white-oak/quick-facts" target="_blank;">White Oak Technology Park</a>. The facility offers local businesses access to more than 20 different fiber networks in addition to four subsea cables.</li>
                            <li>Full integration with <a href="https://www.de-cix.net/en/locations/richmond" target="_blank;">DE-CIX Richmond</a>, a partnership that allows companies to tap into more than 2,400 network operators and more than 500 data centers across the world. This massive reach is carrier and data center neutral – meaning businesses are not reliant on a single connectivity option to transmit their data.</li>
                            <li>The fastest data speeds on the planet. The Henrico professional services business community can send data to Europe and back in one-third of a second – the literal blink of an eye.</li>
                        </ul>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/professionalservices4.jpg" width="100%"/>
                    <br/><br/>
                    <p><h3>Vibrant Culture</h3></p>
                    <p>
                    Businesses that offer consulting and creative services benefit from recruits who are curious, and we provide the type of cultural climate that your talent craves. For inquisitive minds, we offer:
                    </p>
                        <ul>
                            <li>Nearby cultural institutions like the <a href="https://smv.org/" target="_blank;">Science Museum of Virginia</a>, <a href="https://icavcu.org/" target="_blank;">Institute for Contemporary Art at VCU</a>, and <a href="https://www.vmfa.museum/" target="_blank;">Virginia Museum of Fine Arts</a>, which is undergoing a $190 million expansion</li>
                            <li>Local favorites like <a href="https://www.artsglenallen.com/" target="_blank;">The Cultural Arts Center of Glen Allen</a> and the <a href="https://www.latinballet.com/" target="_blank;">Latin Ballet of Virginia</a></li>
                            <li>A vibrant music scene that sings with intimate performance spaces like <a href="https://tinpanrva.com/" target="_blank;">The Tin Pan</a> and massive outdoor concert series like <a href="https://www.vaculive.com/" target="_blank;">Virginia Credit Union Live!</a> at Richmond Raceway, which has drawn renowned acts like Bon Iver, Norah Jones, and Wu-Tang Clan</li>
                        </ul>
                    <p>
                    With so many world-class options, your workforce is sure to enjoy both living and working in Henrico.
                    </p>                    
                    </Col>                                       
                </Row>                                 
                {/* <Quote copy={quote2Data.copy} signature={quote2Data.signature} signatureTitle={quote2Data.signatureTitle} /> */}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default ProfessionalServices;