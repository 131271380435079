import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SiteSelectorsHero from './SiteSelectorsHero';
import SiteSelectorsNav from '../shared/Navs/SiteSelectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import { remoteData } from '../shared/Cms';

const { domain, section, page } = {
  domain: 'henrico',
  section: 'site-selectors',
  page: 'Incentives'
};

class Incentives extends React.Component {
    constructor() {
        super();
        this.state = {
          showMore: false,
          showMore1: false,
          name: "React",
          test: 'example',
          selector: [],
          loading: true
        }
        this.toggleShowMore = this.toggleShowMore.bind(this);
        this.toggleShowMore1 = this.toggleShowMore1.bind(this);
    }

  componentDidMount() {
    remoteData({domain, section, page})
      .then(data => {
        this.setState({
          selector: data[0],
          loading: false
        });
      });
  }

    toggleShowMore(event){
        event.preventDefault();
        this.state.showMore === true ?  this.setState({showMore: false}) : this.setState({showMore: true});
    }


    toggleShowMore1(event) {
        event.preventDefault();
        this.state.showMore1 === true ?  this.setState({showMore1: false}) : this.setState({showMore1: true});
    }


    render() {
      const { selector, loading } = this.state;
      const showMoreOneMap = ['recruitment','virginiaJobsInvestment','enterpriseZones','portOfVirginia','utilityIncentives','corporateTaxCredits','salesAndTax','discretionaryIncentives'];
      const showMoreTwoMap = ['progressive','enterpriseZone','foreignTradeZone','defenseProductionZone','commercial','virginiaCareer','smallBusiness','discretionaryIncentives1'];
      const renderShowMore = (arrMap) => {
        return arrMap.map(name => {
          return <p dangerouslySetInnerHTML={{__html: selector[name]}} />
        })
      };

      if(loading) {
        return <></>;
      }
        return (
            <div>
                <SiteSelectorsHero />
                <Container>
                    <SiteSelectorsNav />
                      <PageTitle title={
                        selector.title
                      } />
                    <Row>
                        <Col lg={12} sm={12} md={12} xl={12}>
                            <Row>
                              <Col lg={10} sm={10} md={10} xl={10}>
                                <span dangerouslySetInnerHTML={{__html: selector.s1}} />
                                <b><a href="#!" onClick={this.toggleShowMore}>{this.state.showMore === true ? '-' : '+'}Show {this.state.showMore === true ? 'Less' : 'More'}</a></b><br />
                              </Col>


                                <Col lg={2} sm={2} md={2} xl={2}>
                                    <img alt="" className="img-fluid" src={selector.image1}></img>
                                </Col>
                            </Row>
                            { this.state.showMore === true ?
                                <React.Fragment>
                                  <Row>
                                    <Col lg={10} sm={10} md={10} xl={10}>
                                      {renderShowMore(showMoreOneMap)}
                                    </Col>
                                  </Row>
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                            <Row>
                                <Col lg={10} sm={10} md={10} xl={10}>
                                    <span dangerouslySetInnerHTML={{__html: selector.s2}} />
                                    <b><a href="#!" onClick={this.toggleShowMore1}>{this.state.showMore1 === true ? '-' : '+'}Show {this.state.showMore1 === true ? 'Less' : 'More'}</a></b>
                                </Col>
                                <Col lg={2} sm={2} md={2} xl={2}>
                                    <img alt="henrico Map" className="img-fluid" src={selector.image2}></img>
                                </Col>
                            </Row>
                    { this.state.showMore1 === true ?

                        <React.Fragment>
                          <Row>
                            <Col lg={10} sm={10} md={10} xl={10}>
                              {renderShowMore(showMoreTwoMap)}
                            </Col>
                          </Row>
                        </React.Fragment>
                        :
                        <React.Fragment></React.Fragment>
                    }
                        </Col>
                    </Row>
                    <NewsInsights />
                </Container>
            </div>
        );
    }

}

export default Incentives;
