import React, {  } from "react";
import "./DataCard.scss";
import { useHistory } from "react-router-dom";

const DataCard = (props) => {
        const history = useHistory();

        const cardStyles = {
            // width: props.width,
            // height: props.height,
        }

        const cardTextStyles = {
            fontSize: props.fontSize
        }

        return (
            <div className={`data-card-wrapper`}>
                <div className={`data-card-header color-${props.cardClass}`}></div>
                <div className={`data-card ${props.cardSize}`} style={cardStyles}>
                    <div className="data-card-body">
                        <div className="title-line-1">{props.titleLine1}</div>
                        <div className="title-line-2">{props.titleLine2}</div>
                        <div className="title-line-3">{props.name}</div>
                        <div className="card-text" style={cardTextStyles}>{props.text}</div>
                        {history.location.pathname === '/why-henrico' ? <a className="data-card-button" href="#!" onClick={() => props.openModal(props)}>More ></a> :''}
                    </div>
                </div>
            </div>
        );
}

export default DataCard;
