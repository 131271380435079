import React from "react";
import heroImage from "./images/TargetSectorsHero.png";
import "./TargetSectorsHero.scss";

import Hero from '../shared/Hero/Hero';
const title = "Target Sectors";

const TargetSectorsHero = () => {
    return (
        <div>
            <Hero image={heroImage} title={title} heroCaption=""/>
        </div>
    );
};

export default TargetSectorsHero;