import React from "react";
import heroImage from './news-hero.png';
import Hero from '../Hero/Hero';
// import './HenricoNowArchive.scss';
const title = "News";

const SiteSelectorsHero = () => {
    return (
        <div>
            <Hero image={heroImage} title={title} heroCaption="RIC Airport"/>
        </div>
    );
};

export default SiteSelectorsHero;