import React from "react";
import { Container } from "react-bootstrap";
import NewsInsights from "../../components/shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "../ExistingBusiness/ExistingBusinessHero";
import ExistingBusinessNav from '../shared/Navs/ExistingBusinessNav';
import './BusinessFirstSurvey.scss';

import PageTitle from '../shared/PageTitle/PageTitle';

const pageTitle = 'Business First Survey';

const BusinessSurveyForm = () => {
    return (
      <div>
          <ExistingBusinessHero />
          <Container>
              <ExistingBusinessNav />
              <PageTitle title={pageTitle} />
                <iframe title="business-survey-form" src="https://henrico-eda-site-assets.s3.amazonaws.com/survey/survey.html"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="survey-iframe"></iframe>
              <NewsInsights />
          </Container>
      </div>
    );
};

export default BusinessSurveyForm;