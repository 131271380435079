import React from "react";
import HomeFacts from "./HomeFacts.js";
import HomeRotatingBanner from './HomeRotatingBanner';
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import ContactHenrico from '../shared/ContactHenrico/ContactHenrico';
import HomepageHero from '../shared/Hero/HomepageHero';
import "./HomePage.scss";
import AlertBanner from '../shared/AlertBanner/AlertBanner';

import heroImage from "./images-home/home-hero-background.png";
import { Container } from "react-bootstrap";
const title = "The 2024 Henrico Look Book";

const pageClassName = "alert-banner-home";

const HomePage = (props) => {
    return (
        <Container fluid>
            <HomepageHero 
                height="550px" 
                className="home" 
                image={heroImage} 
                title={title} 
                // heroCaption="Photo: Buttermilk & Honey"
            />
            <AlertBanner />
            <HomeFacts />
            <HomeRotatingBanner />
            <Container>
                <NewsInsights />
                <ContactHenrico />
            </Container>
        </Container>
    );
};

export default HomePage;