import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import AboutUsHero from "../AboutUs/AboutUsHero"
import SiteSelectorsNav from "../shared/Navs/AboutUsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import ContactUsForm from '../shared/Forms/ContactUsForm';
import './ContactUs.scss';

const pageTitle = 'Contact Us';

const ContactUs = (data) => {

    return(
        <div>
            <AboutUsHero/>
            <Container>
                <SiteSelectorsNav/>
                <PageTitle title={pageTitle} />
                <Col>
                    <Row>
                      <p>Let our knowledge and experience work for you. For more information, or to schedule a guided tour, please contact the Henrico EDA at <a href="tel:1-804-501-7654">804.501.7654</a> or reach out to one of our team members below.</p>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={3}>
                            Already established your business in Henrico and have questions? Contact Ebonie Atkins at <a href="mailto:ebonie@henrico.com">ebonie@henrico.com</a>
                        </Col>
                        <div class="spacingonmobile">
                        <Col xs={12} sm={12} md={3}>
                            <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/184748-ebonie2.png">
                            </img>
                        </Col>
                        </div>
                        <Col xs={12} sm={12} md={3}>
                            If you’re looking for someone to help you with establishing business operations in Henrico, please contact Andrew Larsen at <a href="mailto:andrew@henrico.com">andrew@henrico.com</a>
                        </Col>
                        <Col xs={12} sm={12} md={3}>
                            <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/184728-195816-andrew-headshot2.png">
                            </img>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={3}>
                            For any other questions or to schedule a guided tour, please contact Linda McArdle at <a href="mailto:linda@henrico.com">linda@henrico.com</a> or call us at <a href="tel:804-501-7654">804.501.7654</a>.
                        </Col>
                        <Col xs={12} sm={12} md={3}>
                            <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/184650-182549-192844-lINDA2.png">
                            </img>
                        </Col>
                        <Col>
                        &nbsp;
                        </Col>
                        {/* <Col>
                        &nbsp;
                        </Col> */}
                    </Row>
                    {/* <div class="hideOnMobile">
                        <Row>
                            <ContactUsForm />
                        </Row>
                    </div> */}
                </Col>
            </Container>
        </div>
    )
}

export default ContactUs;
