import React from "react";
import './AlertBanner.scss';

const AlertBanner = (props) => {
    return (
            <div className={`alert-banner ${props.pageClassName}`} >
                <p className="alert-banner-text">Think you're eligible for Innsbrook Technology Zone incentives? Fill out our <a href="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/office-space/Innsbrook+Technology+Zone+Fee+Waiver+Application.docx" target="_blank"><span style={{textDecoration: 'underline'}}>fee waiver application</span></a></p>
            </div>
        );
};

export default AlertBanner;