import React from "react";
import { useHistory } from "react-router-dom";
import './Hero.scss';

const Hero = (props) => {
    const heroStyleHome = {
        width: "100%",
        height: "550px",
        backgroundImage: `url(${props.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    }

    const heroStyleSecondary = {
        width: "100%",
        height: "400px",
        backgroundImage: `url(${props.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    }

    let history = useHistory();
    let pageType = ''
    history.location.pathname === "/" ? pageType = 'home' : pageType = 'secondary';

    return (
        <div>
            <div>
                <div className={`hero ${props.className}`} style={history.location.pathname === "/" ? heroStyleHome : heroStyleSecondary}>
                    <h1 className={`hero-title hero-title-${pageType}`}>{props.title}</h1>
                    {
                        history.location.pathname === "/" ? <button className="button-primary button-hero-home" onClick={() => {history.push('/why-henrico/qualityoflife')}} >FIND OUT MORE</button> : ''
                    }
                    {props.heroCaption && (
                        <div className="hero-caption">{props.heroCaption}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Hero;
