import React from "react";
import heroImage from './images/200303-A-TC012-005.png';
//import heroImage from "../../TargetSectors/images/capital-one-west-creek.png";
import Hero from '../Hero/Hero';
// import AlertBanner from '../AlertBanner/AlertBanner';

const title = "COVID-19";


const COVIDHero = () => {
    return (
        <div>
            <Hero image={heroImage} title={title} />

        </div>
    );
};

export default COVIDHero;

