import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./SecondaryNav.scss";

const ExistingBusinessNav = (props) => {   
        return (
            <div>
                <Navbar expand="xs" className="secondary-navabar">
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/existing-business/business-retention-expansion"
                    >
                        Business Retention & Expansion
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/existing-business/major-employers-legacy-companies"
                    >
                        Major Employers & Legacy Companies
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/existing-business/start-a-business"
                    >
                        Start a Business
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/existing-business/grow-your-business"
                    >
                        Grow Your Business
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/existing-business/workforce-assistance"
                    >
                        Workforce Assistance
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/existing-business/exporting-assistance"
                    >
                        Exporting Assistance
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/existing-business/financial-assistance"
                    >
                        Financial Assistance
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/existing-business/additional-resources"
                    >
                        Additional Resources
                    </NavLink>
                </Navbar>
            </div>
        );
}

export default ExistingBusinessNav;