import React, { useEffect} from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import "./Social.scss";
import twitter from "./images/twitter-circle.png";
import facebook from "./images/facebook.png";
import linkedin from "./images/linkedin.png";
import youtube from "./images/youtube.png";
import instagram from "./images/instagram.png";
// import SearchBar from '../SearchBar/SearchBar';

const Social = () => {


    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://cse.google.com/cse.js?cx=012338158314937675759:ont6r72uk0k";
        // script.src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return(
        <div className="social-div">
            <Container>
                <Row>
                    <Col className="social-icons social-icons-column" xs={12} sm={6}>
                        <a href="https://twitter.com/henriconow?lang=en" target="_blank" rel="noopener noreferrer">
                            <Image src={twitter} className="social-icon"/>
                        </a>
                        <a href="https://www.facebook.com/TheHenricoEDA/" target="_blank" rel="noopener noreferrer">
                            <Image src={facebook} className="social-icon"/>
                        </a>
                        <a href="https://www.linkedin.com/company/henrico-economic-development-authority/" target="_blank" rel="noopener noreferrer">
                            <Image src={linkedin} className="social-icon"/>
                        </a>
                        <a href="https://www.youtube.com/channel/UC7ulNHiQcff3LBjBTHkwutA" target="_blank" rel="noopener noreferrer">
                            <Image src={youtube} className="social-icon"/>
                        </a>
                        <a href="https://instagram.com/henriconow" target="_blank" rel="noopener noreferrer">
                            <Image src={instagram} className="social-icon"/>
                        </a>
                    </Col>
                    <Col xs={12} sm={6}>
                        <div className="gcse-searchbox-only"></div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Social;