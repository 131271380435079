import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NewsInsights from "../../components/shared/NewsInsights/NewsInsights";
import ExistingBusinessHero from "../ExistingBusiness/ExistingBusinessHero";
import ExistingBusinessNav from '../shared/Navs/ExistingBusinessNav';
import henricoEDABond from '../ExistingBusiness/images/Henrico EDA Bond Application.pdf';
import PageTitle from '../shared/PageTitle/PageTitle';
import { RemoteContent } from '../shared/Cms';

class FinancialAssistance extends React.Component {
    constructor() {
        super();
        this.state = {
            showMore: false,
            showMore1: false,
            name: "React",
            test: 'example'
        }
        this.toggleShowMore = this.toggleShowMore.bind(this);
        this.toggleShowMore1 = this.toggleShowMore1.bind(this);
    }

    toggleShowMore(event){
        event.preventDefault();
        this.state.showMore === true ?  this.setState({showMore: false}) : this.setState({showMore: true});
    }

    toggleShowMore1(event) {
        event.preventDefault();
        this.state.showMore1 === true ?  this.setState({showMore1: false}) : this.setState({showMore1: true});
    }



    render() {
      const { domain, section, page } = {
        domain: 'henrico',
        section: 'existing-business',
        page: 'Financial Assistance'
      };
    return (
        <div>
            <ExistingBusinessHero />
            <Container>
                <ExistingBusinessNav />
                  <PageTitle title={
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field="title"
                    />
                  } />
                <Row>
                    <Col>
                      <RemoteContent
                        domain={domain}
                        section={section}
                        page={page}
                        field="s1"
                      />
                    </Col>
                </Row>
                <Row>
                    <Col>
                      <RemoteContent
                        domain={domain}
                        section={section}
                        page={page}
                        field="s2"
                      />
                        <b><a href="#!" onClick={this.toggleShowMore}>{this.state.showMore === true ? '-' : '+'}Show {this.state.showMore === true ? 'Less' : 'More'}</a></b><br /><br />
                    </Col>
                </Row>
                { this.state.showMore === true ?

                    <React.Fragment>
                      <RemoteContent
                        domain={domain}
                        section={section}
                        page={page}
                        field="showmore1"
                      />
                        <Row>
                            <Col>
                                <b><a href="#!" onClick={this.toggleShowMore1}>{this.state.showMore1 === true ? '-' : '+'}Show {this.state.showMore1 === true ? 'Less' : 'More'}</a></b><br /><br />
                            </Col>
                        </Row>
                        { this.state.showMore1 === true ?

                            <React.Fragment>
                              <RemoteContent
                                domain={domain}
                                section={section}
                                page={page}
                                field="showmore2"
                              />
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                }

                <NewsInsights />
            </Container>
        </div>
);
    }
}

export default FinancialAssistance;
