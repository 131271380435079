import React from "react";

const BloggerEmbed = () => {
    return (
        <div>
            <iframe src='https://henricotimes.blogspot.com/' seamless width="100%" height="600" scrolling='' />
        </div>
    )
}

export default BloggerEmbed;