import {Col, Row} from "react-bootstrap";
import React from "react";
import './PageTitle.scss'

const PageTitle = (props) => {
    return (
        <div>
            <Row>
                <Col className="page-title"><p className="heading-secondary">{props.title}</p></Col>
            </Row>
        </div>
    );
};

export default PageTitle;