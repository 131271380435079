import React, { useState } from "react";
import { Row, Col } from 'react-bootstrap';
import ImageMapper from 'react-image-mapper';
import "./TheSiteMap.scss";

const MAP = {
    name: "my-map",
  areas: [
    {
      id: "1",
      shape: "poly",
      coords: [1093, 449, 1087, 442, 1082, 432, 1078, 416, 1082, 412, 1080, 401, 1067, 392, 1069, 362, 1063, 353, 1067, 347, 1054, 336, 1041, 323, 1045, 319, 1041, 310, 1019, 301, 1017, 292, 1019, 286, 1017, 279, 1019, 269, 1015, 258, 1030, 242, 1032, 232, 1021, 225, 978, 164, 895, 229, 861, 208, 852, 223, 848, 236, 843, 258, 841, 277, 839, 297, 841, 319, 843, 338, 845, 358, 848, 375, 861, 371, 880, 375, 898, 382, 919, 390, 935, 405, 948, 419, 1021, 492, 1093, 449],
      strokeColor: "rgba(255,255,255)",
      fillColor: "rgba(255,255,255,.5)",
      preFillColor: "rgba(255,255,255,.3)",
      name: "Site 1",
      node: "s2",
      pdfDownload: "https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/194411-WOTP+Site+1+Flyer+HEDA2.pdf"
    },
    {
      id: "2",
      shape: "poly",
      coords: [1165, 20, 1081, 83, 1083, 104, 1089, 126, 1094, 141, 1102, 156, 1113, 174, 1249, 296, 1263, 281, 1282, 266, 1304, 244, 1330, 213, 1352, 183, 1362, 168, 1384, 142, 1347, 113, 1297, 79, 1267, 57],
      strokeColor: "rgba(255,255,255)",
      fillColor: "rgba(255,255,255,.5)",
      preFillColor: "rgba(255,255,255,.3)",
      name: "Site 2",
      node: "s3",
      pdfDownload: "https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/194433-WOTP+Site+2+Flyer+HEDA.pdf"
    },
    {
      id: "3",
      shape: "poly",
      coords: [1100, 458, 1115, 474, 1160, 500, 1189, 537, 1165, 578, 1134, 604, 1032, 502],
      strokeColor: "rgba(255,255,255)",
      fillColor: "rgba(255,255,255,.5)",
      preFillColor: "rgba(255,255,255,.3)",
      name: "Site 3",
      node: "s4",
      pdfDownload: "https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/194448-WOTP+Site+3+Flyer+HEDA.pdf"
    },
    {
      id: "4",
      shape: "poly",
      coords: [1252, 311, 1260, 319, 1267, 363, 1258, 376, 1263, 378, 1276, 378, 1291, 398, 1323, 406, 1315, 422, 1330, 448, 1349, 498, 1349, 524, 1356, 532, 1371, 535, 1328, 563, 1263, 576, 1200, 548, 1189, 535, 1158, 498, 1119, 476, 1102, 463, 1115, 448],
      strokeColor: "rgba(255,255,255)",
      fillColor: "rgba(255,255,255,.5)",
      preFillColor: "rgba(255,255,255,.3)",
      name: "Site 4",
      node: "s5",
      pdfDownload: "https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/194548-WOTP+Site+4+Flyer+HEDA.pdf"
    },
    {
      id: "5",
      shape: "poly",
      coords: [1644,405,1546,492,1562,533,1583,552,1601,585,1635,609,1614,641,1638,670,1653,689,1662,711,1653,728,1664,745,1731,778,1761,813,1709,858,1720,900,1716,967,1737,1023,1742,1104,1772,1219,1798,1212,1809,1130,1811,1067,1809,971,1850,960,1907,949,1939,928,1998,945,2004,867,2011,774,2004,732,1985,693,1961,646,1926,630,1883,604,1839,578,1779,552,1742,511,1690,455],
      strokeColor: "rgba(255,255,255)",
      fillColor: "rgba(255,255,255,.5)",
      preFillColor: "rgba(255,255,255,.3)",
      name: "Site 5",
      node: "s6",
      pdfDownload: "https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/194613-WOTP+Site+5+Flyer.pdf"
    }
    // ,
    // {
    //   id: "6",
    //   shape: "poly",
    //   coords: [1793, 1218, 1773, 1222, 1740, 1116, 1743, 1040, 1714, 970, 1719, 899, 1706, 862, 1727, 837, 1760, 810, 1730, 775, 1669, 745, 1654, 725, 1660, 708, 1647, 679, 1612, 642, 1636, 610, 1651, 595, 1667, 582, 1695, 565, 1747, 518, 1777, 551, 1808, 566, 1836, 579, 1858, 592, 1886, 609, 1914, 624, 1958, 646, 1953, 651, 1971, 674, 1984, 694, 1997, 716, 2005, 737, 2012, 759, 2012, 779, 2010, 801, 2003, 823, 1999, 840, 1997, 866, 2001, 866, 1999, 949, 1942, 927, 1901, 947, 1882, 953, 1862, 957, 1845, 957, 1825, 957, 1808, 962, 1810, 1066, 1808, 1130],
    //   strokeColor: "rgba(255,255,255)",
    //   fillColor: "rgba(255,255,255,.5)",
    //   preFillColor: "rgba(255,255,255,.3)",
    //   name: "Site 6",
    //   node: "s7",
    //   pdfDownload: "https://henrico-eda-site-assets.s3.amazonaws.com/white-oak-the-site/WOTP-Site-6-Flyer-HEDA-1.pdf"
    // }
    // },
    // {
    //   id: "1",
    //   shape: "poly",
    //   coords: [902, 933, 1106, 1049, 1139, 1064, 1141, 1077, 1128, 1079, 1130, 1116, 1143, 1116, 1160, 1509, 1100, 1487, 1097, 1407, 1084, 1370, 971, 1289, 800, 1268, 741, 1246],
    //   strokeColor: "rgba(255,255,255)",
    //   fillColor: "rgba(255,255,255,.5)",
    //   preFillColor: "rgba(255,255,255,.3)",
    //   name: "Site 11",
    //   node: "s9",
    //   pdfDownload: "https://henrico-eda-site-assets.s3.amazonaws.com/white-oak-the-site/WOTP-Site-11-Flyer-HEDA-1.pdf"
    // },
    // {
    //   id: "2",
    //   shape: "poly",
    //   coords: [1139, 1068, 1141, 1077, 1158, 1077, 1160, 1114, 1143, 1116, 1160, 1508, 1184, 1519, 1219, 1517, 1286, 1573, 1375, 1585, 1473, 1575, 1547, 1617, 1547, 1452, 1662, 1432, 1697, 1425, 1784, 1399, 1773, 1297, 1743, 1232, 1643, 1243, 1621, 1247, 1606, 1247, 1591, 1245, 1575, 1239],
    //   strokeColor: "rgba(255,255,255)",
    //   fillColor: "rgba(255,255,255,.5)",
    //   preFillColor: "rgba(255,255,255,.3)",
    //   name: "Site 12",
    //   node: "s10",
    //   pdfDownload: "https://henrico-eda-site-assets.s3.amazonaws.com/white-oak-the-site/WOTP-Site-12-Flyer-HEDA-1.pdf"
    // }
  ]
};

var URL = "https://henrico-eda-site-assets.s3.amazonaws.com/white-oak-the-site/the-site-base.jpg";

const TheSiteMap = ({pageData}) => {
    const [hoveredArea, setHoveredArea] = useState('');
    const [message, setMessage] = useState();
    const [listItems, setListItems] = useState(['']);
    const [pdf, setPdf] = useState('');

    const load = () => {
      clicked(MAP.areas[0])
    }

    const clicked = (area) => {
      setMessage(`${area.name}`);
      setListItems(pageData[area.node]);
      setPdf(area.pdfDownload);
    }

    const leaveArea = (area) => {
        setHoveredArea(null);
    }

    const getTipPosition = (area) => {
        return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
    }

    return (
        <div className="grid">
            <Row>
                <Col>
                    <div style={{ position: "relative" }}>
                        <div className="map-desktop">
                            <ImageMapper
                                src={URL}
                                map={MAP}
                                width={500}
                                imgWidth={2457}
                                onLoad={() => load()}
                                onClick={area => clicked(area)}
                                onMouseLeave={area => leaveArea(area)}
                                lineWidth={2}
                                strokeColor={"white"}
                            />
                        </div>
                        {hoveredArea && (
                            <span
                                className="tooltip"
                                style={{ ...getTipPosition(hoveredArea) }}
                            >
                                {hoveredArea && hoveredArea.id}
                            </span>
                        )}
                    </div>
                </Col>
                <Col>
                    <div className="data-display">
                        <p className="paragraph-header--blue">{message ? message : null}</p>
                        <div dangerouslySetInnerHTML={{ __html: listItems }} />
                        {pdf &&
                            <a href={pdf} target="_blank" rel="noopener noreferrer" className="site-pdf-link">
                                <button className="button-primary button-green button-grow-business button-large">
                                    DOWNLOAD PDF
                                </button>
                            </a>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default TheSiteMap;
