// VimeoSetup.js
export const initializeVimeoPlayer = () => {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    document.body.appendChild(script);
  
    script.onload = () => {
      // Find all Vimeo iframes and set up the player
      const iframes = document.querySelectorAll('iframe[src*="vimeo.com"]');
      iframes.forEach(iframe => {
        // You can add any player setup logic here if needed
      });
    };
  };
  