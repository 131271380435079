import { Col } from "react-bootstrap";
import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useInputChange } from './UseInputChange';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert'
import './HenricoLegacyCompaniesForm.scss'

const ContactUsForm = () => {
    const [input, handleInputChange] = useInputChange();
    const [show, setShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [formShow, setFormShow] = useState(true);
    const [disableButton, setDisableButton] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisableButton(true);
        axios.post(`${process.env.REACT_APP_NEWS_URL}/forms/legacy-companies`, {
            companyName: input.companyName,
            yearBusinessEstablishedInHenrico: input.yearBusinessEstablishedInHenrico,
            address1: input.address1,
            address2: input.address2,
            city: input.city,
            state: input.state,
            zip: input.zip,
            websiteUrl: input.websiteUrl,
            firstName: input.firstName,
            lastName: input.lastName,
            title: input.title,
            email: input.email,
            telephone: input.telephone,
        })
        .then(res => {
            setAlertType(res.data.alertType);
            setAlertMessage(res.data.message);
            setShow(true);
            setFormShow(false);
        });
    }

    const handleAlertClose = (e) => {
        setShow(false);
    }

    return (
        <div className="contact-us-form-row">
            {show && (
                <Alert variant={alertType} onClose={(e) => handleAlertClose(e)} dismissible>
                    <Alert.Heading>{alertMessage}</Alert.Heading>
                    <div className="d-flex justify-content-end">
                    </div>
                </Alert>
            )}
            {formShow && (
                <Form>
                    <Form.Label><p>Company Name</p></Form.Label>
                    <Form.Group controlId="companyName">
                        <Form.Control name="companyName" onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Label><p>Years Business Established in Henrico</p></Form.Label>
                    <Form.Group controlId="yearBusinessEstablishedInHenrico">
                        <Form.Control name="yearBusinessEstablishedInHenrico" onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Label><p>Street Address or P.O. Box</p></Form.Label>
                    <Form.Group controlId="address1">
                        <Form.Control name="address1" onChange={handleInputChange} placeholder="Address Line 1" />
                    </Form.Group>

                    <Form.Group controlId="address2">
                        <Form.Control name="address2" onChange={handleInputChange} placeholder="Address Line 2" />
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="city">
                            <Form.Control name="city" onChange={handleInputChange} placeholder="City" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="state">
                            <Form.Control name="state" onChange={handleInputChange} placeholder="State" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="zip">
                            <Form.Control name="zip" onChange={handleInputChange} placeholder="Zip" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Label><p>Website URL</p></Form.Label>
                    <Form.Group controlId="websiteUrl">
                        <Form.Control name="websiteUrl" onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Label><p>Name of Primary Company Contact</p></Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} controlId="firstName">
                            <Form.Control name="firstName" onChange={handleInputChange} placeholder="First" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="lastName">
                            <Form.Control name="lastName" onChange={handleInputChange} placeholder="Last" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Label><p>Title</p></Form.Label>
                    <Form.Group controlId="title">
                        <Form.Control name="title" onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Label><p>Email Address</p></Form.Label>
                    <Form.Group controlId="email">
                        <Form.Control name="email" onChange={handleInputChange} type="email" />
                    </Form.Group>

                    <Form.Label><p>Phone And Extension</p></Form.Label>
                    <Form.Group controlId="phone">
                        <Form.Control name="telephone" onChange={handleInputChange} type="tel" placeholder="(804) 111-2222 x3333" />
                    </Form.Group>

                    <Button className="button-primary button-green button-large" type="submit" disabled={disableButton} onClick={handleSubmit}>
                        Submit
                    </Button>
                </Form>
            )}
        </div>
    );
};

export default ContactUsForm;