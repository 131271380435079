import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import WhyHenricoHero from "./WhyHenricoHero";
import WhyHenricoNav from "../shared/Navs/WhyHenricoNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import QualityOfLifeForm from './QualityOfLifeForm';

const pageTitle = "SWaM Certification";

const SwamStatement = () => {
  return (
    <div>
<WhyHenricoHero />
            <Container>
            <div className="category-button-wrapper">
                <Row className="button-row button-row-1">
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/pro-business" onClick={(event) => this.updateCategory(event, 'Pro-Business')}  className="button-primary button-henrico-category" >
                            PRO-BUSINESS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/market-access" onClick={(event) => this.updateCategory(event, 'Market Access')} className="button-primary button-henrico-category" >
                             MARKET ACCESS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/skilled-workforce" onClick={(event) => this.updateCategory(event, 'Skilled Workforce')} className="button-primary button-henrico-category" >
                            WORKFORCE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/competitive-operating-costs" onClick={(event) => this.updateCategory(event, 'Competitive Operating Costs')} className="button-primary button-henrico-category" >
                            OPERATING COSTS
                        </a>
                    </Col>
                </Row>
                <Row className="button-row button-row-1">
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/infrastructure"  onClick={(event) => this.updateCategory(event, 'Infrastructure')} className="button-primary button-henrico-category" >
                            INFRASTRUCTURE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/qualityoflife" className="button-primary button-henrico-category" >
                            QUALITY OF LIFE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/fast-facts" onClick={(event) => this.updateCategory(event, 'Fast Facts')} className="button-primary button-henrico-category" >
                            FAST FACTS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/swam-certification" onClick={(event) => this.updateCategory(event, 'SWaM Certification')} className="button-primary button-henrico-category" >
                            SWAM
                        </a>
                    </Col>                    
                </Row>
            </div>
            </Container>
            <Container>
                <div class="pagetTitleContainer">
                    <PageTitle title={pageTitle} />  
                </div>
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                      <p>Entrepreneurs are the backbone of our economy, and the Small, Women-owned and Minority-owned (SWaM) businesses they build are vital to Henrico’s vibrancy. It’s why we’re doing everything we can to promote supplier diversity by increasing <a href="https://www.sbsd.virginia.gov/certification-division/swam/" target="_blank" rel="noopener noreferrer">SWaM certification</a>. In addition, the Virginia program was created to enhance procurement opportunities for SWaM businesses in state-funded projects.</p>
                      <p>But it’s not just about forging connections; it’s also about supporting everyone in our SWaM community. Our entrepreneurs create jobs, promote economic mobility, increase our economic competitiveness, and contribute to our exceptional <a href="https://www.henrico.com/why-henrico/qualityoflife" target="_blank" rel="noopener noreferrer">quality of life</a>. They also improve the vibrancy of our communities – two of which have been named some of the best places to live in the U.S. Perhaps most importantly, we believe everyone should have access to opportunity.</p>
                      <p>So, if it’s a small business, a Black-owned business, or an LGBTQ-owned business, we make it our business by cultivating a community of inclusion. In fact, we have spent $1.3 billion with SWaM businesses since 2007 and they account for 56% of our annual spend – the most of any locality in central Virginia.  Because there can be no economic development without equitable development, it’s the Henrico EDA’s duty and privilege to grow our economy for all.</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6} style={{ paddingRight: "40px" }}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/swam11.jpg" alt="Black woman small business owner leading a board room meeting" width="100%"/>
                    <br/><br/>
                    <p><h3>Women-Owned Businesses in Henrico</h3></p>
                    <p>Virginia is a <a href="https://www.merchantmaverick.com/the-top-10-states-for-women-led-startups-in-2023/" target="_blank;" rel="noopener noreferrer">top ten state for women-led startups</a>, and in Henrico, we’re living up to the recognition. Here, we work to make our community a beacon of inclusion, and women entrepreneurs are finding extraordinary success like:
                        <br/><br/>
                        <ul>
                            <li>Ashley Reynolds, founder of the online planner/stationary retailer <a href="https://clothandpaper.com/" target="_blank" rel="noopener noreferrer">Cloth & Paper</a>, an Inc. 5000 company for two consecutive years</li>
                            <li>Courtney White, owner of Henrico microbrewery <a href="https://www.intermissionbeer.com/" target="_blank" rel="noopener noreferrer">Intermission Beer Company</a>, voted Best Local Brewery in Style Weekly</li>
                            <li>Catina Jones, founder and principal broker of Rockett’s Landing-based <a href="https://www.iconrealtyrva.com/" target="_blank" rel="noopener noreferrer">ICON Realty Group</a>, expanded her business footprint to South Florida in 2021</li>
                        </ul>
                        And as an added support system, there’s Shirley Crawford, the executive director of <a href="http://wbcrva.com/" target="_blank" rel="noopener noreferrer">Women’s Business Center RVA (WBCRVA)</a>. She uses her incubator and coworking space to give women entrepreneurs the tools, tips, and resources they need to “start, sustain, and succeed in business.” Coaching, mentoring, monthly seminars, and more are all included with membership.<br></br><br></br>
                        Local women entrepreneurs also have the opportunity to join the <a href="https://theinstitutenc.org/wbcrichmond/" target="_blank" rel="noopener noreferrer">Women’s Business Center of Richmond</a>, which was launched in 2021 partly in response to the pandemic, and the <a href="https://nawborichmond.org/" target="_blank" rel="noopener noreferrer">National Association of Women Business Owners Richmond Chapter</a>. 
                    </p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6} style={{ paddingLeft: "40px" }}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/swam2.png" alt="Black entrepreneur and woman at an office table viewing a tablet" width="100%"/>
                    <br/><br/>
                    <p><h3>Minority-Owned Businesses in Henrico</h3></p>
                    <p>One great reason why you should build or relocate your minority-owned business in our community? SmartAsset ranked the Richmond metro area, which includes Henrico, the <a href="https://smartasset.com/data-studies/best-places-for-black-entrepreneurs-2022" target="_blank" rel="noopener noreferrer">#8 Best Place in the U.S. for Black Entrepreneurs</a>. Metrics included: 
                        <br/><br/>
                        <ul>
                            <li>The number of Black-owned businesses (7% of metro area businesses)</li>
                            <li>Black-owned business growth (54% from 2017–2019)</li>
                            <li>Median Black household income ($47,445)</li>
                            <li>Black unemployment rate (6.9%)</li>
                            <li>The percentage of businesses with sales exceeding $250,000</li>
                        </ul>  
                        …and more. <br></br><br></br>
                        Uniquely, Henrico has appointed a Supplier Relations Manager who has access to Virginia buyers. The role is 100% dedicated to advocating for our SWaM businesses. Through a strong partnership with Virginia and surrounding localities, we guide local businesses, improve best practices, and promote equity throughout our business community. 
                    </p>
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6} style={{ paddingRight: "40px" }}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/swam3.jpg" alt="Man and woman at a desk in an office smiling while looking at a laptop" width="100%"/>
                    <br/><br/>
                    <p><h3>Diversity, Equity, and Inclusion in the Workplace</h3></p>
                    <p>
                      When you’re looking to further your Diversity, Equity, and Inclusion (DEI) and <a href="https://www.henrico.com/why-henrico/esg" target="_blank" rel="noopener noreferrer">Environmental, Social, and Governance</a> (ESG) goals, turn to Henrico. Our embrace of multiculturalism is echoed by our diverse population, which added nearly 34,000 Asian, Black, Hispanic, and multiracial residents from 2010–2020. Here you can more easily attract and retain a diverse workforce and build an inclusive workplace to:
                    </p>
                        <ul>
                            <li>Increase team engagement</li>
                            <li>Grow your customer base</li>
                            <li>Innovate through diversity</li>
                            <li>Heighten your competitiveness</li>
                            <li>Propel business success now and in the future</li>
                        </ul> 
                    <p>
                      And your <a href="https://www.henrico.com/why-henrico/skilled-workforce" target="_blank" rel="noopener noreferrer">talent pipeline</a> will always be full: our labor pool is over 700,000 strong, and there are nearly 30 colleges, universities, and professional schools in our area. Plus, Virginia State and Virginia Union, two nationally recognized nearby universities, are historically Black.   
                    </p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6} style={{ paddingLeft: "40px" }}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/swam4.png" alt="Black woman entrepreneur smiling while looking out her storefront window" width="100%"/>
                    <br/><br/>
                    <p><h3>SWaM Certification and Supplier Diversity</h3></p>
                    <p>
                      We can help your small businesses get their foot in the door. In 2020, the Henrico EDA thought of new ways to help our small businesses and promote supplier diversity. Through a multipronged approach, we:
                    </p>    
                        <ul>
                            <li>Worked more closely with the <a href="https://www.sbsd.virginia.gov/" target="_blank" rel="noopener noreferrer">Virginia Department of Small Business and Supplier Diversity</a> and <a href="https://henrico.us/finance/divisions/purchasing/swam-businesses/" target="_blank" rel="noopener noreferrer">Henrico Supplier Relations Division</a> to increase SWaM business certifications</li>
                            <li>Connected with companies for one-on-ones to learn more about their supply chain, vendor, and contractor needs</li>
                            <li>Partnered with Henrico’s <a href="https://henrico.us/finance/divisions/purchasing/" target="_blank" rel="noopener noreferrer">Purchasing Division</a> to present a series of webinars that help businesses and entrepreneurs understand exactly how to gain SWaM certification, which had the dual benefit of helping us build a database of certified companies</li>
                        </ul>
                    <p>     
                    In 2022, <a href="https://henrico.us/news/2022/07/henrico-earns-naco-awards-for-21-government-school-programs/" target="_blank" rel="noopener noreferrer">the Henrico EDA received a National Association of Counties (NACo) Award</a> – which recognizes groundbreaking service programs – for our refreshed SWaM initiative. And we are committed to continuing to earn our national reputation as a place where business owners from all cultures and backgrounds come to thrive. 
                    </p>
                    </Col>                                       
                </Row>    
            </Container>
            <QualityOfLifeForm />
            <Container>
                <NewsInsights />
            </Container>
    </div>
  );
};

export default SwamStatement;
