import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./SecondaryNav.scss";

const SiteSelectorsNav = (props) => {   
        return (
            <div>
                <Navbar expand="xs" className="secondary-navabar">
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/available-property-search"
                    >
                        Available Property Search
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/taxes"
                    >
                        Taxes
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/utilities"
                    >
                        Utilities
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/incentives"
                    >
                        Incentives
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/demographic-data"
                    >
                        Demographic Data
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/transportation"
                    >
                        Transportation
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/cost-of-living"
                    >
                        Cost of Living
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/data-downloads"
                    >
                        Downloads
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/site-selectors/data-connectivity"
                    >
                        Data Connectivity
                    </NavLink>
                </Navbar>
            </div>
        );
}

export default SiteSelectorsNav;