import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SiteSelectorsHero from './SiteSelectorsHero';
import SiteSelectorsNav from "../shared/Navs/SiteSelectorsNav";
import PageTitle from '../shared/PageTitle/PageTitle';
import henricoTaxdocument from '../SiteSelectors/images/Henrico-Taxbook-2019_updated.pdf';
import './Taxes.scss';
import { remoteData } from '../shared/Cms';
import { getListItems } from '../../utils/cms';

const Taxes = () => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'site-selectors',
    page: 'Taxes'
  };
  const [loading, setLoading] = useState(true);
  const [selector, setSelector] = useState([]);

  useEffect(() => {
    remoteData({domain, section, page})
      .then(data => {
        setSelector(data[0]);
        setLoading(false);
      });
  }, []);

  if(loading) {
    return <></>;
  }

    return (
        <div>
            <SiteSelectorsHero />
            <Container>
                <SiteSelectorsNav />
                <PageTitle title={selector.title} />
                <Row>
                  <Col>
                    <p dangerouslySetInnerHTML={{__html: selector.s1}} />
                    <ul className="ul-added-space">
                      {getListItems(selector).map(listItem => {
                        return <li>{selector[listItem]}</li>
                      })}
                    </ul>
                    <p dangerouslySetInnerHTML={{__html: selector.s2}} />
                  </Col>
                </Row>
                <Row>
                    <Col className="">
                        <p className="paragraph-header--blue">Henrico Taxes</p>
                        <Row style={{margin: '0.5rem'}}>
                            <Col xl={9}>
                                Year 2024: Henrico
                            </Col>
                            <Col xl={3} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                Tax Rate
                            </Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={9} style={{borderRight:'2px solid #444'}}>
                                Real Estate Tax
                            </Col>
                            <Col xl={3} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                $0.85/$100
                            </Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={9} style={{borderRight:'2px solid #444'}}>
                                Machinery & Tools Tax Rate
                            </Col>
                            <Col xl={3} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                $0.30/$100
                            </Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={9} style={{borderRight:'2px solid #444'}}>
                                Tangible Personal Property Tax
                            </Col>
                            <Col xl={3} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                $3.50/$100
                            </Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={9} style={{borderRight:'2px solid #444'}}>
                                Data Center Tax Rate
                            </Col>
                            <Col xl={3} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                $0.40/$100
                            </Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={9} style={{borderRight:'2px solid #444'}}>
                                Business and Professional Licenses Tax
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>The Business and Professional License Tax is based on gross receipts exceeding $500,000, with a minimum tax of $30 for all but Utility companies. When gross receipts equal $500,000 or less, an application is required, but no tax is due.</p>
                            </Col>
                        </Row>

                        <Row className={'tableMargin'} >
                            <Col xl={9} style={{borderRight:'1px solid #444'}}>Retail Merchants</Col>
                            <Col xl={3}>$0.20 per $100</Col>

                        </Row>
                        <Row className={'tableMargin'} >
                            <Col xl={9} style={{borderRight:'1px solid #444'}}>Professional Services</Col>
                            <Col xl={3}>$0.20 per $100</Col>

                        </Row>
                        <Row className={'tableMargin'} >
                            <Col xl={9} style={{borderRight:'1px solid #444'}}>Fee-Basis Contractors</Col>
                            <Col xl={3}>$1.50 per $100</Col>

                        </Row>
                        <Row className={'tableMargin'} >
                            <Col xl={9} style={{borderRight:'1px solid #444'}}>Contract-Basis Contractors</Col>
                            <Col xl={3}>$1.50 per $100</Col>

                        </Row>
                        <Row className={'tableMargin'} >
                            <Col xl={9} style={{borderRight:'1px solid #444'}}>Utility Companies (including cellular telephone companies)</Col>
                            <Col xl={3}>$0.50 per $100</Col>
                        </Row>
                        <Row className={'tableMargin borderBottom'}>
                            <Col xl={2} className={'centerContent'}>
                                <span>Wholesale Merchants</span>
                            </Col>
                            <Col xl={10}>
                                <Row  className={'subTableMargin'} >
                                    <Col xl={12}>
                                        <span>Tax is imposed based on the following variable rate; the first $500,000 of gross purchases is excluded from the tax for an item.</span>
                                    </Col>
                                </Row>
                                <Row  className={'subTableMargin'} >
                                    <Col xl={8}>
                                        For purchases $1.00 to $10,000
                                    </Col>
                                    <Col xl={4}>
                                        $25 (flat fee)
                                    </Col>
                                </Row>
                                <Row className={'subTableMargin'} >
                                    <Col xl={8}>
                                        For purchases $10,001 to $5,000,000
                                    </Col>
                                    <Col xl={4}>
                                        $0.20 per $100
                                    </Col>
                                </Row>
                                <Row className={'subTableMargin'}  >
                                    <Col xl={8}>
                                        For purchases $5,000,001 to $15,000,000
                                    </Col>
                                    <Col xl={4}>
                                        $0.15 per $100
                                    </Col>
                                </Row>
                                <Row className={'subTableMargin'} >
                                    <Col xl={8}>For purchases $15,000,001 to $25,000,000
                                    </Col>
                                    <Col xl={4}>$0.10 per $100
                                    </Col>
                                </Row>
                                <Row className={'subTableMargin'} >
                                    <Col xl={8}>For purchases $25,000,001 to $50,000,000
                                    </Col>
                                    <Col xl={4}>$0.05 per $100
                                    </Col>
                                </Row>
                                <Row className={'subTableMargin'} >
                                    <Col xl={8}>For purchases $50,000,001 to $100,000,000
                                    </Col>
                                    <Col xl={4}>$0.025 per $100
                                    </Col>
                                </Row>
                                <Row className={'subTableMargin'} >
                                    <Col xl={8}>For purchases $100,000,001 and over
                                    </Col>
                                    <Col xl={4}>$0.0125 per $100
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={9} style={{borderRight:'2px solid #444'}}>
                                Aircraft Tax Rate
                            </Col>
                            <Col xl={3} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                $0.50/$100
                            </Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={9} style={{borderRight:'2px solid #444'}}>
                                Lodging Tax
                            </Col>
                            <Col xl={3} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                8%
                            </Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={9} style={{borderRight:'2px solid #444'}}>
                                Local Sales Tax
                            </Col>
                            <Col xl={3} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                1%
                            </Col>
                        </Row>
{/*
                        <img className="henrico-taxes-table" src={henricoTaxesTable}></img>*/}
                    </Col>
                </Row>
                <Row>
                    <Col className="">
                        <p className="paragraph-header--blue">Virginia State Level Taxes</p>
                        <Row style={{margin: '0.5rem'}}>
                            <Col xl={4}>
                                Year 2024: Commonwealth of Virginia
                            </Col>
                            <Col xl={2} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                Tax Rate
                            </Col>
                        </Row>
                       {/* <Row style={{margin: '0.5rem'}}>
                            <Col xl={4} style={{backgroundColor: '#0073cf', borderRight:'2px solid #444', height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                Corporate Income Tax
                            </Col>
                            <Col xl={2} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                6%
                            </Col>
                        </Row>*/}
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={4} style={{borderRight:'2px solid #444'}}>
                                Corporate Income Tax
                            </Col>
                            <Col xl={2} style={{backgroundColor: '#0073cf',height: '1.5rem', textAlign: 'center', color: 'white'}}>6%</Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={6} style={{borderRight:'0px solid #444'}}>
                                Personal Income Tax
                            </Col>
                        </Row>
                     {/*   <Row style={{margin: '0.5rem'}}>
                            <Col xl={6} style={{backgroundColor: '#0073cf', borderRight:'2px solid #444', height: '1.5rem', textAlign: 'center', color: 'white'}}>
                                Personal Income Tax
                            </Col>
                        </Row>*/}
                        <Row className={'tableMargin1'} >
                            <Col xl={4} style={{borderRight:'1px solid #444'}}> $0 – $3,000</Col>
                            <Col xl={2}>  2%</Col>

                        </Row>
                        <Row className={'tableMargin1'} >
                            <Col xl={4} style={{borderRight:'1px solid #444'}}>$3,001 – $5,000</Col>
                            <Col xl={2}>$60 + 3% of excess over $3,000</Col>
                        </Row>
                        <Row className={'tableMargin1'} >
                            <Col xl={4} style={{borderRight:'1px solid #444'}}>$5,001 – $17,000</Col>
                            <Col xl={2}>$120 + 5% of excess over $5,000</Col>
                        </Row>

                        <Row className={'tableMargin1'} >
                            <Col xl={4} style={{borderRight:'1px solid #444'}}>$17,001 and above</Col>
                            <Col xl={2}>$0.20 per $100</Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={4} style={{borderRight:'2px solid #444'}}>
                                Unemployment Insurance Tax
                            </Col>
                        </Row>
                        <Row className={'tableMargin1'} >
                            <Col xl={4} style={{borderRight:'1px solid #444'}}> New Employer's Rate</Col>
                            <Col xl={2}>  2.57%</Col>

                        </Row>
                        <Row className={'tableMargin1'} >
                            <Col xl={4} style={{borderRight:'1px solid #444'}}>Minimum</Col>
                            <Col xl={2}>0.17%</Col>
                        </Row>
                        <Row className={'tableMargin1'} >
                            <Col xl={4} style={{borderRight:'1px solid #444'}}>Maximum</Col>
                            <Col xl={2}>6.27%</Col>
                        </Row>

                        <Row className={'tableMargin1'} >
                            <Col xl={4} style={{borderRight:'1px solid #444'}}>Wage Base</Col>
                            <Col xl={2}>$8,000</Col>
                        </Row>
                        <Row style={{margin: '0.5rem',backgroundColor: '#0073cf', textAlign: 'center', color: 'white'}}>
                            <Col xl={4} style={{borderRight:'2px solid #444'}}>
                                Sales & Use Tax
                            </Col>
                            <Col xl={2}>5.3%</Col>
                        </Row>



                        {/* <img className="state-taxes-table" src={stateTaxesTable}></img>*/}
                    </Col>
                </Row>

                <NewsInsights />
            </Container>
        </div>
    );
    };

export default Taxes;
