import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import SiteSelectorsHero from './SiteSelectorsHero';
import EmbedDemograhpicData from '../shared/EmbedDemographicData/EmbedDemographicData';
import SiteSelectorsNav from '../shared/Navs/SiteSelectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';

const pageTitle = 'Demographic Data';

const DemographicData = () => {
    return (
        <div>
            <SiteSelectorsHero />
            <Container>
                <SiteSelectorsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                        <p>Looking for demographic and workplace data? <a href="mailto:info@henrico.com">Contact us</a> and let us help.</p>
                        {/* <p>Gather the demographic and workforce data you’re interested in by using the tool below. Not finding what
                        you’re looking for? <a href="mailto:info@henrico.com">Contact us</a> and let us help.</p> */ }
                    </Col>
                </Row>
                {/* <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                        <EmbedDemograhpicData />
                    </Col>
    </Row> */}
                <NewsInsights />
            </Container>
        </div>
    );
};


export default DemographicData;